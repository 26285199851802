import {Checkbox} from "../../../../components";
import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import classNames from "classnames";
import {PackageContent} from "../index";

const CheckoutInfo = (props) => {

    const { changeCreditCardInfo, creditCardInfo, packageInfo, buttonLoading, handlePayment, successfulPayment } = props;
    const strings = useLocalization();

    // Sözleşme onay durumunun tutulduğu state
    const [isContractApproved, setIsContractApproved] = useState(false);

    // Sözleşmenin onay durumu değiştikçe üst componentteki kredi kartı bilgileri güncelleniyor
    useEffect(() => {
        changeCreditCardInfo({ isContractApproved: isContractApproved });
    }, [isContractApproved]);

    return (
        <div className="hidden md:flex w-[30%] md:h-fit py-5 transparent-section mt-4 mb-5">
            <div className="flex flex-col mx-auto">
                {creditCardInfo?.bank.id !== -1 &&
                    // Sözleşme Onay Durumu
                    <label className="flex justify-center items-start mb-3">
                        <Checkbox
                            type="checkbox"
                            state={isContractApproved}
                            action={() => setIsContractApproved(isContractApproved => !isContractApproved)}
                        />
                        <p
                            className="text-[12px] text-start"
                            dangerouslySetInnerHTML={{__html: strings.market.checkout.preliminary_information_form}}
                        />
                    </label>
                }

                {/* Onay Butonu */}
                <button
                    type='button'
                    className={classNames("button primary-button md-810:text-[16px]", {
                        "disabled:opacity-25 disabled:pointer-events-none" : buttonLoading
                    })}
                    disabled={buttonLoading}
                    onClick={handlePayment}
                >
                    {buttonLoading
                        ? successfulPayment
                            ? strings.market.checkout.pay_is_ok
                            : strings.market.checkout.trying_to_pay
                        : strings.market.checkout.complete_button
                    }
                </button>

                <PackageContent
                    packageInfo={packageInfo}
                    creditCardInfo={creditCardInfo}
                />
            </div>
        </div>
    )
}

export default CheckoutInfo;