import { fetchApi } from '../index';

export const CreateMemberExercise = async (memberId) => {

    const url = '/_services/ab_testing/onboarding_exercise/create_member_exercise/index.php';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetMemberExercise = async (model) => {

    const url = '/_services/ab_testing/onboarding_exercise/get_exercise_by_use_case_id/index.php';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const CreateExerciseResult = async (model) => {

    const url = '/_services/ab_testing/onboarding_exercise/create_result_by_exercise_day/index.php';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}