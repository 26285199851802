import {Link} from "react-router-dom";
import classNames from "classnames";

const HorizontalMenuButton = (props) => {

    const {
        showLink = false,
        showOnclick = true,
        onclick,
        icon,
        title,
        link = "",
        classnames = ""
    } = props;

    return (
        <>
            {showLink &&
                <Link
                    to={link}
                    className={classNames("", {
                        [classnames] : [!!classnames]
                    })}
                >
                    <div className="onboarding-button flex items-center">
                        <div className="onboarding-button-left">
                            <img src={icon} alt="" width="32" height="32" />
                        </div>
                        <p className="text-start">{title}</p>
                    </div>
                </Link>
            }
            {showOnclick &&
                <div
                    onClick={onclick}
                    className={classNames("onboarding-button flex items-center cursor-pointer", {
                        [classnames] : [!!classnames]
                    })}
                >
                    <div className="onboarding-button-left">
                        <img src={icon} alt="" width="32" height="32" />
                    </div>
                    <p className="text-start">{title}</p>
                </div>
            }
        </>
    )
}

export default HorizontalMenuButton;