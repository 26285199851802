import { useLocalization } from "../../../../hooks/useLocalization"

const MissedCallHistory = ({ data }) => {

  const strings = useLocalization();

  return (
    <div className="transparent-section mt-5 text-sm text-start">
      <h5 className="text-secondary mb-3">{strings.support.missed_call.previously_missedcall_courses}</h5>
      {data.length > 0 ?
        data.map((date, index) => (
          <div key={index}>
            <hr />
            <p>{date} {strings.support.missed_call.missedcall_lesson_content}</p>
          </div>
        )) : (
          <div className="text-center">{strings.support.missed_call.not_used}</div>
        )}
      <br />
    </div>
  )
}

export default MissedCallHistory