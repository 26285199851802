import React, {useEffect, useState} from "react";
import {Bookmark, SelectedBookmark, Speaker} from "../assets/svg";
import useSpeaker from "../hooks/useSpeaker";
import {EventLogs, SentenceStoreType} from "./Constants";
import useAuth from "../hooks/useAuth";
import {UpsertMemberSentenceStore} from "../services/SentenceBank/SentenceBankService";
import classNames from "classnames";
import {useLocalization} from "../hooks/useLocalization";
import useAnalytics from "../hooks/useAnalytics";

const SentenceRow = (props) => {

    const {
        data,
        showHr,
        handleClickedSentence = () => {}
    } = props;

    const strings = useLocalization();
    const { member } = useAuth();
    const { speakSentence, SpeakerComponent } = useSpeaker();
    const analytics = useAnalytics();

    const [isSavedSentence, setIsSavedSentence] = useState(data?.isSaved);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleSaveSentence = () => {
        setShowTooltip(false);

        const model = {
            memberId: member?.MemberId,
            sentenceId: data?.sentenceId,
            sentenceStoreType: SentenceStoreType.BOOK
        };

        UpsertMemberSentenceStore(model)
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setIsSavedSentence(resultContent);

                    resultContent
                        ? analytics.useAnalytics(EventLogs.SENTENCE_BANK_ADDED_TO_LEARNING_LIST, undefined, false, true)
                        : analytics.useAnalytics(EventLogs.SENTENCE_BANK_REMOVED_TO_LEARNING_LIST, undefined, false, true);

                    setShowTooltip(true);
                }

            })
            .catch()
    };

    const handleClickSentence = () => {
        speakSentence(data.sentence)
            .then()
            .catch()

        analytics.useAnalytics(EventLogs.SENTENCE_BANK_PRONUNCIATION_LISTENED, undefined, false, true);
        handleClickedSentence(data.sentenceId);
    }

    useEffect(() => {
        let timer;
        if (showTooltip) {
            timer = setTimeout(() => {
                setShowTooltip(false);
            }, 1500);
        }

        return () => clearTimeout(timer);
    }, [showTooltip]);

    return (
        <>
            <div className="flex justify-between items-center gap-2 mt-2">
                <div className="flex flex-col">
                    <button
                        type="button"
                        className="flex gap-1 hover:text-base-text/80 cursor-pointer"
                        onClick={() => handleClickSentence(data)}
                    >
                        <p className="font-bold align-middle text-start">
                            {data.sentence}
                            <img src={Speaker} width={16} height={16} alt="speaker" className="inline-flex ml-1.5"/>
                        </p>
                    </button>
                    <p className="text-[14px] text-start">
                        {data.sentenceTranslation}
                    </p>
                </div>
                <div className="flex-shrink-0">
                    <button
                        type="button group absolute"
                        onClick={handleSaveSentence}
                    >
                        {isSavedSentence
                            ? <img src={SelectedBookmark} width={20} height={20} alt="bookmark"/>
                            : <img src={Bookmark} width={20} height={20} alt="selected-bookmark"/>
                        }

                        <div
                            className={classNames("has-tooltip z-0 relative", {
                                "group-active": showTooltip
                            })}
                        >
                            <div className="tooltip-top -top-[58px]">
                                {isSavedSentence
                                    ? strings.speaking_lesson.daily_lesson.sentence_bank.added_list
                                    : strings.speaking_lesson.daily_lesson.sentence_bank.removed_list
                                }
                            </div>
                        </div>
                    </button>
                </div>
            </div>

            {showHr && <hr className="my-2"/>}
            <SpeakerComponent/>
        </>
    )
}

export default SentenceRow;