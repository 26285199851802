import React from 'react'
import { Outlet } from 'react-router-dom'
import { Menu, SideBar } from '../../components'
import { useLocalization } from '../../hooks/useLocalization'

const OnboardingExerciseLayout = () => {

  const strings = useLocalization();

  return (
    <>
      <Menu title={strings.onboarding_exercises.title} />

      <section className="container">

        <Outlet />

        <SideBar />

      </section>
    </>
  )
}

export default OnboardingExerciseLayout