import { Outlet } from "react-router-dom"
import { Menu, SideBar } from "../../components"
import { useLocalization } from "../../hooks/useLocalization"

const TeacherLayout = () => {

    const strings = useLocalization()

    return (
        <>
            <Menu title={strings.teachers.title} />

            <section className="container">

                <Outlet />

                <SideBar />
                
            </section>
        </>
    )
}

export default TeacherLayout