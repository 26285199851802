import { useLocalization } from '../../../../hooks/useLocalization'
import { Icon_Confused } from '../../../../assets/svg';
import Error from '../Common/Error';
import ChangeTeacherTimeHistory from '../TeacherTimeChange/ChangeTeacherTimeHistory';

const Frozen = ({ data, history, fromPostpone = false }) => {
    const strings = useLocalization();

    return (
        <>
            <Error
                icon={Icon_Confused}
                title={strings.support.teacher_time_change_frozen.frozen}
                content={strings.support.postpone_lesson.you_have_frozen_your_courses
                    .replace('#date1#', data.frozenLessonEndDate)
                    .replace('#date2#', data.transactionBeginDate)}
                hideButton="hidden"
            />
            {!fromPostpone && <ChangeTeacherTimeHistory data={history} />}
        </>
    )
}

export default Frozen