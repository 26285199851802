import classNames from "classnames";
import {getAnswerTitleByIndex} from "../../../../utils";
import React from "react";

const QuizReportQuestions = ( props ) => {

    const { quizQuestions, quizReport } = props;

    return (
        <div className="transparent-section mt-10 text-sm text-start pb-5 mb-10">
            {quizQuestions.map((item, questionIndex) => (
                <div
                    key={questionIndex}
                    className={classNames("flex flex-col", {
                        "mt-5" : questionIndex !== 0
                    })}
                >
                    <p className="w-full text-center mt-2 font-bold">
                        <span>{questionIndex + 1}) </span>
                        <span dangerouslySetInnerHTML={{ __html: item.content.replace(/\n/g, '<br>') }}></span>
                    </p>
                    <div className="flex flex-col mt-4 items-center">
                        {item.questionAnswers.map((answer, index) => {

                            // Kullanıcının soruyu boş geçmediyse doğru cevabı yeşil yakıyoruz.
                            let rightAnswerButton = (answer.isCorrectAnswer) && (quizReport.questions[questionIndex].userAnswer !== -1);
                            // Kullanıcının seçtiği cevap yanlışsa seçili cevabı kırmızı yakıyoruz.
                            let wrongAnswerButton = (quizReport.questions[questionIndex].userAnswer !== quizReport.questions[questionIndex].rightAnswer && quizReport.questions[questionIndex].userAnswer === answer.id)

                            return (
                                <button
                                    key={index}
                                    type="button"
                                    className={classNames("w-full max-w-[330px] h-[50px] leading-[22px] relative overflow-hidden rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 pl-[70px] pr-7 flex items-center cursor-default", {
                                        "bg-green": rightAnswerButton,
                                        "bg-red": wrongAnswerButton,
                                    })}
                                >
                                    <div
                                        className={classNames("onboarding-button-left", {
                                            "bg-green border-white text-white": rightAnswerButton,
                                            "bg-red border-white text-white": wrongAnswerButton,
                                        })}
                                    >
                                        <span className="font-bold">{getAnswerTitleByIndex(index)}</span>
                                    </div>
                                    <p
                                        className={classNames("text-start", {
                                            "text-white": (rightAnswerButton || wrongAnswerButton),
                                            "text-[14px] leading-[17px]": answer.answer.content.length > 60,
                                        })}
                                    >
                                        {answer.answer.content}
                                    </p>
                                </button>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default QuizReportQuestions