import React from "react";
import {useLocalization} from "../../hooks/useLocalization";
import {Menu, SideBar} from "../../components";
import {Outlet} from "react-router-dom";

const ProfileLayout = () => {
    const strings = useLocalization();

    return (
        <>
            <Menu title={strings.menu.collapse.profile} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
        </>
    )
}

export default ProfileLayout;