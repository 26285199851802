import {useLocalization} from "../../hooks/useLocalization";
import React, {useEffect, useState} from "react";
import {Button, Loading, RadialBar} from "../../components";
import {url} from "../../routes/utility";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {GetVideoDialogs} from "../../services/VideoPractice/VideoPracticeService";
import classNames from "classnames";
import PronunciationModal from "../Exercises/components/Modals/PronunciationModal";
import {sendEventByDefaultParameters} from "../../utils";
import useAnalytics from "../../hooks/useAnalytics";
import {EventLogs} from "../../components/Constants";
import {GetVideoDialogById} from "../../services/DemoPrototype/DemoPrototypeService";

const Dialog = () => {

    const strings = useLocalization();

    const { videoId } = useParams();
    const { member } = useAuth();

    const navigate = useNavigate();
    const analytics = useAnalytics();
    const location = useLocation();
    const params = new URLSearchParams(window.location.search);

    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;
    const isSinglePage = location.pathname.includes("/dialogs");
    const outsideDialogId = params.get('dialogId') || false;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [openPronunciationModal, setOpenPronunciationModal] = useState(false);
    const [selectedDialog, setSelectedDialog] = useState({});

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                video_name: videoInfo?.videoName,
                ...parameters
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    const getVideoDialogs = () => {
        setLoading(true);

        GetVideoDialogs(videoId, member?.MemberId)
            .then(result => {
                if (result.status === 200) {
                    setData(JSON.parse(result.content));
                }

                setLoading(false);
            })
            .catch()
    }

    const getVideoDialogsById = (dialogId) => {
        setLoading(true);

        GetVideoDialogById(+dialogId)
            .then(result => {
                if (result.status === 200) {
                    setData(result.content);
                } else {
                    navigate(url("dashboard"));
                }
            })
            .catch()

        setLoading(false);
    }

    const handleBackButton = () => {
        navigate(url("videopractice.videoflow.word", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_DIALOG_LIST_BACK_CLICK); //LOGGED
    }

    const handleContinueButton = () => {
        navigate(url("videopractice.videoflow.grammar", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_DIALOG_LIST_CONTINUE_CLICK); //LOGGED
    }

    const handleClosePronunciationModal = () => {
        setOpenPronunciationModal(false);
        isSinglePage
            ? getVideoDialogsById(outsideDialogId)
            : getVideoDialogs();
    }

    const handleClickDialog = (data) => {
        setSelectedDialog(data);
        setOpenPronunciationModal(true);
        // Sayfadaki scroll iptal ediliyor
        document.body.style.overflow = 'hidden';

        sendEvent(
            EventLogs.VIDEO_EXERCISE_DIALOG_LIST_DETAIL_CLICK,
            {
                dialogue: data?.dialog
            }
        ); //LOGGED
    }

    useEffect(() => {
        isSinglePage
            ? getVideoDialogsById(outsideDialogId)
            : getVideoDialogs();

    }, [])

    return (
        <>
            <div
                className={classNames("gray-section px-[10px] xs:px-[10px] text-center flex flex-col gap-3", {
                    "mt-5" : isSinglePage,
                    "mt-10": !isSinglePage
                })}
            >
                {loading && <Loading classnames="mb-4"/>}

                {!loading &&
                    <>
                        <p className="font-bold text-[18px] text-primary text-center">
                            {strings.video_practice.dialog.title}
                        </p>

                        <p className="text-center">
                            {strings.video_practice.dialog.description
                                .replace("#count#", data.length)
                                .replace("#multiple#", data.length > 1 ? "s" : "")
                            }
                        </p>

                        {data.length > 0 &&
                            data.map((item, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className={classNames("flex cursor-pointer justify-between items-center w-full h-17 border border-onboarding-border bg-white hover:bg-onboarding-bg rounded-[10px] px-4", {
                                        "mb-2": data.length - 1 === index
                                    })}
                                    onClick={() => handleClickDialog(item)}
                                >
                                    <div className="flex items-center w-full pr-0.5">
                                        <p className="text-[12px] md:text-[14px] text-start">{item.dialog}</p>
                                    </div>
                                    {!isSinglePage &&
                                        <div>
                                            <RadialBar
                                                score={item.score}
                                                height={100}
                                                fontSize={12}
                                                offsetY={5}
                                                size="35%"
                                                classnames="w-[50px] h-[80px]"
                                            />
                                        </div>
                                    }
                                </button>
                            ))
                        }
                    </>
                }
            </div>
            {!isSinglePage &&
                <div className="flex gap-8 my-5 justify-center">
                    <Button
                        type="button"
                        classnames="button primary-button-outline max-w-[150px]"
                        action={handleBackButton}
                        text={strings.pagination.previous}
                    />
                    <Button
                        type="button"
                        classnames="button primary-button max-w-[150px]"
                        action={handleContinueButton}
                        text={strings.speaking_lesson.quiz.continue}
                    />
                </div>
            }

            {(Object.keys(selectedDialog).length > 0 && openPronunciationModal) &&
                <PronunciationModal
                    pronunciationInfo={{
                        pronunciationId: selectedDialog?.dialogId,
                        content: selectedDialog?.dialog,
                        source: 'dialog',
                        logParameters: {
                            video_name: videoInfo?.videoName,
                            dialog: selectedDialog?.dialog
                        }
                    }}
                    setOpenPronunciationModal={handleClosePronunciationModal}
                    cancelScroll={true}
                    sendResult={!isSinglePage}
                />
            }
        </>
    )
}

export default Dialog;