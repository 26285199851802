import {useLocalization} from "../../../../hooks/useLocalization";
import React, {useEffect, useRef, useState} from "react";
import {AnswerStatutes} from "../../../../components/Constants";
import {closeAnswerStatus, openAnswerStatus} from "../../../../utils/OpenClose";
import {Button} from "../../../../components";
import AnswerStatus from "../Common/AnswerStatus";
import classNames from "classnames";
import {SendIcon} from "../../../../assets/svg";
import {getLogDescriptionAndParameter, hasNonWhitespaceCharacter} from "../../../../utils";
import {useDispatch} from "react-redux";
import {removeId, setCorrectId, setMistakeId} from "../../../../store/ExerciseReportSlice";
import useAnalytics from "../../../../hooks/useAnalytics";

const FillInTheBlank = (props) => {

    const { selectedQuestion, questionsLength, questionIndex, setQuestionIndex, setInnerStep, setComponentStep, logs } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const dispatch = useDispatch();

    const inputRef = useRef();
    const answerStatusRef = useRef();

    //Yazılan cevabın tutulduğu state.
    const [text, setText] = useState("");
    //"İleri" butonunun textinin tutulduğu state -> Default: Boş Bırak
    const [nextButtonText, setNextButtonText] = useState(strings.speaking_lesson.quiz.leave_empty);
    //"Kontrol Et" butonunun textinin tutulduğu state -> Default: Boş Bırak
    const [isClickedControlButton, setIsClickedControlButton] = useState(false);
    //Seçilen şıkkın doğru olup olmadığını tutan state -> Default: Cevaplanmadı
    const [answerStatus, setAnswerStatus] = useState(AnswerStatutes.NOT_ANSWERED);
    const [correctAnswers, setCorrectAnswers] = useState([]);
    //Kullanıcıya göstermek için doğru cevabın tutulduğu state
    const [correctAnswerText, setCorrectAnswerText] = useState("");

    /*
        * Kullanıcının verdiği cevabı soru ile replace ediyoruz.
        * ... replace ediliyor. Eğer ... bulunamazsa replace işlemi yapılmıyor.
        * Cevap doğru ise yazı rengi yeşil, yanlış ise kırmızı, kontrol edilmediyse base text yapılıyor.
    */
    const replaceAnswer = (question, userAnswer) => {
        if (question.includes('...') && userAnswer.length > 0) {

            let className = 'font-bold ';
            if(isClickedControlButton && answerStatus === AnswerStatutes.CORRECT) {
                className += 'text-green'
            } else if (isClickedControlButton && answerStatus === AnswerStatutes.WRONG) {
                className += 'text-red'
            } else {
                className += 'text-base-text'
            }

            const replacement = `<span class="${className}">${userAnswer}</span>`;
            return question.replace('...', replacement);
        } else {
            return question;
        }
    }

    /*
        * Geri butonu kontrolleri yapılıyor.
        * Eğer kullanıcı ilk soruda ise geri butonuna basıldığında içerik alanına gönderiliyor.
        * Eğer kullanıcı ilk soruda değil ise bir önceki soruya gönderiliyor.
    */
    const handlePreviousButton = () => {
        if (questionIndex === 0) {
            setInnerStep();
        } else {
            setQuestionIndex(questionIndex => questionIndex - 1);
        }
    }

    /*
        * ileri butonu kontrolleri yapılıyor.
        * if: Eğer kullanıcı herhangi bir cevap seçtiyse ve ilgili butona bastıysa kontrol etme işlemi gerçekleştiriliyor.
        * else if: Eğer kullanıcı herhangi bir cevap seçtiyse ve kontrol ettiyse bir sonraki soruya geçiliyor.
        * else if: Kullanıcı eğer son soruda ise farklı bir componente geçiş yapıyoruz.
        * else: Eğer kullanıcı herhangi bir işlem yapmadan butona basarsa bir sonraki soruya geçiliyor.
    */
    const handleNextButton = () => {
        //İki yerde log atıldığı için butonu log bilgileri alınıyor.
        let logInfoContinue = getLogDescriptionAndParameter(logs?.continue);
        let logInfoLeaveEmpty = getLogDescriptionAndParameter(logs?.leaveEmpty);

        if (text.length > 0 && !isClickedControlButton) {
            setIsClickedControlButton(isClickedControlButton => true);
        } else if (text.length > 0 && isClickedControlButton && questionIndex !== questionsLength - 1) {
            //'Devam Et' butonunda log atılıyor
            logInfoContinue && analytics.useAnalytics(logInfoContinue.logDescription, logInfoContinue.parameterObject);

            setQuestionIndex(questionIndex => questionIndex + 1);
        } else if (questionIndex === questionsLength - 1) {
            //'Devam Et' butonunda log atılıyor.
            (logInfoContinue && isClickedControlButton)
                && analytics.useAnalytics(logInfoContinue.logDescription, logInfoContinue.parameterObject);

            //'Boş Bırak' butonunda log atılıyor.
            (logInfoLeaveEmpty && !isClickedControlButton)
                && analytics.useAnalytics(logInfoLeaveEmpty.logDescription, logInfoLeaveEmpty.parameterObject);

            setComponentStep();
        } else {
            //'Boş Bırak' butonunda log atılıyor
            logInfoLeaveEmpty && analytics.useAnalytics(logInfoLeaveEmpty.logDescription, logInfoLeaveEmpty.parameterObject);

            setQuestionIndex(questionIndex => questionIndex + 1);
        }
    }

    // Kullanıcının yazdığı cevabın doğruluğu kontrol ediliyor ve doğru-yanlış durumu set ediliyor.
    const checkAnswer = (text) => {

        inputRef.current.value = "";
        let result;

        if(correctAnswers.includes(text.trim().toLowerCase())){
            setAnswerStatus(answerStatus => AnswerStatutes.CORRECT);
            result = true;
            dispatch(setCorrectId(selectedQuestion.id));
        } else {
            setAnswerStatus(answerStatus => AnswerStatutes.WRONG);
            result = false;
            dispatch(setMistakeId(selectedQuestion.id));
        }

        let logInfo = getLogDescriptionAndParameter(logs.check);
        if(logInfo) {
            logInfo.parameterObject['result'] = result;
            analytics.useAnalytics(logInfo.logDescription, logInfo.parameterObject);
        }
    }

    // Soruya geri gelindiğinde store'dan ilgili soruyu siler.
    useEffect(() => {
        dispatch(removeId(selectedQuestion.id));
    }, [selectedQuestion.id]);

    useEffect(() => {
        let tmpCorrectAnswers = [];

        selectedQuestion?.questionAnswers.map(answer => {
            if (answer.isCorrectAnswer) {
                tmpCorrectAnswers.push(answer.answer.content.toLowerCase());
            }
        });

        setCorrectAnswers(correctAnswers => tmpCorrectAnswers);
        setCorrectAnswerText(correctAnswerText => tmpCorrectAnswers.toString());
    }, [])

    /*
        * ileri butonu texti güncelleniyor.
        * if: "Kontrol Et" texti basılıyor.
        * else if: "Devam Et" texti basılıyor.
        * else: "Boş Bırak" texti basılıyor.
    */
    useEffect(() => {
        if (text.length > 0 && !isClickedControlButton) {
            setNextButtonText(strings.speaking_lesson.quiz.control);
        } else if (text.length > 0 && isClickedControlButton) {
            setNextButtonText(strings.speaking_lesson.quiz.continue);
        } else {
            setNextButtonText(strings.speaking_lesson.quiz.leave_empty);
        }
    }, [text, isClickedControlButton])


    // Herhangi bir şık seçilip "Kontrol Et" butonuna tıklandıysa doğru-yanlış componenti render ediliyor.
    useEffect(() => {
        if (text.length > 0 && isClickedControlButton) {
            checkAnswer(text);
            openAnswerStatus(answerStatusRef);
        }
    }, [text, isClickedControlButton])

    // Soru değiştirildiğinde seçili cevap ve kontrol buton durumu sıfırlanıyor.
    useEffect(() => {
        closeAnswerStatus(answerStatusRef);
        setAnswerStatus(answerStatus => AnswerStatutes.NOT_ANSWERED);
        setText("");
        setIsClickedControlButton(isClickedControlButton => false);
    }, [questionIndex]);

    return (
        <>
            <div className="flex flex-col">
                <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-center">
                    <h5 dangerouslySetInnerHTML={{ __html: replaceAnswer(selectedQuestion?.content, text) }}></h5>

                    <div className="relative mt-2 max-w-[332px] mx-auto">

                        <p className="mt-3 text-center mb-3">{strings.onboarding_exercises.complete_the_blank}</p>
                        <div
                            className={classNames("flex flex-col items-start transition-opacity duration-100", {
                                "opacity-100 h-full mt-3" : isClickedControlButton,
                                "opacity-0 h-0 mt-0" : !isClickedControlButton
                            })}
                        >
                            <p className="font-bold">{strings.onboarding_exercises.your_answer}:</p>
                            <p>{text}</p>
                        </div>

                        {answerStatus === AnswerStatutes.WRONG && (
                            <div
                                className={classNames("flex flex-col items-start transition-opacity duration-100", {
                                    "opacity-100 h-full mt-3": isClickedControlButton && answerStatus === AnswerStatutes.WRONG,
                                    "opacity-0 h-0 mt-0": !isClickedControlButton && answerStatus === AnswerStatutes.WRONG
                                })}
                            >
                                <p className="font-bold">{strings.onboarding_exercises.right_answer}</p>
                                <p>{correctAnswerText}</p>
                            </div>
                        )}

                        <input
                            ref={inputRef}
                            className="input-area w-full block mt-3 disabled:opacity-70 disabled:bg-white pr-9"
                            disabled={isClickedControlButton}
                            placeholder={isClickedControlButton ? "" : strings.ai_chatbot.write_here}
                            onChange={(e) => {
                                e.preventDefault();
                                setText(e.target.value);
                            }}
                            onKeyDown={e => {
                                if (e.keyCode === 13 && !(e.keyCode === 13 && e.shiftKey)) {
                                    e.preventDefault();

                                    if (hasNonWhitespaceCharacter(e.target.value)) {
                                        e.target.style.height = '52px';
                                        checkAnswer(e.target.value);
                                        setIsClickedControlButton(isClickedControlButton => true);
                                    }
                                }
                            }}
                        ></input>
                        <button
                            disabled={!hasNonWhitespaceCharacter(text)}
                            className={classNames("absolute right-3 bottom-0 h-[50px]", {
                                "opacity-70 cursor-not-allowed": !hasNonWhitespaceCharacter(text) || isClickedControlButton,
                            })}
                            onClick={() => {
                                checkAnswer(text);
                                setIsClickedControlButton(isClickedControlButton => true);
                            }}
                        >
                            <img src={SendIcon} className="w-5 h-5" alt="send-icon"/>
                        </button>
                    </div>

                    <div className="flex gap-8 my-5 justify-center">
                        <Button
                            classnames="button primary-button-outline max-w-[150px]"
                            text={strings.pagination.previous}
                            action={handlePreviousButton}
                        />
                        <Button
                            classnames="button primary-button max-w-[150px]"
                            text={nextButtonText}
                            action={handleNextButton}
                        />
                    </div>
                </div>

                <AnswerStatus ref={answerStatusRef} answerStatus={answerStatus}/>
            </div>
        </>
    )
}

export default FillInTheBlank;