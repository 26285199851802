import {useLocalization} from "../../../../hooks/useLocalization";
import React, {useEffect, useState} from "react";
import {GetInstallmentInformation} from "../../../../services/Market/MarketService";
import classNames from "classnames";
import {Arrow_Down} from "../../../../assets/svg";

const Installments = (props) => {

    const { packageInfo, changeCreditCardInfo, bankPosId, isOneShot } = props;
    const strings = useLocalization();
    const currencySymbol = packageInfo?.selectedProduct?.productDetail?.priceDetail?.currencySymbol;

    const [installmentOptions, setInstallmentOptions] = useState([]);
    const [cardInstallmentOption, setCardInstallmentOption] = useState(null);
    const [showOtherInstallment, setShowOtherInstallment] = useState(false);

    const renderInstallment = (installmentOption, index) => {
        return (
            <div key={index} className="w-full">
                <div className="w-full flex justify-between">
                    <div className="flex gap-2">
                        <button
                            id={`installment-option_${index}`}
                            type='button'
                            value={installmentOption.installment}
                            className={classNames("w-5 h-5 rounded-full border border-onboarding-border transition-colors duration-300 before:bg-white bg-white z-0 relative", {
                                "border-secondary before:w-2.5 before:h-2.5 before:!bg-secondary before:rounded-full before:absolute before:inset-0 before:m-auto before:transition-colors before:duration-300": cardInstallmentOption.installment === installmentOption.installment
                            })}
                            onClick={() => setCardInstallmentOption(cardInstallmentOption => installmentOption)}
                        ></button>
                        <label
                            htmlFor={`installment-option_${index}`}
                            className="-mt-0.5 cursor-pointer"
                        >
                            {installmentOption.installment === 1 ? strings.market.checkout.one_shot : installmentOption.installment + " x "} {installmentOption.monthlyPrice} {currencySymbol}
                        </label>
                    </div>
                    <label
                        htmlFor={`installment-option_${index}`}
                        className='font-bold -mt-0.5 cursor-pointer'
                    >
                        {installmentOption.price} {currencySymbol}
                    </label>
                </div>
                {installmentOption.discountDetails.length > 0 && (
                    <p className='text-end -mb-3 -mt-1.5 text-[14px]'>{`-${installmentOption.discountDetails[0].discountAmount} ${currencySymbol} (${strings.market.checkout.one_shot_discount})`}</p>
                )}
            </div>
        )
    }

    useEffect(() => {
        const model = {
            brandId: +process.env.REACT_APP_BRAND_ID,
            totalPrice: packageInfo?.selectedProduct?.productDetail?.priceDetail?.totalPriceWithoutFormat,
            bankPosId: bankPosId,
            totalDiscountRate: packageInfo?.discountInformation?.totalDiscount
        }

        GetInstallmentInformation(model)
            .then(result => {
                if(result.status === 200) {
                    let resultContent = JSON.parse(result.content);
                    isOneShot
                        ? setInstallmentOptions(installmentOptions => [resultContent[0]])
                        : setInstallmentOptions(installmentOptions => resultContent)
                    //Başlangıçta Tek Çekim'in seçili gelmesini sağlıyoruz
                    setCardInstallmentOption(cardInstallmentOption => resultContent[0])
                }
            })
            .catch(e => {})
    }, [bankPosId]);

    useEffect(() => {
        if(cardInstallmentOption) {
            changeCreditCardInfo({ priceInfo: cardInstallmentOption });
        }
    }, [cardInstallmentOption]);

    return (
        <div className="flex flex-col mt-2">
            <label className="block text-start mb-0.5">{strings.market.checkout.installment_options}</label>
            <div className='transparent-section w-full border-onboarding-border flex flex-col gap-2'>
                {installmentOptions.map((installmentOption, index) => (
                    (installmentOption.installment === 1 || installmentOption.installment % 3 === 0) && renderInstallment(installmentOption, index)
                ))}
                {!isOneShot &&
                    <>
                        <hr className='my-0.5 border border-onboarding-border'></hr>
                        <div className='relative text-start'>
                            <button
                                id="collapse-button"
                                className='font-bold w-full text-start'
                                type='button'
                                onClick={() => setShowOtherInstallment(!showOtherInstallment)}
                            >
                                {strings.market.checkout.other_installment_discount} ({installmentOptions.filter((_, index) => (index + 1) % 3 !== 0 && index + 1 !== 1).length})
                            </button>
                            <img
                                src={Arrow_Down}
                                className={classNames("material-symbols-outlined absolute right-0 top-2 transition-transform duration-300 cursor-pointer", {
                                    "rotate-180": showOtherInstallment
                                })}
                                alt="arrow_down"
                            />
                            <div
                                className={classNames('w-full flex flex-col gap-2 mt-1 transition-all duration-300 overflow-hidden', {
                                    "max-h-0": !showOtherInstallment,
                                    "max-h-screen": showOtherInstallment,
                                })}>
                                {installmentOptions?.map((installmentOption, index) => (
                                    (installmentOption.installment !== 1 && installmentOption.installment % 3 !== 0) && renderInstallment(installmentOption, index)
                                ))}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Installments;