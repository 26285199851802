import {fetchHeader} from "../services/Header";

const useMemberNote = () => {
    return (notes) => {

        if (notes.length === 0) return;

        const url = '/_services/member/v1/member_note/create_range/';

        fetch(process.env.REACT_APP_API_URL + url, fetchHeader(notes))
            .then()
            .catch();
    };
}

export default useMemberNote;