export const wordsAndIdioms = [
    {
        "idioms": [
            [
                "A little bird told me",
                "Bana kuşlar söyledi"
            ],
            [
                "A penny for your thoughts",
                "Ne düşünüyorsun?"
            ],
            [
                "Abide by a decision",
                "Karara uymak"
            ]
        ],
        "word": [
            "have",
            "sahip olmak",
            "I have an older brother",
            "Benim bir abim var(sahibim)."
        ]
    },
    {
        "idioms": [
            [
                "Add fuel to the fire",
                "Yangına körükle gitmek"
            ],
            [
                "Against the clock",
                "Zamana karşı"
            ],
            [
                "Age before beauty",
                "Önden lütfen/ Önden yaşlılar"
            ]
        ],
        "word": [
            "some",
            "biraz, birkaç",
            "Ken went to the supermarket to buy some eggs.",
            "Ken birkaç tane yumurta almak için süpermarkete gitti."
        ]
    },
    {
        "idioms": [
            [
                "All bets are off",
                "Her şey iptal "
            ],
            [
                "All eyes on me",
                "Bütün gözler üstümde"
            ],
            [
                "All heart",
                "Canı gönülden"
            ]
        ],
        "word": [
            "other",
            "diğer",
            "We cannot see the other side of the moon.",
            "Ay`ın diğer yüzünü göremeyiz."
        ]
    },
    {
        "idioms": [
            [
                "All over bar the shouting",
                "Kesin sonuç"
            ],
            [
                "All over the place",
                "Her şey her yerde"
            ],
            [
                "All over the shop",
                "Karman çorman"
            ]
        ],
        "word": [
            "all",
            "bütün, hepsi",
            "All my friends went to the park.",
            "Bütün arkadaşlarım parka gitti."
        ]
    },
    {
        "idioms": [
            [
                "All talk and no trousers",
                "Laf çok icraat yok"
            ],
            [
                "All your eggs in one basket",
                "Her şeyini riske atmak"
            ],
            [
                "And all that jazz",
                "Ve bunun gibi bir sürü saçma şey"
            ]
        ],
        "word": [
            "word",
            "sözcük, kelime",
            "I was remembering the words of my old friend.",
            "Eski bir arkadaşımın kelimelerini hatırlıyordum."
        ]
    },
    {
        "idioms": [
            [
                "Angry as a bear",
                "Ayı kadar kızgın"
            ],
            [
                "Ants in your pants",
                "İçi içine sığmayan"
            ],
            [
                "Apple of your eye",
                "Birinin gözbebeği olmak"
            ]
        ],
        "word": [
            "each",
            "her",
            "She kissed each of her children.",
            "Her çocuğunu öptü."
        ]
    },
    {
        "idioms": [
            [
                "Apron strings",
                "Aşırı bağlılık"
            ],
            [
                "Argue the toss",
                "Nuh deyip peygamber dememek"
            ],
            [
                "Arm and a leg",
                "Pahalıya almak"
            ]
        ],
        "word": [
            "kind",
            "tür, çeşit",
            "What kind of person is he?",
            "O ne tür bir insan?"
        ]
    },
    {
        "idioms": [
            [
                "Caught with your hand in the cookie jar",
                "Enselenmek"
            ],
            [
                "Chalk and cheese",
                "Arada dağlar kadar fark var"
            ],
            [
                "Champ at the bit",
                "İçi içine sığmamak"
            ]
        ],
        "word": [
            "write",
            "yazmak",
            "An author writes stories.",
            "Yazar hikayeler yazar."
        ]
    },
    {
        "idioms": [
            [
                "Change of heart",
                "Fikir değistirmek"
            ],
            [
                "Cheap shot",
                "Belden aşağı vurma"
            ],
            [
                "Cherry pick",
                "İnce eleyip sık dokumak"
            ]
        ],
        "word": [
            "so",
            "çok, bu yüzden",
            "She is so beautiful.",
            "O çok güzel."
        ]
    },
    {
        "idioms": [
            [
                "Chew on a bone",
                "Yoğun düşünme"
            ],
            [
                "Child`s play",
                "Çocuk oyuncağı"
            ],
            [
                "Chip on your shoulder",
                "Öfkesi burnunda olmak"
            ]
        ],
        "word": [
            "more",
            "daha",
            "Please sir, I want more.",
            "Lütfen efendim, daha fazla istiyorum."
        ]
    },
    {
        "idioms": [
            [
                "Cigarette paper",
                "Etle tırnak gibi olmak"
            ],
            [
                "Circle the wagons",
                "Gardını almak"
            ],
            [
                "Circling the drain",
                "Kaçınılmaz sona yaklaşmak"
            ]
        ],
        "word": [
            "day",
            "gün",
            "Sunday is the last day of the week.",
            "Pazar haftanın son günü."
        ]
    },
    {
        "idioms": [
            [
                "Clean bill of health",
                "Sağlam raporu alan kimse"
            ],
            [
                "Clean sheet",
                "Yeni / Temiz bir sayfa"
            ],
            [
                "Clean slate",
                "Beyaz bir sayfa açmak"
            ]
        ],
        "word": [
            "sound",
            "ses",
            "The sound of barking dogs bothers me while I am sleeping.",
            "Havlayan köpeklerin sesi uyurken beni rahatsız ediyor."
        ]
    },
    {
        "idioms": [
            [
                "Close but no cigar",
                "İyi deneme ama olmadı"
            ],
            [
                "Close to your heart",
                "Kişiye hitap eden"
            ],
            [
                "Closed book to me",
                "Kapalı kutu"
            ]
        ],
        "word": [
            "number",
            "numara",
            "Messi is the number one player in the world.",
            "Messi dünyadaki bir numaralı oyuncudur."
        ]
    },
    {
        "idioms": [
            [
                "Cold feet",
                "Gözü yememek"
            ],
            [
                "Cold fish",
                "Soğuk nevale"
            ],
            [
                "Cold light of day",
                "Sakin kafayla"
            ]
        ],
        "word": [
            "call",
            "aramak",
            "A girl called her boyfriend at night.",
            "Kız erkek arkadaşını geceleyin aradı."
        ]
    },
    {
        "idioms": [
            [
                "Give a big hand",
                "Alkışlamak"
            ],
            [
                "Give me five",
                "Çak!/Çak bir beşlik!"
            ],
            [
                "Give your eye teeth",
                "Varını yoğunu vermek/Varını yoğunu ortaya koymak"
            ]
        ],
        "word": [
            "people",
            "insanlar",
            "People sleep when they are tired.",
            "İnsanlar yorgun olduklarında uyurlar."
        ]
    },
    {
        "idioms": [
            [
                "Glass ceiling",
                "İşyerinde kadınların ve azınlıkların yükselmesindeki engeller"
            ],
            [
                "Go awry",
                "Aksi gitmek/Ters gitmek"
            ],
            [
                "Go bananas",
                "Keçileri kaçırmak"
            ]
        ],
        "word": [
            "find",
            "bulmak",
            "The police needs to find the evidence to put him into a jail.",
            "Polisin onu hapse koyması için delili bulması gerekiyor."
        ]
    },
    {
        "idioms": [
            [
                "Go down like a lead balloon",
                "Hoş karşılanmamak"
            ],
            [
                "Go for the jugular",
                "Zayıf yerinden vurmak"
            ],
            [
                "Go over like a lead balloon",
                "Tamamen basarısız olmak"
            ]
        ],
        "word": [
            "work",
            "çalışmak",
            "Many employees work at weekends.",
            "Birçok çalışan haftasonları çalışır."
        ]
    },
    {
        "idioms": [
            [
                "Go play in traffic",
                "Defol git gözüm görmesin seni!"
            ],
            [
                "Go tell it to birds",
                "Külahıma anlat"
            ],
            [
                "Go the distance",
                "Sonuna kadar götürmek"
            ]
        ],
        "word": [
            "place",
            "yer",
            "Keep your credit cards in a safe place.",
            "Kredi kartlarını güvenli bir yerde tut."
        ]
    },
    {
        "idioms": [
            [
                "Golden rule",
                "Altın kural"
            ],
            [
                "Going overboard",
                "Abartmak"
            ],
            [
                "Good egg",
                "İyi adam"
            ]
        ],
        "word": [
            "attendance",
            "katılım",
            "Students whose attendance falls below 80% will receive a warning letter.",
            "Katılımı %80`in altına düşen öğrenciler uyarı mektubu alırlar."
        ]
    },
    {
        "idioms": [
            [
                "Gravy train",
                "Köşeyi dönmek"
            ],
            [
                "Grease monkey",
                "Makinist/Çarkçı/Usta/Tamirci"
            ],
            [
                "Great guns",
                "Tüm gücünü ve enerjisini harcamak"
            ]
        ],
        "word": [
            "authority",
            "yetki, otorite",
            "We don`t have the authority to kick kids out of school unless they have been especially violent.",
            "Şiddet içeren davranışlar sergilemedikçe çocukları okuldan atmak gibi bir yetkimiz yok."
        ]
    },
    {
        "idioms": [
            [
                "Grey Cardinal",
                "Perde arkasında gücünü kullanmak"
            ],
            [
                "Grey matter",
                "Akıl/Zeka/Beyin"
            ],
            [
                "Grey suits",
                "Perde arkasındakiler"
            ]
        ],
        "word": [
            "equipment",
            "ekipman",
            "He is saving up to buy himself some new football equipment.",
            "Yeni futbol ekipmanları almak için tasarruf yapıyor."
        ]
    },
    {
        "idioms": [
            [
                "Know a hawk from a handsaw",
                "İyi bilmek"
            ],
            [
                "Know full well",
                "Emin Olmak"
            ],
            [
                "Know the ropes",
                "Bilgi sahibi kimse"
            ]
        ],
        "word": [
            "burst",
            "patlamak",
            "The fireworks burst in the air with brilliant colors.",
            "Havai fişekler parlak renklerle havada patladı."
        ]
    },
    {
        "idioms": [
            [
                "Know where all the bodies are buried",
                "Bir şeyi tüm ayrıntılarıyla bilmek"
            ],
            [
                "Know which way the wind blows",
                "Rüzgarın hangi yönden eseceğini bilmek"
            ],
            [
                "Know your place",
                "Haddini bilmek"
            ]
        ],
        "word": [
            "comparison",
            "karşılaştırma, kıyaslamak",
            "Country life is very peaceful in comparison with city life.",
            "Kır hayatı şehir hayatıyla kıyaslandığında daha huzurludur."
        ]
    },
    {
        "idioms": [
            [
                "Labor of love",
                "Gönüllü yapılan iş"
            ],
            [
                "Lame duck",
                "Topal ördek/Sakat"
            ],
            [
                "Landslide victory",
                "Ezici oy üstünlüğü ile kazanılmış zafer"
            ]
        ],
        "word": [
            "dependable",
            "güvenilir",
            "He is a good friend of mine, very helpful, and always dependable.",
            "O benim iyi bir arkadaşım çok yardımsever ve güvenilir biridir."
        ]
    },
    {
        "idioms": [
            [
                "Lap of the gods",
                "Allah'a kalmış/Elinden bir şey gelmemek"
            ],
            [
                "Last hurrah",
                "Son gösteri/Son performans"
            ],
            [
                "Last straw",
                "Bardağı taşıran son damla"
            ]
        ],
        "word": [
            "patience",
            "sabır",
            "Jean-Jacques Rousseau once observed that patience is bitter, but its fruit is sweet.",
            "Jean-Jacques Rousseau 'Sabır acıdır fakat meyvesi tatlıdır' demiştir."
        ]
    },
    {
        "idioms": [
            [
                "Laugh to see a pudding crawl",
                "Her şeye gülen bir kişi"
            ],
            [
                "Laughing stock",
                "Komik duruma düşmek"
            ],
            [
                "Laughter is the best medicine",
                "Gülmek en iyi ilaçtır"
            ]
        ],
        "word": [
            "poet",
            "şair",
            "I know a poet whose poems are widely read.",
            "Şiirleri çokça okunan bir şair tanıyorum."
        ]
    },
    {
        "idioms": [
            [
                "Law unto yourself",
                "Bildiğini okumak"
            ],
            [
                "Lay down the law",
                "Patronluk taslamak"
            ],
            [
                "Lean and mean",
                "Elleri kolları sıvayıp beklemek"
            ]
        ],
        "word": [
            "impolite",
            "kaba",
            "It would have been impolite to refuse.",
            "Reddetmek kaba olurdu."
        ]
    },
    {
        "idioms": [
            [
                "Learn the ropes",
                "İşin nasıl yapıldığını öğrenmek"
            ],
            [
                "Leave well alone",
                "Bir şeye karışmamak"
            ],
            [
                "Left to your own devices",
                "Kendi haline bırakmak"
            ]
        ],
        "word": [
            "raise",
            "yükseltmek; zam",
            "I just got a raise of over 50 cents an hour.",
            "Saatlik 50 cent zam aldım."
        ]
    },
    {
        "idioms": [
            [
                "Lend an ear",
                "Kulak asmak"
            ],
            [
                "Let bygones be bygones",
                "Eski defterleri kapamak"
            ],
            [
                "Let sleeping dogs lie",
                "Bana dokunmayan yılan bin yıl yaşasın"
            ]
        ],
        "word": [
            "similarity",
            "benzerlik",
            "Despite the twins`s physical similarity, their characters are totally different.",
            "İkizlerin fiziksel benzerliğine rağmen karakterleri çok farklı."
        ]
    },
    {
        "idioms": [
            [
                "Let the cat out of the bag",
                "Ağzında kaçırmak"
            ],
            [
                "Let the chips fall where they may",
                "İşi olacağına bırakmak"
            ],
            [
                "Let the genie out of the bottle",
                "Ok yaydan çıktı/Okun yaydan çıkmış olması"
            ]
        ],
        "word": [
            "solution",
            "çözüm",
            "Love is the key to the solution of the problems of the world.",
            "Sevgi dünyadaki sorunların çözümü için anahtardır."
        ]
    },
    {
        "idioms": [
            [
                "Let your hair down",
                "Rahatlamak/İçini dökmek"
            ],
            [
                "Let`s call it a day",
                "Bugünlük bu kadar yeter"
            ],
            [
                "Letter of the law",
                "Kanun hükmü"
            ]
        ],
        "word": [
            "adult",
            "yetişkin",
            "You cannot get a tattoo until you are an adult.",
            "Yetişkin olana kadar dövme yaptıramazsın."
        ]
    }
]