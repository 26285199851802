import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ModalOverlay } from '../components'
import { Brands, Channel } from "../components/Constants";
import useAnalytics from '../hooks/useAnalytics';
import useAuth from '../hooks/useAuth';
import { useLocalization } from '../hooks/useLocalization';
import { CreateNpsService, GetDemoSurvey } from '../services/Nps/NpsService';
import { closeModal, getLanguageIdByLanguageKey, openModal } from '../utils';
import NpsContent from './components/NpsContent';

const DemoNps = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const location = useLocation();
    const language = useSelector(state => state.localizationStore.language);
    const { member, token } = useAuth();

    const modalRef = useRef();
    const modalOverlayRef = useRef();
    const successIconRef = useRef();
    const commentErrorRef = useRef();

    //İşlem başarılı olduktan sonra başarılı mesajının gösterilmesini sağlayan state
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    //Servisten gelen criterion detaylarını tuttuğumuz state
    const [criterionDetail, setCriterionDetail] = useState({
        popupVisibility: false,
        title: "",
        criterionId: 0
    });
    //NPS oluştururken servise bu modeli gönderiyoruz

    const [model, setModel] = useState({
        memberId: "",
        criterionId: 0,
        channelId: 0,
        comment: "",
        rate: 0
    });

    //NPS oluşturma modelini sıfırlamak için kullanılan fonksiyon
    const clearModel = () => {
        setModel(model => ({
            ...model,
            memberId: "",
            criterionId: 0,
            channelId: 0,
            comment: "",
            rate: 0
        }))
    }

    //Kullanıcının NPS durumunu getiriyoruz
    const getDemoSurvey = async (model) => {

        const result = await GetDemoSurvey(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setCriterionDetail(criterionDetail => ({
                ...criterionDetail,
                title: resultContent.title,
                popupVisibility: resultContent.checkPageVisibility,
                criterionId: resultContent.criterionId
            }))
        }
    }

    const createDemoNps = async () => {
        let valid = true;

        if (model.comment.length > 250) {
            valid = false;
            commentErrorRef.current.innerText = strings.popup.nps.comment_error_message
        }

        if (valid) {
            await CreateNpsService(model);
            // Başarılı mesajını göstermek için showSuccessMessage state'ini true'ya çekiyoruz
            setShowSuccessMessage(showSuccessMessage => true);
            //4 saniye sonra popup'ı kapatıp tutulan modeli sıfırlıyoruz
            setTimeout(() => {
                closeModal(modalOverlayRef, modalRef);
                clearModel();
            }, 3000)

            //Öğrencinin göndermiş olduğu NPS skoru loglanıyor.
            // if (model.rate > 0)
            //     analytics.useAnalytics(
            //         EventLogs.NPS_SURVEY_REPLIED,
            //         {
            //             score: model.rate
            //         }
            //     )
        }
    }

    //Kullanıcı dashboard'a geldiğinde NPS durumunu bize getiren servise gidilip detaylar yakalanıyor
    //Dil değişince de servise giderek başlıkları dile göre servisten tekrar getiriyoruz
    useEffect(() => {
        if ((location.pathname === '/dashboard') && member?.MemberId) {
            let model = {
                applicationId: Brands.KO,
                memberId: member?.MemberId,
                languageId: getLanguageIdByLanguageKey(language)
            }

            getDemoSurvey(model);
        }
    }, [location.pathname, language, token])

    //NPS durumunda gelen popup göster değişkeni true geldiğinde popup'ı açıyoruz
    useEffect(() => {
        if (criterionDetail.popupVisibility) {
            openModal(modalOverlayRef, modalRef);
        } else {
            closeModal(modalOverlayRef, modalRef);
        }

        setShowSuccessMessage(showSuccessMessage => false);
        setModel(model => ({
            ...model,
            memberId: member?.MemberId,
            channelId: Channel.WebSite,
            criterionId: criterionDetail.criterionId
        }))
    }, [criterionDetail.popupVisibility])


    return (
        <>
            <ModalOverlay ref={modalOverlayRef} />
            <div ref={modalRef} id="modal"
                className="transition-transform duration-300 fixed hidden z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] sm:w-[500px] bg-white rounded-[10px] px-2 sm:px-8 py-6 space-y-5 drop-shadow-lg">
                <NpsContent
                    criterionDetail={criterionDetail}
                    showSuccessMessage={showSuccessMessage}
                    successIconRef={successIconRef}
                    commentErrorRef={commentErrorRef}
                    model={model}
                    uniqueValue="demo"
                    setModel={setModel}
                    action={() => createDemoNps(model)}
                />
            </div>

        </>
    )
}

export default DemoNps