import { Error } from "../components"
import { Icon_Confused } from "../../../assets/svg";
import { useLocalization } from "../../../hooks/useLocalization";
import { url } from "../../../routes/utility";
import useAuth from "../../../hooks/useAuth";

const PostponeNoRight = () => {

    const strings = useLocalization();
    const { token } = useAuth();

    return (
        <Error
            icon={Icon_Confused}
            title={strings.support.postpone_lesson_no_right.no_postpone_right}
            content={strings.support.postpone_lesson_no_right.no_postpone_right_information}
            to={url("market.rightcatalog")}
            state={{ "token": token }}
            buttonText={strings.support.buy}
        />
    )
}

export default PostponeNoRight