import {CreateMemberExercise} from "../../../services/OnboardingExercise/OnboardingExerciseService";
import React, {useEffect, useRef, useState} from "react";
import {EventLogs} from "../../../components/Constants";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {url} from "../../../routes/utility";
import {useLocalization} from "../../../hooks/useLocalization";
import {
    ExerciseLockActive,
    ExerciseLockFuture,
    ExerciseLockPast
} from "../../../assets/svg";
import {ModalOneButton, ModalOverlay} from "../../../components";
import {closeModal, getBrowserName, openModal} from "../../../utils";
import useAnalytics from "../../../hooks/useAnalytics";

const DashboardOnboardingExercise = () => {

    const strings = useLocalization();
    const { member } = useAuth();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    //Kullanıcıyı egzersize alıp/almama durumunu kontrol eden state.
    const [exerciseAvailability, setExerciseAvailability] = useState(null);
    //Kullanıcının egzersiz durumlarını tutan state.
    const [exerciseStatus, setExerciseStatus] = useState({});
    //Mikrofon iznini belirleyen state.
    const [isHaveMicrophonePermission, setIsHaveMicrophonePermission] = useState("not determined");
    //Modal text'ini belirleyen state.
    const [modalText, setModalText] = useState("");

    //Adaylar için onboarding member exercise oluşturuluyor.
    const createMemberExercise = async (memberId) => {
        try {
            const result = await CreateMemberExercise(memberId);

            setExerciseAvailability(exerciseAvailability => result.isAvailable);
            setExerciseStatus(exerciseStatus => result.status);
        } catch (e) {}
    }

    const ExerciseStatuses = Object.freeze(
        {
            "ACTIVE": 0,
            "SOLVED": 1,
            "FUTURE": 2,
            "NOT_SOLVED": 3
        }
    )

    const getMicrophonePermission = () => {

        let browserName = getBrowserName();

        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                    //Mikrofona izin verildiğinde loglanıyor.
                    setIsHaveMicrophonePermission(isHaveMicrophonePermission => true);

                    browserName !== 'safari' && window.location.reload();
                })
                .catch((e) => {
                    browserName !== 'safari' && window.location.reload();
                })
        }
    }

    const handleStartButton = () => {
        if (isHaveMicrophonePermission === "prompt") {
            getMicrophonePermission();
        } else if (isHaveMicrophonePermission === "denied") {
            setModalText(modalText => strings.ai_chatbot.errors.microphone_not_allowed_error);
            openModal(modalOverlayRef, modalRef);
        } else if(!exerciseAvailability) {
            setModalText(modalText => strings.onboarding_exercises.start_study_error_message);
            openModal(modalOverlayRef, modalRef);
        } else {
            /*
                * Eğer kullanıcının aktif çalışması (0) var ise 0'ın indexine bakılıp içeriye alınıyor.
                * Eğer kullanıcının çözülmüş çalışması (1) var ise 1'ın indexine bakılıp içeriye alınıyor.
            */
            let tmpDayIndex;
            let zeroIndex = exerciseStatus.lastIndexOf(ExerciseStatuses.ACTIVE);

            if(zeroIndex === -1) {
                tmpDayIndex = exerciseStatus.lastIndexOf(ExerciseStatuses.SOLVED);
            } else {
                tmpDayIndex = exerciseStatus.lastIndexOf(ExerciseStatuses.ACTIVE);
            }

            analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_START_PRACTICE, { day_count: tmpDayIndex + 1});
            navigate(url("onboardingexercise", { dayIndex: tmpDayIndex }));
        }
    }

    const getDayContent = (dayStatus, index) => {
        switch (dayStatus) {
            case ExerciseStatuses.ACTIVE:
                return (
                    <div className="flex gap-3">
                        <img src={ExerciseLockActive} alt="lock_open"/>
                        <span className="text-secondary">{strings.onboarding_exercises.day_text.replace("#dayIndex#", index + 1 )}</span>
                    </div>
                )
            case ExerciseStatuses.SOLVED:
                return (
                    <div className="flex gap-3 relative">
                        <img src={ExerciseLockPast} alt="lock_open"/>
                        <span
                            className="text-secondary/50">{strings.onboarding_exercises.day_text.replace("#dayIndex#", index + 1)}</span>
                        <svg
                            className="h-[22px] w-[22px] text-secondary/50 absolute -right-7 top-[1px]"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            />
                        </svg>
                    </div>
                )
            case ExerciseStatuses.FUTURE:
                return (
                    <div className="flex gap-3">
                        <img src={ExerciseLockFuture} alt="lock_open"/>
                        <span>{strings.onboarding_exercises.day_text.replace("#dayIndex#", index + 1)}</span>
                    </div>
                )
            case ExerciseStatuses.NOT_SOLVED:
                return (
                    <div className="flex gap-3 relative">
                        <div className="absolute inset-0 top-3 w-[115%] -mx-1.5 h-0.5 bg-secondary/50"></div>
                        <img src={ExerciseLockPast} alt="lock_open"/>
                        <span
                            className="text-secondary/50">{strings.onboarding_exercises.day_text.replace("#dayIndex#", index + 1)}</span>
                    </div>
                )
            default:
                return null;
        }
    }

    useEffect(() => {
        if (member) {
            createMemberExercise(member?.MemberId);
        }
    }, []);

    useEffect(() => {
        const checkMicrophoneAllowed = async () => {
            let microphonePermissionStatus = navigator.permissions.query({name: 'microphone'})
                .then(permissionStatus => {
                    return permissionStatus.state
                })
            return await microphonePermissionStatus
        }

        checkMicrophoneAllowed().then(status => {
            setIsHaveMicrophonePermission(isHaveMicrophonePermission => status);
        })
    }, []);


    return (
        <>
            {exerciseStatus.length > 0 && (
                <div className="transparent-section mt-4">
                    <h5 className="text-secondary">{strings.onboarding_exercises.title}</h5>
                    <p className="mt-[27px]">{strings.onboarding_exercises.dashboard_message_1}</p>
                    <p className="mt-[27px]">{strings.onboarding_exercises.dashboard_message_2}</p>
                    <div className="flex flex-col items-center justify-center mt-5 gap-2">
                        {exerciseStatus.map((day, index) => (
                            <div key={index}>
                                {getDayContent(day, index)}
                            </div>
                        ))}
                    </div>
                    <div className="btn-outer-div my-5">
                        <button
                            onClick={handleStartButton}
                            className="button primary-button"
                        >
                            {strings.onboarding_exercises.start_study}
                        </button>
                    </div>
                </div>
            )}

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={modalText}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
        </>
    )
}

export default DashboardOnboardingExercise;