import { useLocalization } from '../../../../hooks/useLocalization'
import { addTimeZone, getTimeZone } from '../../../../utils';
import useAuth from '../../../../hooks/useAuth';
import {Default_Teacher} from "../../../../assets/svg";
import { url } from '../../../../routes/utility';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

const LessonInformation = ({ data }) => {
    const strings = useLocalization();
    const { member } = useAuth();
    const timeZone = getTimeZone(member.Timezone);
    const navigate = useNavigate();

    return (
        <>
            <div className="flex flex-col text-sm mt-4 pb-2">
                <p className="mb-5 sm:mb-3">{strings.speaking_lesson.daily_lesson.daily_lesson_information}</p>
                <div className="flex flex-col sm:flex-row sm:text-start sm:items-center">
                    {(data.teacher) && (
                        <img
                            className={classNames("rounded-full w-[100px] mx-auto sm:mr-3", {
                                "cursor-pointer": !(data?.teacher?.id === 1320 || data?.teacher?.id === 1500 || data?.teacher?.id === 1501)
                            })}
                            onClick={() => !(data?.teacher?.id === 1320 || data?.teacher?.id === 1500 || data?.teacher?.id === 1501) && navigate(url("teachers.detail", { teacherId: data?.teacher?.id }))}
                            src={data?.teacher?.profilePicture ?? Default_Teacher}
                            alt=""
                        />
                    )}
                    <div className="sm:mr-auto">
                        <p className="mt-3 sm:mt-0">{strings.speaking_lesson.daily_lesson.your_teacher} <span className="font-bold">{data.teacher && data?.teacher?.fullName.replace(" (Sanal Egitmen)", "")}</span></p>
                        <p className="mb-5 sm:mb-0 mt-2">{strings.speaking_lesson.daily_lesson.lesson_time} <span className="font-bold">{addTimeZone(data?.lessonTime,timeZone)}</span></p>
                    </div>
                </div>
                <p className="sm:mt-3">{strings.speaking_lesson.daily_lesson.lesson_content}</p>
            </div>

        </>
    )
}

export default LessonInformation