import React from "react";
import { Padlock, Unlocked } from "../../../../assets/svg"
import { useLocalization } from "../../../../hooks/useLocalization";

const AwardsRow = ({ data }) => {

    const strings = useLocalization();

    return (
        <>
            <div className="flex items-center justify-start gap-2 p-5 w-full h-[95px] rounded-[10px] border mb-5">
                <div className="h-[54px] w-[54px]">
                    {data?.isRewardLocked &&
                        <img
                            src={Padlock ?? ""}
                            width="54"
                            height="54"
                            className="w-full h-full"
                            alt="padlock"
                        />
                    }

                    {(!data?.isRewardLocked && data?.iconUrl && data?.iconUrl.length > 0) &&
                        <img
                            src={data?.iconUrl ?? ""}
                            width="54"
                            height="54"
                            className="w-full h-full"
                            alt="unlocked"
                        />
                    }

                    {!data?.isRewardLocked && (!data?.iconUrl || data?.iconUrl.length === 0) &&
                        <img
                            src={Unlocked ?? ""}
                            width="54"
                            height="54"
                            className="w-full h-full"
                            alt="unlocked"
                        />
                    }
                </div>

                <div className="flex flex-col">
                    <p className="text-[16px] font-bold">
                        {data?.isRewardLocked
                            ? strings.profile.awards.reward_title.replace("#streakCount#", data?.streakCount)
                            : data?.title
                        }
                    </p>
                    <p className="text-[14px] -mt-1 mr-1">
                        {data?.isRewardLocked
                            ? strings.profile.awards.reward_content.replace("#streakCount#", data?.streakCount)
                            : data?.description
                        }
                    </p>
                </div>
            </div>
        </>
    )
}

export default AwardsRow