
export const staticData = {
    "studentQuestions": [
        {
            "title": {
                "tr": "Yeni öğrenen birisi olarak, Amerikalı eğitmenlerle nasıl konuşacağım?",
                "en": "Yeni öğrenen birisi olarak, Amerikalı eğitmenlerle nasıl konuşacağım?"
            },
            "text": {
                "tr": "Eğitmenlerimiz sıfırdan başlayan, çekingen ve endişeli öğrencilerimize o kadar alıştılar ki! Bu yüzden yavaş yavaş ve tane tane başlıyoruz. Kitaplarınız da nispeten kolay oluyor. Ta ki siz alışana kadar. Alıştıktan sonra hızınız yavaş yavaş artıyor. Yüzme öğrenmek gibi düşünün. Suya girmeden yüzemezsiniz değil mi?",
                "en": "Eğitmenlerimiz sıfırdan başlayan, çekingen ve endişeli öğrencilerimize o kadar alıştılar ki! Bu yüzden yavaş yavaş ve tane tane başlıyoruz. Kitaplarınız da nispeten kolay oluyor. Ta ki siz alışana kadar. Alıştıktan sonra hızınız yavaş yavaş artıyor. Yüzme öğrenmek gibi düşünün. Suya girmeden yüzemezsiniz değil mi?"
            }
        },
        {
            "title": {
                "tr": "10, 20 veya 30 dakika yeterli mi?",
                "en": "10, 20 veya 30 dakika yeterli mi?"
            },
            "text": {
                "tr": "Günlük 20 dakika en çok tercih edilen programlarımızdan biri. Bu örnek üzerinden gidersek, ayda 440 dakika yapıyor (22 iş günü üzerinden hesaplarsak), bu da 7 saatten fazla ders süresine denk geliyor. Size verilen ödevleri ve diğer materyalleri düşündüğünüzde, oldukça yeterli bir süre. Fazlasına ihtiyaç duyarsanız her zaman dakikalarınızı arttırabilirsiniz.",
                "en": "Günlük 20 dakika en çok tercih edilen programlarımızdan biri. Bu örnek üzerinden gidersek, ayda 440 dakika yapıyor (22 iş günü üzerinden hesaplarsak), bu da 7 saatten fazla ders süresine denk geliyor. Size verilen ödevleri ve diğer materyalleri düşündüğünüzde, oldukça yeterli bir süre. Fazlasına ihtiyaç duyarsanız her zaman dakikalarınızı arttırabilirsiniz."
            }
        },
        {
            "title": {
                "tr": "Eğitmenleriniz gerçekten Amerikalı mı?",
                "en": "Eğitmenleriniz gerçekten Amerikalı mı?"
            },
            "text": {
                "tr": "Eğitmenlerimiz Memphis/Tennessee'de bulunan ofisimizde çalışmaktadır. Eğitmenlerimiz ofisimizde çalışmaya başlamadan önce ciddi bir elemeden geçerler. Vatandaşlık durumu, İngilizce eğitim kapasitesi gibi durumlar ciddi bir şekilde değerlendirilir. Ana dili İngilizce olmayan bir eğitmenin işe alımı mümkün değildir. Bu yüzden gönlünüz rahat olabilir.",
                "en": "Eğitmenlerimiz Memphis/Tennessee'de bulunan ofisimizde çalışmaktadır. Eğitmenlerimiz ofisimizde çalışmaya başlamadan önce ciddi bir elemeden geçerler. Vatandaşlık durumu, İngilizce eğitim kapasitesi gibi durumlar ciddi bir şekilde değerlendirilir. Ana dili İngilizce olmayan bir eğitmenin işe alımı mümkün değildir. Bu yüzden gönlünüz rahat olabilir."
            }
        },
        {
            "title": {
                "tr": "Eğitmenlerle istediğim konuları konuşabiliyor muyum?",
                "en": "Eğitmenlerle istediğim konuları konuşabiliyor muyum?"
            },
            "text": {
                "tr": "İngilizce durumunuza göre, konularınızı belirlerken mesleğinize göre eklemeler yapabiliyoruz. Tıp, mühendislik, iş dünyası, sağlık, güzellik, eğitim, inşaat, finans gibi konular belirleyip, programınıza ekliyoruz. Konuları istediğinizde değiştirebilirsiniz. Online İngilizce kursu olarak her şey size özel.",
                "en": "İngilizce durumunuza göre, konularınızı belirlerken mesleğinize göre eklemeler yapabiliyoruz. Tıp, mühendislik, iş dünyası, sağlık, güzellik, eğitim, inşaat, finans gibi konular belirleyip, programınıza ekliyoruz. Konuları istediğinizde değiştirebilirsiniz. Online İngilizce kursu olarak her şey size özel."
            }
        },
        {
            "title": {
                "tr": "Yoğunluktan derse katılamazsam ne oluyor?",
                "en": "Yoğunluktan derse katılamazsam ne oluyor?"
            },
            "text": {
                "tr": "Hesabınıza belirli sayıda ders erteleme hakkı tanınıyor. Ders başlamadan iki saat öncesine kadar bu haklarınızı kullanarak dersinizi erteleyebilirsiniz. Haklarınız bittiğinde ise makul bir ücret karşılığında erteleme hakkı satın alabilirsiniz. Fakat yine de tercihimiz saatlerinizi doğru ayarlayıp, derslerinize devam etmeniz.",
                "en": "Hesabınıza belirli sayıda ders erteleme hakkı tanınıyor. Ders başlamadan iki saat öncesine kadar bu haklarınızı kullanarak dersinizi erteleyebilirsiniz. Haklarınız bittiğinde ise makul bir ücret karşılığında erteleme hakkı satın alabilirsiniz. Fakat yine de tercihimiz saatlerinizi doğru ayarlayıp, derslerinize devam etmeniz."
            }
        },
        {
            "title": {
                "tr": "İlerleyişimi nasıl takip edebilirim?",
                "en": "İlerleyişimi nasıl takip edebilirim?"
            },
            "text": {
                "tr": "Derslere başladığınız zaman, belirlediğiniz kullanıcı adı ve şifre ile öğrenci panelinize giriş yapabileceksiniz. Bu panelde, eğitim içeriklerinizle birlikte ilerleme raporunuz da olacak. Burada eğitmeninizin notlarını, ilerlemenizi ve diğer içeriklerinizi görebileceksiniz.",
                "en": "Derslere başladığınız zaman, belirlediğiniz kullanıcı adı ve şifre ile öğrenci panelinize giriş yapabileceksiniz. Bu panelde, eğitim içeriklerinizle birlikte ilerleme raporunuz da olacak. Burada eğitmeninizin notlarını, ilerlemenizi ve diğer içeriklerinizi görebileceksiniz."
            }
        },
        {
            "title": {
                "tr": "%100 İade garantisi nedir?",
                "en": "%100 İade garantisi nedir?"
            },
            "text": {
                "tr": "%100 iade garantisi 6 ve 9+3 aylık paket alan öğrencilerimize sunmuş olduğumuz bir haktır. Derslerine en az %90 oranında devamlılık gösteren öğrencilerimiz eğitimlerinden memnun kalmadıklarında iade isteyebilir ve ödemelerinin tamamını geri iade olarak alabilirler.",
                "en": "%100 iade garantisi 6 ve 9+3 aylık paket alan öğrencilerimize sunmuş olduğumuz bir haktır. Derslerine en az %90 oranında devamlılık gösteren öğrencilerimiz eğitimlerinden memnun kalmadıklarında iade isteyebilir ve ödemelerinin tamamını geri iade olarak alabilirler."
            }
        }
    ],
    "studentComments": [
        {
            "name": "Gökhan Telkenar",
            "job": {
                "tr": "Spor Spikeri",
                "en": "Sports Announcer"
            },
            "youtubeId": "CDK88D4XP98"
        },
        {
            "name": "Esra Sönmezer",
            "job": {
                "tr": "Oyuncu",
                "en": "Actress"
            },
            "youtubeId": "Va4J4-STC2o"
        },
        {
            "name": "Yüksel Akkale",
            "job": {
                "tr": "Doktor",
                "en": "Doctor"
            },
            "youtubeId": "dWR6VfkqXTU"
        },
        {
            "name": "Sevcan Gökmen",
            "job": {
                "tr": "IK Uzmanı",
                "en": "HR Specialist"
            },
            "youtubeId": "JGU4MQ-r8s4"
        },
        {
            "name": "Sitar Atmaca",
            "job": {
                "tr": "Üst Düzey Yönetici",
                "en": "Senior Manager"
            },
            "youtubeId": "ONDCjtbeD6Y"
        }
    ],
    "howToLearnEnglish": {
        "youtubeId": "qDE5mXKyQTg"
    }
}