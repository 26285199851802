import {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import useAnalytics from "../hooks/useAnalytics";

const AccordionFilter = (props) => {

    const { title,
            content,
            isDisabled = false,
            open = false,
            clickEvent = ""
          } = props;

    const analytics = useAnalytics();

    const childrenRef = useRef();
    const firstVisit = useRef(true);

    const [isOpen, setIsOpen] = useState(open);

    const handleTransitionEnd = () => {
        if (!isOpen) {
            childrenRef.current.classList.add('hidden');
        }
    };

    useEffect(() => {
        if (isOpen) {
            childrenRef.current.classList.remove('hidden');

            //Açılma eventinde istenen log atılıyor.
            if(clickEvent.length > 0)
                analytics.useAnalytics(clickEvent, undefined, true, false);
        }
    }, [isOpen]);

    return (
        <div className="relative z-10 mt-5">
            <div
                onClick={() => {
                    setIsOpen(!isOpen);
                    firstVisit.current = false;
                }}
                className={classNames(
                    "px-5 py-4 cursor-pointer border border-onboarding-bg text-dark-text bg-card hover:bg-box focus:border-box transition-colors rounded-t-md flex justify-between items-center", {
                        "pointer-events-none opacity-70" : isDisabled,
                        "rounded-b-md" : !isOpen
                    })}
            >
                <div className="flex items-center">
                    <p>{title}</p>
                </div>
                {!isOpen ? (
                        <span
                            onClick={() => setIsOpen(true)}
                            className="material-symbols-outlined font-bold text-base cursor-pointer"
                        >
                            add
                        </span>
                    )
                    :
                    (
                        <span
                            onClick={() => setIsOpen(false)}
                            className="material-symbols-outlined font-bold text-base cursor-pointer"
                        >
                          remove
                        </span>
                    )
                }
            </div>

            {/* <!-- Detaylı Arama Content --> */}
            <div
                className={`${isOpen ? 'max-h-screen py-8 border border-t-0' : 'max-h-0'} px-4 border-onboarding-bg rounded-b-md transition-max-height duration-500`}
                onTransitionEnd={handleTransitionEnd}
            >
                <div ref={childrenRef} className={`${!isOpen && 'animate-fadeOut'} ${firstVisit.current ? 'hidden' : ''}`}>
                    {content}
                </div>
            </div>
        </div>
    );
}

export default AccordionFilter;