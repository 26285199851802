import {Accordion} from "../../../../components";
import {LessonBook, LessonBookAudio, LessonVideo, StudySubjects, Words} from "./index";
import React from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import useAnalytics from "../../../../hooks/useAnalytics";

const LessonContent = ({convertedData, book, selectedDate}) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_materials}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={true}
        >
            <div className="pb-5">
                {convertedData && convertedData[3][0] && (
                    <LessonBook data={book} convertedData={convertedData} events={['Completed_Lesson_Book_Preview', 'Completed_Lesson_Book_Section_Click']} />
                )}

                {convertedData && convertedData[1][0] && (
                    <StudySubjects convertedData={convertedData} events={'Comp_Les_Topic_Question_Section_Click'} />
                )}

                {convertedData && convertedData[7][0] && (
                    <LessonBookAudio
                        src={convertedData[7]}
                        title={strings.speaking_lesson.components.audio.book_voiceover_title}
                        analyticsPass={true}
                        onplay={() => {
                            //DataLayer
                            window.dataLayer.push({
                                'event' : 'bookPlayer',
                                'action' : 'play'
                            });
                            //Event
                            analytics.useAnalytics('Book_Listen');
                        }}
                        onpause={() => {
                            //DataLayer
                            window.dataLayer.push({
                                'event' : 'bookPlayer',
                                'action' : 'pause'
                            });
                            //Event
                            analytics.useAnalytics('Book_Pause');
                        }}
                    />
                )}

                {convertedData && convertedData[6][0] && (
                    <LessonVideo src={convertedData[6][0]} title={strings.speaking_lesson.components.video.title}  analyticsPass={true} />
                )}

                {book.words[0] && (
                    <Words
                        title={strings.speaking_lesson.components.words.title}
                        data={book.words}
                        events={['Comp_Les_Word_Detail_Click', 'Comp_Les_Word_Listen']}
                    />
                )}
            </div>
        </Accordion>
    )
}

export default LessonContent;