import React from 'react'

const StudentComment = (props) => {
    return (
        <div className="sm:w-[48%] max-w-[246px] relative">
            <img className="rounded-[10px]" src={props.imgUrl} alt="" />
            <p className="mt-2 font-bold text-sm leading-4">{props.studentName} - {props.studentJob}</p>
            <button type="button" className="button secondary-button-outline inside-button"
                onClick={props.videoClick}>
                <div className="play-button h-3 w-3"></div>
                {props.playVideo}
            </button>
        </div>
    )
}

export default StudentComment