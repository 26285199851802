import { createSlice } from "@reduxjs/toolkit";

export const SignalrHubSlice = createSlice({
    name: 'SignalrHub',
    initialState: {
        callBarVisibility: {
            ensureConnect: true,
            isCallBarVisible: true,
            isTopHeightVisible: true
        }
    },
    reducers: {
        setEnsureConnect: (state, action) => {
            state.callBarVisibility.ensureConnect = action.payload;
        },
        setIsCallBarVisible: (state, action) => {
            state.callBarVisibility.isCallBarVisible = action.payload;
        },
        setIsTopHeightVisible: (state, action) => {
            state.callBarVisibility.isTopHeightVisible = action.payload;
        }
    }
});

export const { setEnsureConnect, setIsCallBarVisible, setIsTopHeightVisible } = SignalrHubSlice.actions;

export default SignalrHubSlice.reducer;