import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import { url } from '../../routes/utility';
import { AutoLoginService, GetCandidateSurveyService } from '../../services/Auth/AuthService';
import { memberLogin } from '../../store/MemberSlice';
import { decodeToken, encodeToken } from '../../utils';

const AutoLogin = () => {

    const { jwt } = useParams();
    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rawToken = jwt ? jwt : false;
    const [surveyStatus, setSurveyStatus] = useState(false);

    useEffect(() => {
        let abortController = new AbortController();

        const referrer = document.referrer
        if (!rawToken || !referrer.includes("konusarakogren.com")) navigate(url("auth.login"));

        async function CheckJWTToken() {
            return await AutoLoginService(rawToken);
        }

        CheckJWTToken().then(result => {
            //if the member is not real member
            if (result.statusCode !== 200) {
                if (result.message) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${result.message}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
                navigate(url("auth.login"));
            } else {

                const member = decodeToken(rawToken);
                const memberId = member.MemberId.toString();

                let terms = '';

                if (member.hasOwnProperty("Terms")) {
                    terms = encodeToken(member.Terms);
                    delete member["Terms"];
                }

                const token = encodeToken(member);

                //set member login
                dispatch(memberLogin({ token, terms }));

                //get survey status
                GetCandidateSurveyService(memberId)
                    .then(result => {
                        if(result.status === 200){
                            let resultContent = JSON.parse(result.content);
                            setSurveyStatus(resultContent);
                        }
                    });
            }
        });

        return () => {
            abortController.abort();
        };

    }, []);


    // Kullanıcı onboarding use case seçimi yapmadıysa onu seçim ekranına gönderiyoruz
    useEffect(() => {
        if(Object.keys(surveyStatus).length > 0) {
            let redirectUrl = 'dashboard';

            if (surveyStatus && surveyStatus?.first.length === 0) {
                redirectUrl = 'onboarding.first'
            }

            navigate(url(redirectUrl));
        }
    }, [surveyStatus])
}

export default AutoLogin