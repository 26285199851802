import {Menu, Transition} from '@headlessui/react'
import {Fragment} from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";

const List = (props) => {

    const language = useSelector(state => state.localizationStore.language);
    const { children, selected, setSelected, data  } = props;

    return (
        <Menu
            as="div"
            className="relative inline-block text-left"
            value={selected}
            onChange={setSelected}
        >
            {({ open }) => (
                <>
                    <Menu.Button className={classNames("", { "text-primary" : open })}>
                        {children}
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-[#333] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                {data.map((item, index) => (
                                    <Menu.Item key={index}>
                                        {({active }) => (
                                            <button
                                                type="button"
                                                className={classNames("block px-4 py-1 text-sm hover:text-primary", {
                                                    "text-primary" : item.id === selected.id,
                                                    "text-white" : item.id !== selected.id
                                                })}
                                                onClick={() => setSelected(item)}
                                            >
                                                {item[language]}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export default List;