import React from 'react';
import { useLocalization } from '../../../../hooks/useLocalization';
import { addTimeZone, getTimeZone } from '../../../../utils';
import useAuth from '../../../../hooks/useAuth';

const NoAnswerInformation = ({ data }) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const timeZone = getTimeZone(member.Timezone);

    return (
        <div className="max-w-lg mt-4 pb-2 mx-auto">
            <div className="flex flex-col sm:flex-row sm:justify-between mx-auto text-start">
                <div className="text-1xs">
                    <div className="font-bold w-[225px] relative pb-2 text-sm">{strings.speaking_lesson.no_answer.comment_title}
                        <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                    </div>
                    <p className="mt-1">{strings.speaking_lesson.no_answer.comment_content}</p>
                </div>
                <div className="text-1xs">
                    <div className="font-bold w-[225px] relative pb-2 text-sm mt-4 sm:mt-0">{strings.speaking_lesson.no_answer.call_log_title}
                        <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                    </div>
                    <div className='max-h-[300px] overflow-auto mt-1'>
                        {data.map((item, index) => (
                            <p key={index} className="mt-1">{addTimeZone(item, timeZone)}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoAnswerInformation