// Mikrofon izin durumunu kontrol eden fonksiyon
export const checkMicrophoneAllowed = async () => {
    let microphonePermissionStatus = navigator.permissions.query({ name: 'microphone' })
        .then(permissionStatus => {
            return permissionStatus.state
        })
    return await microphonePermissionStatus
}

// Bildirim izin durumunu kontrol eden fonksiyon
export const checkNotificationAllowed = async () => {
    if (!("Notification" in window))
        return false; // Tarayıcı bildirimleri desteklemiyor

    const permission = await Notification.requestPermission();
    return permission;
};
