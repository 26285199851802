import React from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { Menu, SideBar } from '../../components'
import DownloadMobileAppBar from '../../components/DownloadMobileAppBar';
import { useLocalization } from '../../hooks/useLocalization'

const DemoLayout = () => {

    const strings = useLocalization();
    const location = useLocation();
    return (
        <>
            <Menu title={strings.demo.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
            {location.pathname.includes("demo/success") && <DownloadMobileAppBar />}
        </>
    )
}

export default DemoLayout