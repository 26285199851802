import { createSlice } from "@reduxjs/toolkit";

export const ExerciseReportSlice = createSlice({
    name: 'ExerciseReport',
    initialState: { mistakeIds: [], correctIds: [] },
    reducers: {
        setMistakeId: (state, action) => {
            const newMistakeId = action.payload;
            state.mistakeIds.push(newMistakeId);
        },
        setCorrectId: (state, action) => {
            const newCorrectId = action.payload;
            state.correctIds.push(newCorrectId);
        },
        removeId: (state, action) => {
            const idToRemove = action.payload;
            state.mistakeIds = state.mistakeIds.filter(id => id !== idToRemove);
            state.correctIds = state.correctIds.filter(id => id !== idToRemove);
        },
        clearReport: (state, action) => {
            state.mistakeIds = [];
            state.correctIds = [];
        }
    }
});

export const {
    setCorrectId,
    setMistakeId,
    removeId,
    clearReport
} = ExerciseReportSlice.actions;

export default ExerciseReportSlice.reducer;
