import React, { useEffect, useState } from 'react'
import useAuth from '../../../hooks/useAuth';
import { TermDropDown } from '../../../components';
import { PostponeHistory } from '../components';
import { GetPostponeHistoryService } from '../../../services/Support/SupportService';
import useAnalytics from '../../../hooks/useAnalytics';
import Postpone from './Postpone';
import PostponeNoRight from './PostponeNoRight';
import { useLocalization } from '../../../hooks/useLocalization';
import { Frozen } from '../components';
import { useLocation } from 'react-router-dom';
import { addTimeZone, getTimeZone } from '../../../utils';

const PostponeHome = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const { member, token } = useAuth();
    const location = useLocation();
    const timeZone = getTimeZone(member.Timezone);
    const terms = typeof(member.Terms) !== "undefined" && JSON.parse(member.Terms).filter(term => term.Status === 1);

    const [startDate, setStartDate] = useState(location.state?.date);
    const [termId, setTermId] = useState(false);
    const [lesson, setLesson] = useState('');
    const [loading, setLoading] = useState(true);
    const [historyData, setHistoryData] = useState({});

    const postponeOK = historyData['postponeRightCount'] > 0;

    //from child dropdown
    const changeTerm = term => {
        const type = `${term.ProductName} / ${addTimeZone(term.LessonTime, timeZone)} / ${term.LessonDuration} ${strings.general_information_messages.minute}`;

        setTermId(term.TermId);
        setLesson(lesson => type);
        setLoading(loading => true);
        if (termId) {
            setStartDate(startDate => false);
        }

    };

    const getHistoryData = async termId => {
        try {
            const result = await GetPostponeHistoryService(termId, token);
            if (result.statusCode === 200) {
                setHistoryData(JSON.parse(result.message));
                setLoading(loading => false);
            } else {

                if (result.message) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${result.message}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
            }

        } catch (error) {
            analytics.useAnalytics('Error_Occured', {
                error: `An unexpected error occurred.`,
            });
        }
    };

    //If the course is frozen, it can be postpone from the transactionBeginDate date from the API.
    const isDateAvailable = () => {
        let todayUtc = new Date();
        let today = new Date(
            todayUtc.getTime() - todayUtc.getTimezoneOffset() * 60000,
        );

        let [date, month, year] =
            historyData.activeFreezeHistory == null
                ? [0, 0, 0]
                : historyData.activeFreezeHistory.transactionBeginDate.split('.');

        let transactionBeginDate = new Date(
            year,
            month - 1,
            date,
            -new Date().getTimezoneOffset() / 60,
        );

        return today >= transactionBeginDate;
    };

    useEffect(() => {
        if (termId) {
            getHistoryData(termId);
        }
    }, [termId])

    return (
        <>
            <div className="main-content">
                <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-start">
                    <TermDropDown terms={terms} changeTerm={changeTerm} />
                </div>

                {historyData.activeFreezeHistory === null || isDateAvailable() ? (
                    !loading && (
                        postponeOK ? (
                            <Postpone
                                data={historyData}
                                lesson={lesson}
                                termId={termId}
                                getHistoryData={getHistoryData}
                                startDate={typeof startDate !== "undefined" ? startDate : false}
                            />
                        ) : (
                            <PostponeNoRight />
                        )
                    )) : (<Frozen data={historyData.activeFreezeHistory} fromPostpone={true} />)}

                {(!loading && historyData?.postponedLessonDates.length > 0) && (
                    <PostponeHistory data={historyData.postponedLessonDates} />
                )}

                <div className="left-margin-bottom"></div>
            </div>

        </>
    )
}

export default PostponeHome