import { Error } from "../components";
import { Success } from "../../../assets/svg";
import { useLocalization } from "../../../hooks/useLocalization";
import { url } from "../../../routes/utility";

const FreezeSuccess = ({ endDate }) => {
    const strings = useLocalization();

    return (
        <Error
            icon={Success}
            content={strings.support.freeze_lesson_success.success_message
                .replace('#date#', endDate)}
            to={url("support")}
            buttonText={strings.modal.okey}
        />
    )
}

export default FreezeSuccess