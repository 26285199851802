import React, {createContext, useContext, useEffect, useState} from "react";
import {SideBar} from "../components";
import {useLocation} from "react-router-dom";

const LayoutContext = createContext();

export const useLayout = () => {
    const context = useContext(LayoutContext);
    if (!context) {
        return {
            currentPage: <SideBar />,
            setCurrentPage: () => {},
        };
    }
    return context;
};

export const LayoutProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(<SideBar />);
    const location = useLocation();

    const value = {
        currentPage,
        setCurrentPage,
    };

    useEffect(() => {
        return () => {
            setCurrentPage(<SideBar />);
        }
    }, [location]);

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
};
