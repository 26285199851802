import {useEffect, useState} from "react";
import {GetMemberLevelCollectionByMemberId} from "../services/MemberDemoRight/MemberDemoRightServices";
import {LevelCollection} from "../components/Constants";

/*
    * Kullanıcının seviyesinin getirildiği hook.
    * Eğer kendi seçtiği seviyesi varsa onu getirir. Yoksa;
    * AI ile seçtiği seviyeyi getirir. Yoksa;
    * -1 döndürür.
*/
const useMemberLevelCollection = (memberId) => {

    const [memberLevelCollectionId, setMemberLevelCollectionId] = useState(-1);

    const getLevelCollectionId = (levelCollection) => {
        switch (levelCollection){
            case "Starter":
                return LevelCollection.STARTER;
            case "Beginner":
                return LevelCollection.BEGINNER;
            case "Intermediate":
                return LevelCollection.INTERMEDIATE;
            case "Advanced":
                return LevelCollection.ADVANCED;
            default:
                return -1;
        }
    }

    useEffect(() => {
        GetMemberLevelCollectionByMemberId(memberId)
            .then(result => {
                if(result.status === 200) {
                    setMemberLevelCollectionId(getLevelCollectionId(result.content));
                } else {
                    setMemberLevelCollectionId(-1);
                }
            })
            .catch()
    }, []);

    return memberLevelCollectionId;
}

export default useMemberLevelCollection;