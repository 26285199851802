import { fetchApi } from '../index';

export const GetStudentDemandsAndReasons = async (memberId) => {

    const url = '/_services/member/v1/member/get_student_demands_and_reason/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const CreateOrUpdateMemberDemandsAndReason = async (model) => {

    const url = '/_services/member/v3/member/create_or_update_member_demands_and_reasons/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberProfileStatus = async (memberId) => {

    const url = '/_services/member/v1/member_profile/get_member_profile_status/';

    const payload = {
        memberId: memberId
    }

    return await fetchApi(url, payload);
}

export const UpdateMemberName = async (memberId, fullName) => {

    const url = '/_services/member/v1/member/update_member_name/';

    const payload = {
        memberId: memberId,
        fullName: fullName
    };

    return await fetchApi(url, payload);
}