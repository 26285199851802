import { isValidPhoneNumber } from 'libphonenumber-js';

export const validPhoneNumber = phone => {
    return isValidPhoneNumber(phone);
}

export const checkPassword = pass => {
    return (pass.length >= 4);
}

export const checkVerificationCode = code => {
    return (code.length === 4)
}

export const openModal = (overlay, modal) => {
    overlay.current.classList.toggle('hidden');
    modal.current.classList.toggle('hidden');
}

export const closeModal = (overlay, modal) => {
    overlay.current.classList.add('hidden');
    modal.current.classList.add('hidden');
}

//show form errors with ref
export const showError = (val, ref) => {
    if (val) {
        ref.current.classList.remove('display');
        ref.current.classList.add('hidden');
    } else {
        ref.current.classList.remove('hidden');
        ref.current.classList.add('display');
    }
}

//clear form errors with ref
export const clearError = (ref) => {
    ref.current.classList.remove('display');
    ref.current.classList.add('hidden');
}