import {fetchApi} from '../index';

export const GetPopularUnitSentences = async (model) => {

    const url = '/_services/book/v1/book_unit_sentence/get_popular_book_unit_sentences/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};

export const UpsertMemberSentenceStore = async (model) => {

    const url = '/_services/member/v1/member_sentence_store/upsert_member_sentence_store/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};

export const GetBookUnitSentenceFilter = async (model) => {

    const url = '/_services/book/v1/book_unit_sentence/get_book_unit_sentence_filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};

export const GetBookUnitSentenceByMemberFilter = async (model) => {

    const url = '/_services/book/v1/book_unit_sentence/get_book_unit_sentence_by_member_filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const IncreaseSentenceClickCount = async (bookUnitSentenceIds) => {

    const url = '/_services/book/v1/book_unit_popular_sentence/increase_sentence_click_count/';

    const payload = {
        bookUnitSentenceIds: bookUnitSentenceIds
    }

    return await fetchApi(url, payload);
}