import React, {useEffect, useState} from 'react'
import {useLocalization} from '../../../../hooks/useLocalization';
import useAnalytics from '../../../../hooks/useAnalytics';
import {LessonBook, LessonBookAudio, LessonInformation, LessonVideo, StudySubjects, Words} from '../Common';
import PopularSentences from "../Common/PopularSentences";

const Pending = ({ data, selectedDate }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const [convertedData, setConvertedData] = useState();

    //we have 7 content type.
    useEffect(() => {
        let newData = {
            1: [], //StudySubjects-question
            2: [], //StudySubjects-sentence
            3: [], //DailyTextbook-book
            4: [], //DailyTextbook-presentation
            5: [], //DailyTextbook-practice
            6: [], //video
            7: [], //audio
        };

        data.book.contents.map(value => {
            for (let i = 1; i < 8; i++) {
                value.contentType == i && newData[i].push(value.content);
            }
        });

        setConvertedData(newData);
    }, [data]);


    useEffect(() => {
        analytics.useAnalytics('screen_view', { screen_name: 'Gunun_Dersi_Islenmemis' });
        window.customDataLayer.push({ event: 'screen_view', screen_name: 'Gunun_Dersi_Islenmemis' });
    }, []);

    return (
        <>

            <div className="gray-section-bottom">
                <LessonInformation data={data} />
            </div>

            {convertedData && convertedData[3][0] && (
                <LessonBook data={data.book} convertedData={convertedData} events={['Book_Preview', 'Book_Section_Click']} />
            )}

            {convertedData && convertedData[1][0] && (
                <StudySubjects convertedData={convertedData} events={'Topic_Quesion_Section_Click'} />
            )}

            {convertedData && convertedData[7][0] && (
                <LessonBookAudio
                    src={convertedData[7]}
                    title={strings.speaking_lesson.components.audio.book_voiceover_title}
                    onplay={() => {
                        //DataLayer
                        window.dataLayer.push({
                            'event' : 'bookPlayer',
                            'action' : 'play'
                        });
                        //Event
                        analytics.useAnalytics('Book_Listen');
                    }}
                    onpause={() => {
                        //DataLayer
                        window.dataLayer.push({
                            'event' : 'bookPlayer',
                            'action' : 'pause'
                        });
                        //Event
                        analytics.useAnalytics('Book_Pause');
                    }}
                />
            )}

            {convertedData && convertedData[6][0] && (
                <LessonVideo src={convertedData[6][0]} title={strings.speaking_lesson.components.video.title} events={'Video_Watch'} />
            )}

            {data?.book?.bookUnitId !== 0 &&
                <PopularSentences
                    title={strings.speaking_lesson.daily_lesson.sentence_bank.title}
                    bookUnitId={data?.book?.bookUnitId}
                    selectedDate={selectedDate}
                />
            }

            {data.book.words[0] && (
                <Words
                    title={strings.speaking_lesson.components.words.title}
                    data={data.book.words}
                    events={['Word_Detail_Click', 'Word_Listen']}
                />
            )}

        </>
    )
}

export default Pending