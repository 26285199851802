import React, { useEffect, useRef, useState } from 'react'
import { EnFlag, LocalFlag } from '../../../../assets/img';
import { Speaker } from '../../../../assets/svg';
import { Modal, ModalOverlay } from '../../../../components';
import { useLocalization } from '../../../../hooks/useLocalization';
import { SpeakWordWithTts } from '../../../../services/SpeakingLesson/SpeakingLessonService';
import QuizQuestions from '../Quiz/QuizQuestions';
import { closeModal, openModal } from '../../../../utils';
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const DailySentenceDetail = (props) => {

    const { data } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();

    const modalRef = useRef();
    const audioRef = useRef();
    const modalOverlayRef = useRef();

    const [modalMessage, setModalMessage] = useState("");
    const [isStartQuiz, setIsStartQuiz] = useState(false);
    const [quizQuestions, setQuizQuestions] = useState({});

    //speak corrections
    const speakSentence = async (word) => {
        const result = await SpeakWordWithTts(word);

        if (result.statusCode === 200) {
            audioRef.current.src = result.message.audio;

            // Ses çalmayı başlat
            audioRef.current.play();

            //Ses dinlendikçe log atılıyor.
            analytics.useAnalytics(EventLogs.DAILY_PATTERN_LISTEN);
        } else {
            setModalMessage(modalMessage => strings.menu.callbar.an_error_occured);
            openModal(modalOverlayRef, modalRef);
        }
    };

    useEffect(() => {
        setQuizQuestions(quizQuestions => data.quiz.questions.map((item) => ({
            ...item,
            questionAnswers: item.questionAnswers.map((answer, index) => ({
                ...answer,
                id: index + 1
            }))
        })))
    }, [data])

    return (
        <>
            <div className="mt-5 transparent-section">
                <p className="font-bold">{data.title}</p>
                <p className="mt-5">{data.description}</p>
                <div className="font-bold max-w-[95%] lg:max-w-[50%] mb-2 relative pb-2 mt-[27px] text-secondary text-start">
                    {strings.speaking_lesson.daily_sentence.sample_sentences}
                    <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                </div>

                {data.sentences.map((s, index) => {
                    return (
                        <div key={index} className="mb-2">
                            <div className="flex gap-2">
                                <img src={EnFlag} className="w-4 h-4 mt-1" alt="multiply" />
                                <p className="font-bold text-start">
                                    {s.sentence}
                                    <img
                                        className="inline-flex ml-1.5 cursor-pointer"
                                        src={Speaker}
                                        onClick={() => speakSentence(s.sentence)}
                                        width="16"
                                        height="16"
                                        alt=""
                                    />
                                    <audio ref={audioRef} id="audioPlayer" controls={false} autoPlay={false} className="hidden">
                                        <source type="audio/mp3" />
                                    </audio>
                                </p>
                            </div>
                            <div className="flex gap-2">
                                <img src={LocalFlag} className="w-4 h-4 mt-1" alt="accept" />
                                <p className='text-start'>{s.sentenceDescription}</p>
                            </div>
                        </div>
                    )
                })}
            </div>

            {!isStartQuiz && (
                <div className="flex flex-col items-center btn-outer-div">
                    <button
                        className="button button-mt primary-button w-full"
                        onClick={() => {
                            setIsStartQuiz(isStartQuiz => true);
                            //Teste başlandığında log atılıyor.
                            analytics.useAnalytics(EventLogs.DAILY_PATTERN_START_TEST);
                        }}
                    >
                        {strings.speaking_lesson.daily_sentence.start_quiz}
                    </button>
                </div>
            )}

            {
                isStartQuiz && (
                    <QuizQuestions
                        quizId={data.quiz.quizId}
                        bookName={""}
                        quizInformation={quizQuestions}
                        source="dailysentence"
                    />
                )
            }
            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <Modal
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage}
                overlayRef={modalOverlayRef}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>

    )
}

export default DailySentenceDetail