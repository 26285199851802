import classNames from 'classnames';
import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AddMember, Default_Teacher} from '../../../../assets/svg';
import {Button, ModalOneButton, ModalOverlay, ModalTwoButtons} from '../../../../components';
import {MemberFamilyRole} from '../../../../components/Constants';
import {useLocalization} from '../../../../hooks/useLocalization';
import {url} from '../../../../routes/utility';
import {RemoveMemberFamily} from '../../../../services/LearnTogether/LearnTogetherService';
import {closeModal, openModal} from '../../../../utils';

const FamilyMember = (props) => {

    const { familyMembers, familyRole, familyCode, sendRequestToEndpoint } = props;
    const strings = useLocalization();
    const navigate = useNavigate();

    const modalRef = useRef();
    const resultModalRef = useRef();
    const modalOverlayRef = useRef();
    const FAMILY_MEMBER_COUNT = 5;

    const notSpecified = strings.speaking_lesson.speaking_lesson_user.it_is_not_specified;

    const [isHiddenRemoveButton, setIsHiddenRemoveButton] = useState(true);
    // Manager rolündeki kişi "Düzenle" butonuna bu state true'ye çekiliyor.
    const [isEditMembers, setIsEditMembers] = useState(false);
    const [memberToBeRemoved, setMemberToBeRemoved] = useState(null);
    const [removeMemberText, setRemoveMemberText] = useState("");

    //Düzenle butonuna basıldığında üye çıkarma butonunun css'ini kontrol etmek için kullanılan fonksiyon
    const handleClick = () => {
        !isEditMembers && setIsHiddenRemoveButton(isHiddenRemoveButton => !isHiddenRemoveButton);
        setTimeout(() => {
            isEditMembers && setIsHiddenRemoveButton(isHiddenRemoveButton => !isHiddenRemoveButton);
        }, 300);
    };

    const removeMemberFamily = async (memberId, familyCode) => {

        const model = {
            memberId: memberId,
            familyCode: familyCode
        }

        const result = await RemoveMemberFamily(model);
        closeModal(modalOverlayRef, modalRef);
        if (result.status === 200) {
            setRemoveMemberText(removeMemberText => strings.learn_together.remove_success_message.replace("#NAME#", memberToBeRemoved?.memberFullName));
        }
        else {
            setRemoveMemberText(removeMemberText => strings.menu.callbar.an_error_occured)
        }
        openModal(modalOverlayRef, resultModalRef);
    }

    return (
        <>
            <div className="mt-5 transparent-section">
                <h5 className="text-secondary">{strings.learn_together.family_list}</h5>
                <div className='mt-3 flex justify-between'>
                    <p className='font-bold'>{strings.learn_together.members}</p>
                    {familyRole === MemberFamilyRole.MANAGER &&
                        <Button
                            classnames="button primary-button-outline max-w-max px-2 h-[25px] text-sm mt-0"
                            action={() => {
                                handleClick();
                                setIsEditMembers(isEditMembers => !isEditMembers);
                            }}
                            text={!isEditMembers ? strings.learn_together.edit : strings.modal.dont_approve}
                        />
                    }
                </div>
                <hr className='my-2' />
                {familyMembers.map((familyMember, index) => (
                    <div key={index}>
                        <div className='flex gap-2'>
                            <div className={classNames("w-8 flex justify-center items-center transition-all duration-300 opacity-0 -translate-x-[32px]", {
                                "translate-x-0 !opacity-100": isEditMembers && !familyMember.isManager
                            })}>
                                {!isHiddenRemoveButton &&
                                    <button
                                        className='bg-red w-4 h-4 rounded-full text-white flex items-center justify-center'
                                        onClick={() => {
                                            setMemberToBeRemoved(memberToBeRemoved => familyMember)
                                            openModal(modalOverlayRef, modalRef)
                                        }}
                                    >-</button>
                                }
                            </div>
                            <button className={classNames('flex justify-between items-center transition-all duration-300 w-full -translate-x-[32px] cursor-default', {
                                "translate-x-0": isEditMembers && !familyMember.isManager
                            })}>
                                <div className='flex gap-2 items-center'>
                                    <img className="rounded-full w-[32px]" src={Default_Teacher} alt="" />
                                    <div>
                                        <p className='text-[14px] font-bold cursor-text'>{familyMember.memberFullName || notSpecified}</p>
                                        <p className={classNames("text-[12px] text-secondary text-start -mt-1 cursor-text", {
                                            "font-bold": familyMember.isManager
                                        })}>{familyMember.isManager ? strings.learn_together.manager : strings.learn_together.member}</p>
                                    </div>
                                </div>
                                {familyRole === MemberFamilyRole.MANAGER && !familyMember.isManager &&
                                    <span className={classNames("material-symbols-outlined transition-all duration-300 text-base-text text-opacity-0 text-[20px] translate-x-[32px]", {
                                        "opacity-0": isEditMembers && !familyMember.isManager
                                    })}>
                                        arrow_forward_ios
                                    </span>
                                }
                            </button>
                        </div>
                        {(index !== familyMembers.length - 1 || familyRole === MemberFamilyRole.MANAGER || FAMILY_MEMBER_COUNT > familyMembers.length) && <hr className='my-2' />}
                    </div>
                ))}
                {familyRole === MemberFamilyRole.MANAGER && FAMILY_MEMBER_COUNT > familyMembers.length &&
                    <button
                        className='flex gap-2 items-center ml-2'
                        onClick={() => navigate(url("learntogether.addfamilymember"))}
                    >
                        <img className="rounded-full w-[32px]" src={AddMember} alt="" />
                        <div>
                            <p className='text-[14px] font-bold'>{strings.learn_together.add_new_member}</p>
                            <p className='text-[12px] text-[#999999] text-start -mt-1'>{strings.learn_together.remaining_count.replace("#COUNT#", FAMILY_MEMBER_COUNT - familyMembers.length)}</p>
                        </div>
                    </button>
                }
            </div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.information_message}
                message={strings.learn_together.remove_approve_message.replace("#NAME#", memberToBeRemoved?.memberFullName)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    removeMemberFamily(memberToBeRemoved.memberId, familyCode)
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
            <ModalOneButton
                ref={resultModalRef}
                showCancelButton={false}
                title={strings.auth.information_messages.modal_title}
                message={removeMemberText}
                overlayRef={modalOverlayRef}
                buttonText={strings.modal.okey}
                buttonClick={() => {
                    closeModal(modalOverlayRef, resultModalRef);
                    setIsEditMembers(isEditMembers => !isEditMembers);
                    sendRequestToEndpoint();
                }}
            />
        </>
    )
}

export default FamilyMember