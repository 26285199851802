import React from "react";
import { motion } from "framer-motion";

const AILoader = ({ color }) => {

    const loadingContainer = {
        width: "30px",
        height: "15px",
        display: "flex",
        justifyContent: "space-around",
    };

    const loadingCircle = {
        display: "block",
        width: "7px",
        height: "7px",
        backgroundColor: color,
        borderRadius: "0.5rem",
    };

    const loadingContainerVariants = {
        start: {
            transition: {
                staggerChildren: 0.2,
            },
        },
        end: {
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const loadingCircleVariants = {
        start: {
            y: "0%",
        },
        end: {
            y: "60%",
        },
    };

    const loadingCircleTransition = {
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
    };

    return (
        <motion.div
            style={loadingContainer}
            variants={loadingContainerVariants}
            initial="start"
            animate="end"
        >
            <motion.span
                style={loadingCircle}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
            ></motion.span>
            <motion.span
                style={loadingCircle}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
            ></motion.span>
            <motion.span
                style={loadingCircle}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
            ></motion.span>
        </motion.div>
    );
};

export default AILoader;
