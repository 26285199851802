import React from 'react'
import { Error_404 } from '../../assets/svg';
import { Menu, SideBar } from '../../components';
import { useLocalization } from '../../hooks/useLocalization';

const Error404Layout = () => {
    const strings = useLocalization();
    return (
        <>
            <Menu title={strings.error404.title} />

            <section className="container">

                <div className="main-content">

                    <div className="mx-auto text-center mt-7 text-sm px-4">
                        <img
                            className="inline-block"
                            src={Error_404}
                            alt=""
                            width="256"
                            height="256"
                        />
                        <p className="font-bold mt-7 text-base">{strings.general_information_messages.error404_title}</p>
                        <p className="mt-5">{strings.general_information_messages.error404_content}</p>
                    </div>

                    <div className="left-margin-bottom"></div>
                </div>

                <SideBar />

            </section>
        </>
    )
}

export default Error404Layout