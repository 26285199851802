import {useLocalization} from "../../hooks/useLocalization";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {GetVideoDetails} from "../../services/VideoPractice/VideoPracticeService";
import {sendEventByDefaultParameters, timeStampToSecond} from "../../utils";
import {Button, Loading,} from "../../components";
import ReactPlayer from "react-player";
import classNames from "classnames";
import List from "../../components/List";
import '../../assets/css/video.css'
import {EventLogs, LanguageTitleType, VideoSpeeds} from "../../components/Constants";
import {url} from "../../routes/utility";
import {WordModal} from "../Exercises/components";
import useAnalytics from "../../hooks/useAnalytics";
import {GetVideoDetailsById} from "../../services/DemoPrototype/DemoPrototypeService";

const Video = () => {

    const strings = useLocalization();
    const {videoId} = useParams();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(window.location.search);

    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;
    const isSinglePage = location.pathname.includes("/videos");
    const outsideVideoId = params.get('videoId') || false;

    const playerRef = useRef();
    const sectionRefs = useRef();

    // Page loading
    const [loading, setLoading] = useState(true);
    // Video verilerinin tutulduğu state
    const [data, setData] = useState({});
    // Videonun içerisinde bulunduğu saniyeyi tutan state
    const [currentTime, setCurrentTime] = useState(0);
    // Altyazı çevirisinin görünme durumunu kontrol eden state
    const [showSubtitleTranslation, setShowSubtitleTranslation] = useState(false);
    // Video hızını kontrol eden state - default normal hız seçili getiriliyor
    const [selectedSpeed, setSelectedSpeed] = useState(VideoSpeeds.find(v => v.speed === 1));
    // Sayfaya ilk girildiğinde hız değişim logu atılmaması için, ilk hız değişimi tutuluyor
    const [isFirstSpeedChange, setIsFirstSpeedChange] = useState(false);
    // Videonun başlama/durdurma aksiyonlarını kontrol eden state
    const [isVideoPlaying, setIsVideoPlaying] = useState(true);
    // Altyazıda geçen kelimeye tıklanıldığında kelime id'sinin tutulduğu state
    const [selectedWordId, setSelectedWordId] = useState("");
    // Kelime modalının açılıp/kapanmasını kontrol eden state
    const [openWordModal, setOpenWordModal] = useState(false);
    // İçerisinde bulunduğumuz section'ı tutan state
    const [currentSection, setCurrentSection] = useState(0);

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            parameters,
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    const splitWordsWithSpaces = (wordArray) => {
        let outputArray = [];

        wordArray.forEach(item => {
            let words = item.word.split(' ');
            words.forEach(word => {
                if (word.trim() !== '') {
                    outputArray.push({
                        "id": item.id,
                        "word": word
                    });
                }
            });
        });

        return outputArray;
    }

    // Video saniyesi değiştikçe bu fonksiyon ile set ediliyor
    const handleProgress = ({playedSeconds, playbackRate}) => {
        setCurrentTime(playedSeconds);
    };

    /*
        * Video bittiğindeki işlemleri gerçekleştiren fonksiyon
        * Video bittiğinde word sayfasına otomatik olarak yönlendiriliyo
    */
    const handleVideoEnd = () => {
        navigate(url("videopractice.videoflow.word", {videoId: videoId}));

        //LOGGED
        sendEvent(
            EventLogs.VIDEO_EXERCISE_VIDEO_COMPLETED,
            {
                video_name: data?.videoTitle,
            }
        );
    }

    // Tıklanan kelime işlemlerini ve kelime pencersinin açılma durumunu kontrol eden fonksiyon
    const handleOpenWordModal = (wordDetail) => {
        // Sayfadaki scroll iptal ediliyor
        document.body.style.overflow = 'hidden';
        setSelectedWordId(wordDetail.id);
        setIsVideoPlaying(false);
        setOpenWordModal(true);

        //LOGGED
        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_CLICKED,
            {
                video_name: data?.videoTitle,
                video_speed: selectedSpeed?.speed + "X",
                word: wordDetail?.word
            }
        );
    };

    // Kelime pencersinin kapanma durumunu kontrol eden fonksiyon
    const handleCloseWordModal = () => {
        setOpenWordModal(false);
        setIsVideoPlaying(true);
    }

    /*
        * Altyazı içerisindeki ilgili kelimeleri tıklanılabilir yapmak için kullanılır
        * Tıklanılabilir kelimeyi içeren span elementi oluşturur
    */
    const makeClickable = (text, wordDetail, index) => {
        return (
            <span
                key={index}
                onClick={() => handleOpenWordModal(wordDetail)}
                className="underline text-primary font-bold cursor-pointer"
            >
                {text}
            </span>
        );
    };

    // Altyazıların render olmasını sağlayan fonksiyon
    const renderSubtitles = (subtitleData, source) => {
        const currentSubtitle = subtitleData.find(subtitle => {
            return currentTime >= timeStampToSecond(subtitle.startTime) && currentTime < timeStampToSecond(subtitle.endTime);
        });

        if (currentSubtitle) {
            if (source === 'translation') {
                return (
                    <div className="max-w-lg">{currentSubtitle?.content}</div>
                );
            }

            if (source === 'subtitle') {
                const words = currentSubtitle?.wordDetail.length > 0
                    ? splitWordsWithSpaces(currentSubtitle.wordDetail)?.map(word => word.word)
                    : [];

                // Kelimeleri tıklanılabilir hale getirir
                const subtitles = currentSubtitle?.content.split(/\b/).map((part, index) => {
                    const word = part.trim().toLowerCase();
                    if (words.includes(word)) {
                        const wordDetail = splitWordsWithSpaces(currentSubtitle?.wordDetail).find(w => w.word === word);
                        return makeClickable(part, wordDetail, index);
                    }
                    return part;
                });

                return <div className="max-w-lg">{subtitles}</div>;
            }
        } else {
            return <></>
        }
    };

    // Butonların render olmasını sağlayan fonksiyon
    const renderButtons = () => {
        const isCurrentSection = (section) => {
            return currentTime >= timeStampToSecond(section.startTime) && currentTime < timeStampToSecond(section.endTime)
        }

        return (
            <div
                ref={sectionRefs}
                className="flex gap-4 max-w-[330px] sm:max-w-[470px] overflow-x-auto sm:overflow-x-hidden sm:hover:overflow-x-auto"
            >
                {data.sections.map((section, index) => (
                    <button
                        key={index}
                        type="button"
                        onClick={() => playerRef?.current?.seekTo(timeStampToSecond(section.startTime))}
                        className={classNames("w-8 h-8 rounded-full flex flex-shrink-0 items-center justify-center mb-2", {
                            "border-[1px] border-[#c5c5c5] bg-[#e5e5e5] ": !isCurrentSection(section),
                            "bg-primary text-white": isCurrentSection(section)
                        })}
                    >
                        {section.sequence}
                    </button>
                ))}
            </div>
        )
    }

    // Video altyazısının açıp/kapanma işlevini kontrol eden fonksiyon
    const handleShowSubtitleTranslation = () => {
        setShowSubtitleTranslation(showSubtitleTranslation => !showSubtitleTranslation)

        //LOGGED
        sendEvent(
            showSubtitleTranslation ? EventLogs.VIDEO_EXERCISE_SUBTITLE_OPENED : EventLogs.VIDEO_EXERCISE_SUBTITLE_CLOSED,
            {
                video_name: data?.videoTitle
            },
        );
    }

    // Geri butonunu kontrol eden fonksiyon
    const handleBackButton = () => {
        navigate(url("videopractice"));
        //LOGGED
        sendEvent(
            EventLogs.VIDEO_EXERCISE_BACK_CLICK,
            {
                video_name: data?.videoTitle
            },
        );
    }

    // İleri butonunu kontrol eden fonksiyon
    const handleContinueButton = () => {
        navigate(url("videopractice.videoflow.word", {videoId: videoId}));
        //LOGGED
        sendEvent(
            EventLogs.VIDEO_EXERCISE_CONTINUE_CLICK,
            {
                video_name: data?.videoTitle
            },
        );
    }

    const getVideoDetails = () => {
        GetVideoDetails(videoId, LanguageTitleType.TURKISH)
            .then(result => {
                if (result.status === 200)
                    setData(JSON.parse(result.content));

                setLoading(false);
            })
            .catch()
    }

    const getVideoDetailsById = (videoId) => {
        GetVideoDetailsById(+videoId)
            .then(result => {
                if (result.status === 200)
                    setData(result.content);
                else
                    navigate(url("dashboard"));
            })
            .catch()

        setLoading(false);
    }

    // Video detayları servisten getiriliyor
    useEffect(() => {
        isSinglePage
            ? getVideoDetailsById(outsideVideoId)
            : getVideoDetails()
    }, [])

    // Section'ların otomatik scroll olmasını sağlayan kısım
    useEffect(() => {
        // İçerisinde olunan section data içerisinden bulunuyor.
        if (data?.sections?.length > 0) {
            data.sections.map((section) => {
                if (currentTime >= timeStampToSecond(section.startTime) && currentTime <= timeStampToSecond(section.endTime))
                    setCurrentSection(section.sequence - 1);
            })
        }
    }, [currentTime, data]);

    // Otomatik scroll işlemleri
    useEffect(() => {
        if(currentSection > 0) {
            const handleScroll = () => {
                if (sectionRefs?.current) {
                    const subtitlesWidth = sectionRefs.current.scrollWidth;
                    const containerWidth = sectionRefs.current.clientWidth;

                    const scrollWidth = (subtitlesWidth - containerWidth) / data?.sections.length;
                    sectionRefs.current.scrollLeft = currentSection * scrollWidth;
                }
            }

            handleScroll();
        }
    }, [currentSection]);

    // Video başlatma/durdurma durumu log atılıyor.
    useEffect(() => {
        if(Object.keys(data).length > 0) {
            //LOGGED
            sendEvent(
                isVideoPlaying ? EventLogs.VIDEO_EXERCISE_PLAY : EventLogs.VIDEO_EXERCISE_PAUSE,
                {
                    video_name: data?.videoTitle
                },
            );
        }
    }, [isVideoPlaying, data])

    // Video hızının ilk değişimi set ediliyor
    useEffect(() => {
        if(selectedSpeed?.speed !== 1) {
            setIsFirstSpeedChange(true);
        }
    }, [selectedSpeed]);

    // Video hızı değiştikçe log atılıyor
    useEffect(() => {
        if(isFirstSpeedChange) {
            //LOGGED
            sendEvent(
                EventLogs.VIDEO_EXERCISE_CHANGED_SPEED,
                {
                    video_name: data?.videoTitle,
                    video_speed: selectedSpeed?.speed + "X"
                },
            );
        }
    }, [selectedSpeed, isFirstSpeedChange]);

    return (
        <>
            <div
                className={classNames("gray-section px-[10px] xs:px-[10px] text-center flex flex-col gap-3", {
                    "mt-5": isSinglePage,
                    "mt-10": !isSinglePage
                })}
            >
                {loading && <Loading classnames="mb-5"/>}

                {(Object.keys(data).length > 0 && !loading) &&
                    <div className="flex flex-col gap-8 items-center justify-center">
                        <div className="flex flex-col gap-1">
                            <ReactPlayer
                                ref={playerRef}
                                url={data.vimeoVideoUrl}
                                controls
                                playbackRate={selectedSpeed?.speed}
                                className="react-player"
                                playing={isVideoPlaying}
                                onPlay={() => setIsVideoPlaying(true)}
                                onPause={() => setIsVideoPlaying(false)}
                                onProgress={handleProgress}
                                onEnded={handleVideoEnd}
                            />

                            <div className="flex justify-between mt-1">
                                <List
                                    data={VideoSpeeds}
                                    selected={selectedSpeed}
                                    setSelected={setSelectedSpeed}
                                    children={
                                        <div className="flex gap-1">
                                            <span className="material-symbols-outlined">slow_motion_video</span>
                                            <p>{strings.video_practice.video.video_speed}</p>
                                        </div>
                                    }
                                />
                                {data.subtitleTranslations.length > 0 &&
                                    <button
                                        className={classNames("flex gap-1", {
                                            "text-primary": showSubtitleTranslation
                                        })}
                                        onClick={handleShowSubtitleTranslation}
                                    >
                                        <span className="material-symbols-outlined">subtitles</span>
                                        <p>{strings.video_practice.video.turkish_subtitle}</p>
                                    </button>
                                }
                            </div>
                        </div>

                        {data.subtitles.length > 0 && renderSubtitles(data.subtitles, 'subtitle')}

                        {(showSubtitleTranslation && data.subtitleTranslations.length > 0) &&
                            renderSubtitles(data.subtitleTranslations, 'translation')
                        }

                        {data.sections.length > 0 && renderButtons()}
                    </div>
                }
            </div>

            {!isSinglePage &&
                <div className="flex gap-8 my-5 justify-center">
                    <Button
                        type="button"
                        classnames="button primary-button-outline max-w-[150px]"
                        action={handleBackButton}
                        text={strings.pagination.previous}
                    />
                    <Button
                        type="button"
                        classnames="button primary-button max-w-[150px]"
                        action={handleContinueButton}
                        text={strings.speaking_lesson.quiz.continue}
                    />
                </div>
            }

            {openWordModal &&
                <WordModal
                    source="video"
                    wordId={selectedWordId}
                    handleClose={handleCloseWordModal}
                    contentName={data?.videoTitle}
                />
            }
        </>
    )
}

export default Video;