import {Empty} from "../../../../assets/svg";

const AIButton = (props) => {

    const { icon, title, action, isVisible } = props;

    return (
        isVisible && (
            <div
                 className="onboarding-button flex items-center cursor-pointer"
                 onClick={action}
                 dir=""
            >
                <div className="onboarding-button-left">
                    <img src={icon || Empty} alt="" width="32" height="32" />
                </div>
                <p className="text-start">{title}</p>
            </div>
        )
    );
};

export default AIButton;
