import {useLocalization} from "../../../../hooks/useLocalization";
import {BottomStickyBar, Button} from "../../../../components";
import React from "react";
import {useNavigate} from "react-router-dom";
import {url} from "../../../../routes/utility";

const ReferenceBar = () => {

    const strings = useLocalization();
    const navigate = useNavigate();

    return (
        <BottomStickyBar
            theme="dark"
        >
            <div className="flex justify-between items-center min-h-[48px]">
                <p className="font-bold text-[16px] md:text-[18px]">
                    {strings.speaking_lesson.components.reference_bar.description}
                </p>
                <Button
                    classnames="button primary-button-outline !border-onboarding-border bg-box text-base-text px-4 h-8 max-w-max text-[16px] mt-0 leading-[16px]"
                    text={strings.speaking_lesson.components.reference_bar.button_text}
                    action={() => navigate(url("learntogether.reference"))}
                />
            </div>
        </BottomStickyBar>
    );
}

export default ReferenceBar;