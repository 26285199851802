const InformationAccordion = (props) => {
  return (
    <>
      <div
        className="group outline-none accordion-section text-sm mt-5"
        tabIndex="1"
      >
        <button className="group accordion-div-main group-focus-within:border-b-0 w-full pr-1">
          <div className="accordion-header">
            <span className="material-symbols-outlined text-base-text">
              {" "}
              arrow_forward_ios{" "}
            </span>
          </div>
          <div className="accordion-text-main">
            <p className="font-bold text-start">{props.title};</p>
          </div>
        </button>
        <div className="accordion-content-main">
          {props.generalInformationData &&
            props.generalInformationData.map((item) => (
              <p className="mb-3" key={item.id}>
                <span className="font-bold">{item.id}) </span>
                {item.text}
              </p>
            ))}
        </div>
      </div>
    </>
  );
};

export default InformationAccordion;
