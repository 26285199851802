import {AccordionFilter, Dropdown, DropdownMultiselect} from "../../../components";
import TeacherTimeRangePicker from "./TeacherTimeRangePicker";
import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../hooks/useLocalization";
import {addTimeZone, getTimeZone} from "../../../utils";
import useAuth from "../../../hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {setTermId} from "../../../store/TermSlice";
import {EventLogs, MemberStatus, TermStatusTypes} from "../../../components/Constants";
import useAnalytics from "../../../hooks/useAnalytics";

const TeacherDetailSearch = ({ changeDetail, loading, setSendToEndpoint }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const { member } = useAuth();
    const dispatch = useDispatch();

    const timeZone = getTimeZone(member.TimeZone);

    const terms = (typeof member.Terms !== "undefined") && JSON.parse(member.Terms).filter(term => term.Status === TermStatusTypes.Active);
    const selectedTermId = useSelector(state => state.termStore.termId);

    const [termData, setTermData] = useState([]);
    const [selectedTermData, setSelectedTermData] = useState({});

    let firstSelectedStartTime = {id: -1, name: strings.teachers.filter.start_time};
    const [selectedStartTime, setSelectedStartTime] = useState(firstSelectedStartTime);

    let firstSelectedEndTime = {id: -1, name: strings.teachers.filter.end_time}
    const [selectedEndTime, setSelectedEndTime] = useState(firstSelectedEndTime);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const teacherFeatures = [
        { id: 1, name: strings.teachers.filter.teacher_features_list.beginner_friendly },
        { id: 9, name: strings.teachers.filter.teacher_features_list.child_friendly },
        { id: 10, name: strings.teachers.filter.teacher_features_list.grammar_focused }
    ];

    const handleFilterTeachersButton = () => {
        setSendToEndpoint(true);
        localStorage.setItem("getAll", JSON.stringify(false));

        //Filtreleme yapıldıkça log atılıyor.
        analytics.useAnalytics(
            EventLogs.TEACHER_FILTER_CLICKED,
            {
                baslangic_saati: selectedStartTime.id === -1 ? "" : selectedStartTime.name,
                bitis_saati: selectedEndTime.id === -1 ? "" : selectedEndTime.name,
                ozellikler: selectedOptions.length === 0 ? "" : selectedOptions.map(opt => opt.name).join(", ")
            }
        )
    }

    useEffect(() => {
        changeDetail?.({ startTime: selectedStartTime.id });
    }, [selectedStartTime.id])

    useEffect(() => {
        changeDetail?.({ endTime: selectedEndTime.id });
    }, [selectedEndTime.id])

    useEffect(() => {
        changeDetail?.({ instructors: selectedOptions.map(option => option.id) });
    }, [selectedOptions])

    /*
        localStorage'dan gelen bir veri var ise bu verilerin sayfaya tekrar gelindiğinde
        seçili gelmesi sağlanıyor.
    */
    useEffect(() => {
        if(localStorage.getItem("teacherFilter")) {
            let parsedValues = JSON.parse(localStorage.getItem("teacherFilter"));

            if(parsedValues.startTime !== -1 && parsedValues.endTime !== 1) {
                let tmpSelectedStartTime = {
                    id: parsedValues?.startTime,
                    name: addTimeZone(parsedValues?.startTime, timeZone)
                }
                setSelectedStartTime(tmpSelectedStartTime);

                let tmpSelectedEndTime = {
                    id: parsedValues?.endTime,
                    name: addTimeZone(parsedValues?.endTime, timeZone)
                }
                setSelectedEndTime(tmpSelectedEndTime);
            }

            if(parsedValues.instructors.length > 0) {
                let tmpSelectedOptions = [];

                teacherFeatures.map((teacherFeature) => {
                    if(parsedValues.instructors.includes(teacherFeature.id)){
                        tmpSelectedOptions.push(teacherFeature);
                    }
                })

                setSelectedOptions(selectedOptions => tmpSelectedOptions);
            }
        }
    }, []);

    /*
        Sayfa ilk açıldığında öğrencinin dönemleri ile veri dolduruluyor.
    */
    useEffect(() => {
        if(terms.length > 0) {

            let tmpTerms = [];

            terms.map((term) => {
                tmpTerms.push({
                    id: term.TermId,
                    name: `${term.ProductName + " / " + addTimeZone(term.LessonTime, timeZone) + " / " + term.LessonDuration} ${strings.general_information_messages.minute}`
                });
            });

            setTermData(termData => tmpTerms);
        }
    }, [])

    /*
        Sayfaya girmeden önce kullanıcının seçili dönemi aktif dönem listesinde var ise ilgili dönem seçtiriliyor.
        Aktif dönem listesinde yok ise ilk aktif dönemi seçtiriliyor.
    */
    useEffect(() => {
        if(termData.length > 0) {
            let selectedTerm = termData.find(term => term.id === selectedTermId) || false;

            if(selectedTerm)
                setSelectedTermData(selectedTerm);
            else
                setSelectedTermData(termData[0])
        }
    }, [termData])

    /*
        Seçili dönem değiştikçe term store'daki bilgileri günceller
    */
    useEffect(() => {
        if(Object.keys(selectedTermData).length > 0)
            dispatch(setTermId(selectedTermData.id));
    }, [selectedTermData]);

    return (
        <AccordionFilter
            title={strings.teachers.filter.filter_teachers}
            outSideClose={true}
            isDisabled={loading}
            clickEvent={EventLogs.TEACHER_FILTER_OPENED}
            content={
                <div className="flex flex-col gap-5">
                    {+member?.Status === MemberStatus.Active && (
                        <div className="flex items-center justify-center">
                            <div className="flex flex-col items-start">
                                <label className="text-[14px] font-bold">{strings.teachers.filter.my_packages}</label>
                                <div className="mt-1">
                                    <Dropdown
                                        data={termData}
                                        selected={selectedTermData}
                                        setSelected={setSelectedTermData}
                                        classnames="max-w-max min-w-[348px] lg:min-w-[550px]"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col gap-2 items-center">
                        <div className="flex flex-col lg:flex-row items-center justify-center gap-5">
                            <div className="flex flex-col items-start">
                                <TeacherTimeRangePicker
                                    firstSelectedStartTime={firstSelectedStartTime}
                                    selectedStartTime={selectedStartTime}
                                    setSelectedStartTime={setSelectedStartTime}
                                    firstSelectedEndTime={firstSelectedEndTime}
                                    selectedEndTime={selectedEndTime}
                                    setSelectedEndTime={setSelectedEndTime}
                                />
                            </div>
                            <div className="flex flex-col items-start">
                                <label className="text-[14px] font-bold">{strings.teachers.filter.teacher_feature}</label>
                                <div className="mt-1">
                                    <DropdownMultiselect
                                        data={teacherFeatures}
                                        normalTitle={strings.teachers.filter.choose_teacher_feature}
                                        selectedTitle={strings.teachers.filter.choose_teacher_feature_number}
                                        selectedDataCount={selectedOptions.length}
                                        state={selectedOptions}
                                        setState={setSelectedOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="button !max-w-[330px] primary-button my-7 focus:!bg-primary"
                            onClick={handleFilterTeachersButton}
                        >
                            {strings.teachers.filter.title}
                        </button>
                    </div>
                </div>
            }
        />
    );
}

export default TeacherDetailSearch;