import {useEffect, useState} from "react";
import {Loading, NoRecordsFound} from "../../../components";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import {useLocalization} from "../../../hooks/useLocalization"
import {GetTicketsService} from "../../../services/Support/SupportService";
import {TicketCard} from "../components";

const Tickets = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const { member, token } = useAuth();

    const [loading, setLoading] = useState(true);
    const [prevTickets, setPrevTickets] = useState([]);

    const getTickets = async () => {
        try {
            const result = await GetTicketsService(member.MemberId, token);

            if (result.statusCode === 200) {

                if (result.message !== {}) {
                    setPrevTickets(JSON.parse(result.message));
                }

            } else {
                if (result.message)
                    analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
            }

            setLoading(false);
        }
        catch (error) { }
    };

    useEffect(() => {
        const controller = new AbortController();

        getTickets();

        return () => { controller.abort(); }
    }, []);

    return (
        <>

            <div className="main-content">
                <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-center text-sm">
                    <p className="font-bold px-5">
                        {strings.support.previous_request.title}
                    </p>
                </div>

                {loading && <Loading />}

                {!loading &&
                    <>
                        {prevTickets.length !== 0 ? prevTickets.reverse().map((ticket, index) => (
                            <TicketCard
                                key={index}
                                index={index}
                                ticket={ticket}
                            />
                        )) :
                            <NoRecordsFound />
                        }
                    </>
                }

                <div className="left-margin-bottom"></div>
            </div>
        </>
    )
}

export default Tickets