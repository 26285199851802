import { createSlice } from "@reduxjs/toolkit";

export const AgoraSlice = createSlice({
    name: 'Agora',
    initialState: { audioTrack: null, client: null },
    reducers: {
        setRtc: (state, action) => {
            state.audioTrack = action.payload.audioTrack;
            state.client = action.payload.client;
        }
    }
});

export const { setRtc } = AgoraSlice.actions;

export default AgoraSlice.reducer;