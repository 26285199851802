import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { staticData } from '../../assets/data/staticData';
import HowToLearnCard from './components/HowToLearnCard';
import { useLocalization } from '../../hooks/useLocalization';
import { ModalYoutube, ModalOverlay } from '../../components';
import { openModal } from '../../utils';
import { Most_Curious_About } from '../../assets/img';
import useAnalytics from '../../hooks/useAnalytics';

const DashboardHowToLearnEnglish = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const language = useSelector(state => state.localizationStore.language);

  const modalRef = useRef();
  const modalOverlayRef = useRef();

  const [videoUrl, setVideourl] = useState('');

  return (
    <>

      <div className="main-content">
        <div className="w-full relative flex justify-center">
          <img className="rounded-[10px] w-full max-w-[438px] mt-5 md:mt-7" src={Most_Curious_About} alt="" />
          <button type="button" className="button secondary-button-outline inside-button bottom-5"
            onClick={() => {
              setVideourl("https://www.youtube.com/embed/qDE5mXKyQTg");
              analytics.useAnalytics('Campaign_Page_Video_View');
              openModal(modalOverlayRef, modalRef);
            }}>
            <div className="play-button h-3 w-3"></div>
            {strings.dashboard.how_to_learn.play_video}
          </button>
        </div>
        <div className="mt-5 md:mt-7">

          <h5 className="text-secondary mb-2">{strings.dashboard.how_to_learn.most_curious_about}</h5>

          {
            staticData.studentQuestions.map((question, index) => {
              let count = index + 1;
              let showButtonCount = index + 1;
              let class_name = "blue-leftside";

              if (count > 5) count = count - 4;

              if (count % 3 === 0) {
                class_name = "orange-leftside";
              } else if (count % 2 === 0) {
                class_name = "gray-rightside";
              }

              return (
                <HowToLearnCard key={index} title={question.title[language]}
                  content={question.text[language]}
                  class_name={class_name}
                  show_button={showButtonCount % 3 === 0}
                  view_package={strings.dashboard.none_user.view_packages} />
              )
            })
          }
          <br />
        </div>
      </div>

      {/* --- Modal --- */}

      <ModalOverlay ref={modalOverlayRef} />
      <ModalYoutube
        ref={modalRef}
        videoUrl={videoUrl}
        overlayRef={modalOverlayRef}
      />

    </>
  )
}

export default DashboardHowToLearnEnglish