import { fetchHeader } from "./Header";

export const fetchApi = async (url, payload) => {

    const apiUrl = process.env.REACT_APP_API_URL + url;
    
    const response = await fetch(apiUrl, fetchHeader(payload));
    const json = await response.json();

    return json;
}

export const fetchPythonApi = async (url, data) => {

    const apiUrl = process.env.REACT_APP_PYTHON_API_URL + url;

    const response = await fetch(apiUrl, fetchHeader(data));
    const json = await response.json();

    return json;
}

export const fetchOutsourceApi = async (url, payload) => {

    const response = await fetch(url, fetchHeader(payload));

    return await response.json();
}