import React, {useEffect} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Successful } from '../../assets/svg';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { addTimeZone, getTimeZone } from '../../utils';

const DemoSuccess = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const timeZone = getTimeZone();
    const strings = useLocalization();
    const lesson = location?.state?.lesson;

    const formatDate = (date) => {
        const dateObj = new Date(date);

        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        const formattedDate = `${day}.${month}.${year}`;

        return formattedDate;
    }

    useEffect(() => {
        //eğer kullanıcı path'den gelmeye çalışırsa sayfa hata vermemesi için bir önceki sayfaya yönlendiriliyor.
        if (lesson === undefined || lesson.lessonDate === undefined) {
            navigate(-1);
        }
    }, []);

    return (
        <div className="main-content">
            {lesson?.lessonDate && lesson?.lessonTime && (
                <div className="gray-section mt-5 py-5">
                    <p className='font-bold'></p>
                    <img
                        className="inline-block"
                        src={Successful}
                        alt=""
                        width="100"
                        height="100"
                    />
                    <p className="font-bold mt-7 text-base">{strings.market.completed.title}</p>
                    <p className="mt-5" dangerouslySetInnerHTML={{ __html: strings.demo.success_message.replace("#date#", formatDate(lesson?.lessonDate)).replace("#hour#", addTimeZone(lesson?.lessonTime.split(":").slice(0, 2).join(":"), timeZone)) }} />
                    <div className='btn-outer-div mt-7'>
                        <Link
                            to={url("dashboard")}
                            className="button w-[95%] xs:w-[330px] primary-button mb-2"
                        >
                            {strings.modal.okey}
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DemoSuccess