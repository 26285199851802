import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
    name: 'Auth',
    initialState: { isLoggedIn: false },
    reducers: {
        setAuth: (state, action) => {
            state.isLoggedIn = action.payload;
        }
    }
});

export const { setAuth } = AuthSlice.actions;

export default AuthSlice.reducer;