import classNames from "classnames";
import React, { useEffect, useState } from "react";

const TopStickBar = (props) => {

    const { isCloseable = true, children, theme = "secondary", isOverlay = false, isOpenOnce = false } = props;
    const [isOpen, setIsOpen] = useState(false);
    
    const storedData = sessionStorage.getItem('topStickyBar');

    const handleClose = () => {
        setIsOpen(false);
        isOpenOnce && updateSessionStorage(theme, true);
    }

    const updateSessionStorage = (theme) => {
        const storedData = sessionStorage.getItem('topStickyBar');

        let themeArray = [];
        if (storedData) {
            themeArray = JSON.parse(storedData);
        }

        if (!themeArray.includes(theme)) {
            themeArray.push(theme);
        }

        sessionStorage.setItem('topStickyBar', JSON.stringify(themeArray));
    };

    // Modal açıldığında arka plandaki overflow'u kapatan fonksiyon
    useEffect(() => {
        if (isOpen && isOverlay) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, isOverlay]);

    useEffect(() => {

        if (storedData) {
            const existingItem = JSON.parse(storedData);

            if (!existingItem.includes(theme)) {
                setIsOpen(true)
            }
        } else {
            setIsOpen(true)
        }
    }, [theme, storedData]);

    return (
        <>
            {isOpen && isOverlay && <div className="fixed z-40 w-screen h-screen inset-0 bg-[#333333] bg-opacity-70" />}
            <div
                className={classNames("min-h-[50px] fixed left-0 right-0 top-0 w-full shadow-md transition-transform duration-700 z-[41]", {
                    "translate-y-0": isOpen,
                    "-translate-y-[160px]": !isOpen,
                    "bg-secondary text-white": theme === "secondary",
                    "bg-white": theme === "white"
                })}
            >
                <div className="flex min-h-[50px] relative">
                    {isCloseable &&
                        <span
                            onClick={handleClose}
                            className={classNames("material-symbols-outlined cursor-pointer top-1 right-1 absolute", {
                                "text-white": theme === "green"
                            })}
                        >
                            close
                        </span>
                    }
                    {children}
                </div>
            </div>
        </>
    )
}

export default TopStickBar;