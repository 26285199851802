import {useEffect, useState} from "react";
import {GetMemberUsername} from "../services/Profile/ProfileService";

const useMemberUsername = (memberId) => {

    const [username, setUsername] = useState("");

    useEffect(() => {
        memberId &&
            GetMemberUsername(memberId)
                .then(result => {
                    if(result.status === 200) {
                        setUsername(result.content);
                    }
                })
                .catch()
    }, []);

    return username;
}

export default useMemberUsername;