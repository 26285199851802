import { Error } from "../components";
import { Icon_Confused } from "../../../assets/svg";
import { useLocalization } from "../../../hooks/useLocalization";

const FreezeNoRight = () => {
    const strings = useLocalization();

    return (
        <Error
            icon={Icon_Confused}
            title={strings.support.freeze_lesson_no_right.no_freeze_right}
            content={strings.support.freeze_lesson_no_right.no_freeze_right_information}
            hideButton="hidden"
        />
    )
}

export default FreezeNoRight