import {History} from "../../../../assets/svg";
import {useLocalization} from "../../../../hooks/useLocalization";
import {useEffect, useState} from "react";
import {GetMemberStatistics} from "../../../../services/Profile/ProfileService";
import {Loading, StreakBar} from "../../../../components";

const Statistics = ({ memberId, username }) => {

    const strings = useLocalization();

    const [statistics, setStatistics] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        memberId &&
            GetMemberStatistics(memberId)
                .then(result => {
                    if (result.status === 200)
                        setStatistics(JSON.parse(result.content));

                    setLoading(false);
                })
                .catch()
    }, [memberId]);

    return (
        Object.keys(statistics).length > 0 &&
            <div className="mt-4">
                <p className="text-start font-bold">
                    {strings.profile.statistics.title}
                </p>

                {loading && <Loading classnames="!mt-2" />}

                {!loading &&
                    <div className="flex gap-2 mt-1.5">
                        <StreakBar
                            memberId={memberId}  
                            source={`profile/${username}`}
                        />
                        <div className="flex items-center justify-start gap-2 px-3 w-full h-12 rounded-[10px] bg-primary/10">
                            <img
                                src={History ?? ""}
                                width="24"
                                height="24"
                                alt="history"
                            />
                            <div className="flex flex-col">
                                <p className="font-bold">
                                    {strings.profile.statistics.total_lesson_duration
                                        ?.replace("#minutes#", statistics?.totalLessonDuration)
                                    }
                                </p>
                                <p className="text-[14px] -mt-1">
                                    {strings.profile.statistics.total_lesson_duration_title}
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
    )
}

export default Statistics;