import React, { useEffect, useRef, useState } from "react";
import { ModalOneButton, ModalOverlay, ModalTwoButtons } from "../../../../components";
import { closeModal, getFormattedTime, openModal } from "../../../../utils";
import { useLocalization } from "../../../../hooks/useLocalization";
import { AnswerButton, QuizTimer } from "../index";
import QuizReport from "./QuizReport";
import classNames from "classnames";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";
import {url} from "../../../../routes/utility";
import {useLocation, useNavigate} from "react-router-dom";

const QuizQuestions = (props) => {

    const { quizId, bookName, bookUnitId, quizInformation, setIsStartQuiz, source = "quiz" } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(window.location.search);

    const outsideQuizId = params.get('quizId') || false;
    const dateFromState = location?.state?.date || null;

    const modalRef = useRef();
    const quitModalRef = useRef();
    const modalOverlayRef = useRef();

    // Sayaç durduğunda bu state true'ya çekilir.
    const [isTimerEnd, setIsTimerEnd] = useState(false);
    // Bu state 'true' sayaç durur. 'false' olduğunda sayaç devam eder.
    const [pauseTimer, setPauseTimer] = useState(false);
    // Kullanıcının aktif olarak olduğu sorunun indexini tutar.
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    // Kullanıcının soruya verdiği cevabı tutar.
    const [userAnswer, setUserAnswer] = useState(-1);
    // Kullanıcının 'Kontrol Et' butonuna tıklama durumunu tutar.
    const [isClickedControlButton, setIsClickedControlButton] = useState(false);

    // Kullanıcının quizi başladığı zamanı tutar.
    const [startTime, setStartTime] = useState("");
    // Kullanıcının quizi bitirdiği zamanı tutar.
    const [quizReport, setQuizReport] = useState({});
    // Kullanıcının quizi bitirdiğinde rapor sayfasını göstermek için kullanılır.
    const [showQuizReport, setShowQuizReport] = useState(false);

    const quizDuration = process.env.REACT_APP_QUIZ_TIME_PER_QUESTION_IN_SECOND * quizInformation.length

    //Rapor sayfasında 'Tekrar Çözmek İstiyorum' butonuna tıklandığında kullanılır.
    const solveAgain = () => {
        setCurrentQuestionIndex(currentQuestionIndex => 0);
        setShowQuizReport(showQuizReport => false);
        setIsTimerEnd(isTimerEnd => false);
        setStartTime(startTime => getFormattedTime(new Date()));
        setQuizReport({
            quizId: quizId,
            bookName: bookName,
            bookUnitId: bookUnitId,
            questions: quizInformation.map((question) => ({
                id: question.id,
                rightAnswer: question.questionAnswers.find(answer => answer.isCorrectAnswer)?.id || -1,
                userAnswer: -1,
            })),
        });
    }

    const handleQuizButton = () => {
        /* Buton görev kontrolleri */
        if (userAnswer === -1 || (userAnswer !== -1 && isClickedControlButton)) {
            setCurrentQuestionIndex(currentQuestionIndex + 1)
            setIsClickedControlButton(isClickedControlButton => false);
        } else if (userAnswer !== -1 && !isClickedControlButton) {
            setIsClickedControlButton(true);
        }

        /* Log kontrolleri */

        //Boş bırakılma durumunda log atılıyor.
        if(userAnswer === -1 && !isClickedControlButton) {

            if(source === "quiz")
                analytics.useAnalytics(EventLogs.LESSON_QUIZ_LEAVE_EMPTY_CLICK);

            if(source === "dailysentence")
                analytics.useAnalytics(
                    EventLogs.DAILY_PATTERN_LEAVE_EMPTY_CLICK,
                    { question_index: currentQuestionIndex + 1}
                );

        }

        //Devam et butonuna tıklandığında log atılıyor.
        if(isClickedControlButton) {

            if(source === "quiz")
                analytics.useAnalytics(
                    EventLogs.LESSON_QUIZ_CONTINUE,
                    { answer_index: currentQuestionIndex + 1 }
                );

            if(source === "dailysentence")
                analytics.useAnalytics(
                    EventLogs.DAILY_PATTERN_CONTINUE,
                    { question_index: currentQuestionIndex + 1 }
                );
        }

        /* Log kontrolleri */
    }

    const handleAnswerButton = (item) => {
        if (!isClickedControlButton) {
            setUserAnswer(userAnswer => item.id);

            //Quiz'de şık seçildikte log atılıyor.
            if(source === "quiz")
                analytics.useAnalytics(EventLogs.LESSON_QUIZ_ANSWER_CHOOSED);
        }
    }

    const handleQuitQuiz = () => {
        if(source === "quiz" && !dateFromState) {
            !outsideQuizId
                ? window.location.href = "/speakinglesson/quiz"
                : window.location.href = "/dashboard";
        }

        if(source === "quiz" && dateFromState) {
            localStorage.setItem("selectedDate", dateFromState);
            navigate(url("speakinglesson.dailylesson"));
        }

        if(source === "resources") {
            setIsStartQuiz(isStartQuiz => false);
        }

        //Quizden çıkış yapıldığında log atılıyor.
        analytics.useAnalytics(EventLogs.LESSON_QUIZ_EXIT, { quiz_name: bookName });
    }

    // Kullanıcı soru değiştirdiğinde 'Kontrol Et' butonuna tıklanma durumunu sıfırlıyoruz.
    useEffect(() => {
        setIsClickedControlButton(isClickedControlButton => false);
    }, [currentQuestionIndex]);

    // Kullanıcının quize başladığı zaman saat:dakika:saniye şeklinde tutuluyor.
    useEffect(() => {
        setStartTime(startTime => getFormattedTime(new Date()));

        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    // Quiz Information bilgileri değiştiğinde quizReport objesini güncelliyoruz.
    useEffect(() => {
        if (quizInformation.length > 0) {
            setQuizReport({
                quizId: quizId,
                bookName: bookName,
                bookUnitId: bookUnitId,
                questions: quizInformation.map((question) => ({
                    id: question.id,
                    rightAnswer: question.questionAnswers.find(answer => answer.isCorrectAnswer)?.id || -1,
                    userAnswer: -1,
                })),
            });
        }
    }, [quizInformation]);

    // Kullanıcının cevapladığı soruları rapor sayfasında göstermek için kullanılır.
    // Bu objeye göre rapor sayfasında soruların doğru yanlış cevaplarını gösteriyoruz.
    useEffect(() => {
        if (userAnswer !== -1) {
            const updatedQuestions = [...quizReport.questions];
            updatedQuestions[currentQuestionIndex].userAnswer = userAnswer;
            setQuizReport((prevObject) => ({
                ...prevObject,
                questions: updatedQuestions,
            }));
            setUserAnswer(-1);
        }

    }, [isClickedControlButton]);

    // Süre dolduğunda rapor sayfasına yönlendirilir.
    useEffect(() => {
        isTimerEnd && openModal(modalOverlayRef, modalRef);
    }, [isTimerEnd]);

    // Kontrol et butonuna tıklandığında event ve log atılıyor.
    useEffect(() => {
        if(isClickedControlButton)
            if(quizReport.questions[currentQuestionIndex].rightAnswer === quizReport.questions[currentQuestionIndex].userAnswer){

                if(source === "quiz")
                    analytics.useAnalytics(
                        EventLogs.LESSON_QUIZ_ANSWER_CHECKED,
                        { answer: true }
                    );

                if(source === "dailysentence")
                    analytics.useAnalytics(
                        EventLogs.DAILY_PATTERN_CONTROL,
                        { result: true, question_index: currentQuestionIndex + 1 }
                    );
            }

            else {

                if(source === "quiz")
                    analytics.useAnalytics(
                        EventLogs.LESSON_QUIZ_ANSWER_CHECKED,
                        { answer: false }
                    );

                if(source === "dailysentence")
                    analytics.useAnalytics(
                        EventLogs.DAILY_PATTERN_CONTROL,
                        { result: false, question_index: currentQuestionIndex + 1 }
                    );
            }
    }, [isClickedControlButton])

    return (
        <>
            {(currentQuestionIndex === quizInformation.length || showQuizReport) && (
                <QuizReport
                    startTime={startTime}
                    quizReport={quizReport}
                    quizQuestions={quizInformation}
                    setIsStartQuiz={setIsStartQuiz}
                    solveAgain={solveAgain}
                    source={source}
                />
            )}

            {(currentQuestionIndex !== quizInformation.length && !showQuizReport) && (
                <div className={classNames("mt-5", {
                    "main-content": source === "quiz"
                })}>
                    <div className={classNames("", {
                        "left-page": source === "quiz"
                    })}>
                        <div className="mb-5 gray-section bg-title-bg px-[10px] xs:px-[10px] text-start">
                            <QuizTimer duration={quizDuration} setIsTimerEnd={setIsTimerEnd} pauseTimer={pauseTimer} />
                        </div>
                        <p className="mt-5 text-end"><span className="text-[20px] font-bold">{currentQuestionIndex + 1}</span><span className="text-[16px]">/{quizReport?.questions?.length}</span></p>
                        <div className="transparent-section mt-1 text-sm text-start relative pt-4">
                            <div className="absolute top-0 left-0 right-0 bg-green h-2 rounded-full" style={{width: `${100 * (currentQuestionIndex + 1 )/quizReport?.questions?.length}%`}}></div>
                            {(source === "quiz" || source === "resources") && (
                                <div className="absolute top-2 right-2">
                                    <span
                                        className="material-symbols-outlined cursor-pointer"
                                        onClick={() => {
                                            openModal(modalOverlayRef, quitModalRef)
                                            setPauseTimer(pauseTimer => true);
                                        }}
                                    >
                                        close
                                    </span>
                                </div>
                            )}
                            <h5 className="text-secondary text-[17px]">{bookName}</h5>
                            <div className="flex flex-col">
                                <p className="w-full text-center mt-2 font-bold">
                                    <span>{currentQuestionIndex + 1}) </span>
                                    <span dangerouslySetInnerHTML={{ __html: quizInformation[currentQuestionIndex].content.replace(/\n/g, '<br>') }}></span>
                                </p>
                                <div className="flex flex-col mt-4 items-center">
                                    {quizInformation[currentQuestionIndex].questionAnswers.map((item, index) => (
                                        <AnswerButton
                                            key={index}
                                            index={index}
                                            item={item}
                                            userAnswer={userAnswer}
                                            quizReport={Object.keys(quizReport).length > 0 && quizReport.questions[currentQuestionIndex]}
                                            isClickedControlButton={isClickedControlButton}
                                            action={() => handleAnswerButton(item)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            {(Object.keys(quizReport).length > 0) && (
                                <button
                                    type="button"
                                    className="button bg-primary text-white hover:bg-secondary my-5 max-w-[330px]"
                                    onClick={handleQuizButton}
                                >
                                    {(userAnswer === -1 && !isClickedControlButton) && strings.speaking_lesson.quiz.leave_empty}
                                    {(userAnswer !== -1 && !isClickedControlButton) && strings.speaking_lesson.quiz.control}
                                    {(isClickedControlButton) && strings.speaking_lesson.quiz.continue}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                showCancelButton={false}
                title={strings.auth.information_messages.modal_title}
                message={strings.speaking_lesson.quiz.timer_end_description}
                overlayRef={modalOverlayRef}
                buttonText={strings.speaking_lesson.quiz.show_report}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                    setShowQuizReport(showQuizReport => true);
                }}
            />
            <ModalTwoButtons
                ref={quitModalRef}
                overlayRef={modalOverlayRef}
                showCancelButton={false}
                title={strings.modal.information_message}
                message={strings.speaking_lesson.quiz.quit_modal_text}
                buttonText1={strings.speaking_lesson.quiz.quit}
                buttonText2={strings.speaking_lesson.quiz.want_continue}
                buttonClick1={handleQuitQuiz}
                buttonClick2={() => {
                    setPauseTimer(pauseTimer => false);
                    closeModal(modalOverlayRef, quitModalRef);
                }}
            />
        </>
    )
}

export default QuizQuestions