import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {useLocalization} from "../../hooks/useLocalization";

const Pending = () => {

    const { base64 } = useParams();
    const strings = useLocalization();

    useEffect(() => {
        if(base64.length > 0) {
            window.parent.document.getElementById("secure-payment-input").value = base64;
        }
    }, [base64])

    return (
        <div className="main-content-mobile">
            {/*<Loading classnames="text-center" text={strings.market.speakingLesson.please_wait} />*/}
        </div>
    )
}

export default Pending;