import {useLocalization} from "../../hooks/useLocalization";
import {useLocation, useNavigate} from "react-router-dom";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {
    addDaysToDate,
    calcDiscount,
    closeModal,
    decodeToBase64AndUri,
    formatDateMarket,
    generateOrderNumber,
    openModal,
    parseMobileKeyToJson,
    parseSecurePaymentKeyToJson,
    validateEmail
} from "../../utils";
import useAuth from "../../hooks/useAuth";
import {url} from "../../routes/utility";
import {CampaignBar, CheckoutInfo, CheckoutInfoSticky, CreditCard, Invoice} from "./components";
import {MarketSourceTypes, MemberStatus, SalesTypes, SourceTypes} from "../../components/Constants";
import {Loading, ModalContent, ModalOneButton, ModalOverlay} from "../../components";
import {
    AddStudentInformation,
    AddTerm,
    PayPackageBy3DCreditCard,
    PaySpeakingLessonPackage
} from "../../services/Market/MarketService";
import useToken from "../../hooks/useToken";
import {useSelector} from "react-redux";
import {BuyPostponePackage} from "../../services/Market/MarketPostponeService";
import {GetMemberName} from "../../services/Dashboard/DashboardService";
import {useLayout} from "../../store/LayoutProvider";
import useWindowSize from "../../hooks/useWindowSize";

const Checkout = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const location = useLocation();
    const tokenHook = useToken();
    const { member } = useAuth();
    const { setCurrentPage } = useLayout();
    const { width} = useWindowSize();

    // 3D değişikliklerini dinlemek için tutulan ref
    const hiddenInputRef = useRef();
    const modalRef = useRef();
    const modalContentRef = useRef();
    const modalOverlayRef = useRef();

    const isMobile = location.pathname.includes("mobile_market_checkout");
    const memberInfo = sessionStorage.getItem("m") ? (parseMobileKeyToJson(sessionStorage.getItem("m"))) : member;
    const packageInfo = sessionStorage.getItem("p") ? decodeToBase64AndUri(sessionStorage.getItem("p")) : false;
    const selectedTermId = useSelector(state => state.termStore.termId) || false;
    let iframeContent = document.getElementById('secure-payment-iframe');
    const hourlyDiscountRender = useSelector(state => state.componentRenderStore.Market.HourlyDiscount);
    const opportunityPackageCategoryId = 10;

    // Form servise gönderildikten sonra bekleme durumunu kontrol eden state
    const [loading, setLoading] = useState(false);
    // 3d ödemeler için kullanılan, 3d servislerinden cevap geldiğinde tetiklenen loading
    const [securePaymentLoading, setSecurePaymentLoading] = useState(false);
    // Kredi Kartı bilgilerinin tutulduğu state
    const [creditCardInfo, setCreditCardInfo] = useState({
        cardName: "",
        cardNumber: "",
        cvv: "",
        cardMonth: { id: -1, name: strings.market.checkout.month },
        cardYear: { id: -1, name: strings.market.checkout.year },
        bank: { id: -1, name: strings.market.checkout.choose_bank },
        priceInfo: {},
        isForeignCard: false,
        isContractApproved: false
    });
    const [invoiceInfo, setInvoiceInfo] = useState({
        name: "",
        email: "",
        taxNumber: "",
        taxOffice: "",
        address: "",
        memberId: memberInfo?.MemberId,
        isCorporate: false
    });
    const [memberName, setMemberName] = useState("");
    // Ödeme modeline gönderilen, toplam indirim oranını tutan state
    const [totalDiscountRate, setTotalDiscountRate] = useState(0);
    // Toplam indirim düşülmüş KDV'siz fiyatın tutulduğu state
    const [discountPrice, setDiscountPrice] = useState(0);
    // KDV tutarının indirim eklenmiş hali ile tutulduğu state
    const [taxPrice, setTaxPrice] = useState(0);
    // Ödeme durumunun başarılı olup/olmadığını kontrol eden state
    const [successfulPayment, setSuccessfulPayment] = useState(false);
    // Ödeme sırasında herhangi bir validasyon hatası var ise, bu hataları tutan state
    const [errors, setErrors] = useState([]);
    // 3D modalının render olmasını önlemek için kullanılan state
    const [show3dModal, setShow3dModal] = useState(false);
    // 3D modalı açıkken arkada çalışan counter state'i sürekli gizli input'un kontrol edilmesini tutan state
    const [counter, setCounter] = useState(0);
    // 3D ödeme bilgilerinin olduğu base64 stringi tutan state
    const [securePaymentResult, setSecurePaymentResult] = useState("");
    // 3D ödeme order numarasının tutulduğu state
    const [securePaymentOrderNumber, setSecurePaymentOrderNumber] = useState("");
    // 3D ödeme linkini tutan state
    const [securePaymentBase64Content, setSecurePaymentBase64Content] = useState("");
    // Mobil görünümde açılan bottom slider'ın açık/kapalı olma durumu set ediliyor
    const [openBottomSheet, setOpenBottomSheet] = useState(false);

    // Kredi kartı bilgilerini güncellemek için kullanılan fonksiyon
    const changeCreditCardInfo = (values) => {
        setCreditCardInfo({ ...creditCardInfo, ...values });
    }

    // Fatura bilgilerini tutan state'i güncellemek için kullanılan fonksiyon
    const changeInvoiceInfo = (values) => {
        setInvoiceInfo({ ...invoiceInfo, ...values });
    }

    // Modalın kapanma durumunu kontrol eden fonksiyon
    const handleCloseModal = () => {
        closeModal(modalOverlayRef, modalRef);
        setErrors([]);
    }

    // Kredi kartı bilgilerini validate eden fonksiyon
    const validateCreditCardAndInvoiceForm = () => {
        let valid = true;

        //check form elements
        if (creditCardInfo.cardNumber.length < 15) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_number]);
        }

        if (creditCardInfo.cardName.length < 4) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_name]);
        }

        if (creditCardInfo.cardMonth.id === -1) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_month]);
        }

        if (creditCardInfo.cardYear.id === -1) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_year]);
        }

        if (creditCardInfo.cvv.length < 3) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_cvv]);
        }

        if(creditCardInfo?.bank.id !== -1) {
            if (!creditCardInfo.isContractApproved) {
                valid = false;
                setErrors(error => [...error, strings.market.checkout.errors.preliminary_information_form_error])
            }
        }

        if (invoiceInfo.name.length < 4) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.name]);
        }

        if (invoiceInfo.email.length < 4 || !validateEmail(invoiceInfo.email)) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.email]);
        }

        if (invoiceInfo.isCorporate) {
            if (invoiceInfo.taxOffice.length < 4) {
                valid = false;
                setErrors(error => [...error, strings.market.information.errors.tax_office]);
            }
        }

        if(invoiceInfo.isCorporate && (invoiceInfo.taxNumber.length < 9 || invoiceInfo.taxNumber.length > 11)) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.tax_number]);
        }

        if (!invoiceInfo.isCorporate && invoiceInfo.taxNumber.length !== 11) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.identity_number]);
        }

        if (invoiceInfo.address.length < 4) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.address]);
        }

        return valid;
    }

    // Dönem oluşturma için model oluşturan fonksiyon.
    const createTermInfo = (orderId) => {
        const todayDay = new Date().getDay();
        let today = new Date();

        //sunday, monday, tuesday
        let days = 3;

        //wednesday && todayDay
        if (todayDay === 3 || todayDay === 4) days = 5;
        //friday && saturday
        if (todayDay === 5 || todayDay === 6) days = 4;

        let startDate = formatDateMarket(addDaysToDate(today, days));

        return {
            beginDate: startDate,
            status: 1,
            orderId: orderId,
            memberId: memberInfo?.MemberId,
            createUserId: memberInfo?.MemberId,
            productOptions: {
                "categoryId": packageInfo?.selectedProduct?.categoryId,
                "minute": packageInfo?.selectedProduct?.minute,
                "monthId": packageInfo?.selectedProduct?.monthId,
                "dayCount": packageInfo?.selectedProduct?.dayCount
            },
            memberStatus: +memberInfo?.Status,
            salesType: +memberInfo?.Status === MemberStatus.Candidate ? SalesTypes.FirstSale : SalesTypes.ExtraPackage
        }
    }

    // Ortak kredi kartı modeli oluşturuluyor
    const createCreditCardModel = () => {
        return {
            number: creditCardInfo.cardNumber,
            name: creditCardInfo.cardName,
            month: creditCardInfo.cardMonth.name,
            year: creditCardInfo.cardYear.name.slice(2),
            cvc: creditCardInfo.cvv,
            cardType: "credit",
            isSecurity: false //Default
        }
    }

    // Order için model oluşturan servis
    const createPayPackageModel = () => {
        // Uygulanan kampanyalardan sadece kampanya id'leri filtreleniyor
        const campaignIds = packageInfo?.discountInformation?.discounts?.map(d => d.campaignId) || [];

        // Kredi kartı kısmından herhangi bir indirim uygulanmış ise, bu indirim id'si de kampanya id'lerina atılıyor.
        if(creditCardInfo?.priceInfo?.discountDetails?.length > 0) {
            campaignIds.push(creditCardInfo.priceInfo.discountDetails[0]?.campaignId);
        }

        // Satış tipi belirleniyor
        const salesType= +memberInfo?.Status === MemberStatus.Candidate
            ? SalesTypes.FirstSale
            : SalesTypes.ExtraPackage

        return {
            brandId: +process.env.REACT_APP_BRAND_ID,
            memberId: memberInfo?.MemberId,
            salesType: salesType,
            productId: packageInfo?.selectedProduct?.productId,
            price: packageInfo?.selectedProduct?.productDetail?.priceDetail.priceWithoutFormat,
            // İndirim var ise indirim uygulanmış KDV'siz fiyat, yok ise indirimsiz KDV'siz fiyat gönderiliyor
            discountPrice: totalDiscountRate === 0
                ? packageInfo?.selectedProduct?.productDetail?.priceDetail.discountPriceWithoutFormat
                : discountPrice,
            discountRate: totalDiscountRate,
            bankPosId: creditCardInfo.bank?.id,
            createUser: memberInfo?.MemberId,
            installment: creditCardInfo.priceInfo?.installmentForService,
            taxValue: packageInfo?.selectedProduct?.productDetail?.priceDetail?.taxRate,
            sourceType: isMobile ? SourceTypes.Mobile : SourceTypes.Web,
            // İndirim var ise indirim uygulanmış KDV tutarı, yok ise indirimsiz KDV fiyatı gönderiliyor
            taxPrice: totalDiscountRate === 0
                ? packageInfo?.selectedProduct?.productDetail?.priceDetail.taxPriceWithoutFormat
                : taxPrice,
            totalPrice: creditCardInfo?.priceInfo?.priceWithoutFormat,
            campaignIdList: campaignIds,
            isRecurringPayment: false, //Default
            isForeignCard: creditCardInfo?.isForeignCard
        };
    }

    /*
        * Pay package endpointine gitmek için kontrol yapan fonksiyon
        * Eğer "Ziraat Bankası" ve taksitli bir işlemse 3D işlemlere yönlendiriyoruz.
        * Değilse normal ödeme işlemlerinden devam ediyoruz.
    */
    const handlePayPackage = () => {
        if(creditCardInfo.bank?.name === "Ziraat Bankası" && creditCardInfo.priceInfo?.installment > 1) {
            // Order Id oluşturuluyor ve bir state'e kaydediliyor
            const tempMemberName = memberName !== ""
                ? memberName
                : creditCardInfo?.cardName;
            const tempOrderNumber = generateOrderNumber(tempMemberName);
            setSecurePaymentOrderNumber(tempOrderNumber);

            // Ödeme linki için base64 content oluşturuluyor
            const tempBase64Object = {
                amount: creditCardInfo?.priceInfo?.priceWithoutFormat,
                installment: creditCardInfo.priceInfo?.installmentForService,
                cardNumber: creditCardInfo.cardNumber,
                cardMonth: creditCardInfo.cardMonth.name,
                cardYear: creditCardInfo.cardYear.name,
                cardCvc: creditCardInfo.cvv,
                orderId: tempOrderNumber
            };
            const tempBase64Content = `Amount=${tempBase64Object.amount}&Installment=${tempBase64Object.installment}&CardNumber=${tempBase64Object.cardNumber}&Year=${tempBase64Object.cardYear}&Month=${tempBase64Object.cardMonth}&Cvv=${tempBase64Object.cardCvc}&Oid=${tempBase64Object.orderId}`
            setSecurePaymentBase64Content(btoa(tempBase64Content));

            openModal(modalOverlayRef, modalContentRef);
            setShow3dModal(true);
            setOpenBottomSheet(false);
        } else {
            payPackage();
        }
    }

    const handlePayment = async () => {
        if(validateCreditCardAndInvoiceForm()) {
            setLoading(true);

            await createInvoiceInfo();

            packageInfo?.source === MarketSourceTypes.SPEAKING_LESSON
                ? handlePayPackage()
                : payRight()
        } else {
            openModal(modalOverlayRef, modalRef);
            setOpenBottomSheet(false);
        }
    }

    // 3D ile ödeme yapan fonksiyon
    const payWith3d = async (paymentResult) => {

        const model = createPayPackageModel();
        model.orderNumber = securePaymentOrderNumber;
        model.creditCardDetail = createCreditCardModel();
        model.virtualPosResultModel = {
            status: paymentResult?.Response === "Approved",
            statusMessage: paymentResult?.Response === "Approved" ? "" : paymentResult?.ErrorMessage,
            authCode: paymentResult?.Response === "Approved" ? paymentResult?.AuthCode : null,
            bankErrorCode: null
        };

        const result = await PayPackageBy3DCreditCard(model);
        const createdOrderId = JSON.parse(result.content);

        let paymentStatus;
        let redirectUrl;
        let errorMessage;

        if (model.virtualPosResultModel.status) {
            paymentStatus = true;
            redirectUrl = "market.successful"
            errorMessage = "";

            setSuccessfulPayment(true);

            let termInfo = createTermInfo(createdOrderId);
            const termResult = await AddTerm(termInfo, "");

            if (termResult.statusCode !== 200) {
                errorMessage = strings.market.checkout.term_error;
            }

            await tokenHook.generateToken(memberInfo?.MemberId);

        } else {
            paymentStatus = false;
            redirectUrl = "market.failed";
            errorMessage = model.virtualPosResultModel.statusMessage;
        }

        setLoading(false);
        setSecurePaymentLoading(false);

        if (isMobile) {
            const data = {
                "payment_status": paymentStatus,
                "day_count": packageInfo?.selectedProduct.dayCount,
                "error_code": errorMessage
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        } else {
            navigate(url(redirectUrl), { state: { "errorCode": errorMessage } });
        }
    };

    // Paket açma için ödemenin servise gönderildiği fonksiyon
    const payPackage = async ()  => {

        const model = createPayPackageModel();
        model.creditCardDetail = createCreditCardModel();

        const result = await PaySpeakingLessonPackage(model, "");

        let paymentStatus;
        let redirectUrl;
        let errorCode;

        if (result.statusCode === 200) {
            paymentStatus = true;
            redirectUrl = "market.successful"
            errorCode = "";

            let orderId = result.message.replace(/"/g, "");
            setSuccessfulPayment(successfulPayment => true);

            let termInfo = createTermInfo(orderId);

            const termResult = await AddTerm(termInfo, "");

            if (termResult.statusCode !== 200) {
                errorCode = strings.market.checkout.term_error;
            }

            await tokenHook.generateToken(memberInfo?.MemberId);

        } else {
            paymentStatus = false;
            redirectUrl = "market.failed";
            errorCode = result.message.replace(/"/g, "");
        }

        setLoading(false);

        if (isMobile) {
            const data = {
                "payment_status": paymentStatus,
                "day_count": packageInfo?.selectedProduct.dayCount,
                "error_code": errorCode
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        } else {
            navigate(url(redirectUrl), { state: { "errorCode": errorCode } });
        }
    }

    // Ek hak satın alma servisi
    const payRight = async () => {

        let model = {
            addedPostponeRightCount: packageInfo?.selectedProduct?.rightCount,
            sourceType: isMobile ? SourceTypes.Mobile : SourceTypes.Web,
            userType: 0, //member
            createUserId: memberInfo?.MemberId,
            payPackage: {
                brandId: parseInt(process.env.REACT_APP_BRAND_ID),
                memberId: memberInfo?.MemberId,
                termId: selectedTermId,
                salesType: SalesTypes.AdditionalRight, //6
                productId: packageInfo?.selectedProduct?.additionalProductPriceDetails?.productId,
                price: packageInfo?.selectedProduct?.additionalProductPriceDetails?.priceWithoutFormat,
                discountPrice: packageInfo?.selectedProduct?.additionalProductPriceDetails?.discountPriceWithoutFormat,
                discountRate: packageInfo?.selectedProduct?.additionalProductPriceDetails?.discountRate,
                bankPosId: 5, //Default İş Bankası
                createUser: memberInfo?.MemberId,
                installment: 0, //Default One Shot
                taxValue: packageInfo?.selectedProduct?.additionalProductPriceDetails?.taxRate,
                sourceType: isMobile ? SourceTypes.Mobile : SourceTypes.Web,
                taxPrice: packageInfo?.selectedProduct?.additionalProductPriceDetails?.taxPriceWithoutFormat,
                totalPrice: packageInfo?.selectedProduct?.additionalProductPriceDetails?.totalPriceWithoutFormat,
                campaignIdList: [],
                isRecurringPayment: false,
                isForeignCard: false
            }
        }

        model.payPackage.creditCardDetail = createCreditCardModel();
        const result = await BuyPostponePackage(model);

        let paymentStatus;
        let redirectUrl;
        let errorCode;

        if (result.status === 200) {
            paymentStatus = true;
            redirectUrl = "market.successful"
            errorCode = "";
            setSuccessfulPayment(successfulPayment => true);

            await tokenHook.generateToken(memberInfo?.MemberId);
        } else {
            paymentStatus = false;
            redirectUrl = "market.failed";
            errorCode = result.content.replace(/"/g, "");
        }

        setLoading(false);
        //if request comes from mobile app
        if (isMobile) {
            const data = {
                "payment_status": paymentStatus,
                "error_code": errorCode
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        } else {
            navigate(url(redirectUrl), { state: { "source": MarketSourceTypes.POSTPONE_RIGHT, "errorCode": errorCode } });
        }
    }

    const createInvoiceInfo = async () => {
        const model = {
            name: invoiceInfo.name,
            email: invoiceInfo.email,
            taxNumber: invoiceInfo.taxNumber,
            address: invoiceInfo.address,
            memberId: memberInfo?.MemberId
        }

        // Kurumsal ise taxOffice bilgisi modele append ediliyor.
        if (invoiceInfo.isCorporate) {
            model.taxOffice = invoiceInfo.taxOffice;
        }

        await AddStudentInformation(model, "");
    }

    // Sayfaya route üzerinden ulaşılmaya çalışılırsa (Paket Seçim ekranından gelinmemiş ise) dashboard'a gönderiliyor.
    useEffect(() => {
        if(!packageInfo) {
            navigate(url("dashboard"));
        }
    }, [])

    // Ödeme servisine gönderilecek toplam indirim
    useEffect(() => {
        let tempTotalDiscountRate = packageInfo?.discountInformation?.totalDiscount;

        if(creditCardInfo?.priceInfo?.discountDetails?.length > 0) {
            const creditCardDiscountRate = creditCardInfo.priceInfo.discountDetails[0]?.discountRate;
            tempTotalDiscountRate += creditCardDiscountRate;
        }

        setTotalDiscountRate(totalDiscountRate => tempTotalDiscountRate);
    }, [creditCardInfo])

    // KDV'siz fiyat ve KDV tutarı hesaplanıyor
    useEffect(() => {
        if(totalDiscountRate > 0) {
            let discountPriceWitDiscount = packageInfo?.selectedProduct?.productDetail?.priceDetail.discountPriceWithoutFormat;
            let taxPriceWithDiscount = packageInfo?.selectedProduct?.productDetail?.priceDetail.taxPriceWithoutFormat;

            discountPriceWitDiscount = calcDiscount(discountPriceWitDiscount, (100 - totalDiscountRate) / 100);
            taxPriceWithDiscount = calcDiscount(taxPriceWithDiscount, (100 - totalDiscountRate) / 100);

            setDiscountPrice(discountPrice => discountPriceWitDiscount);
            setTaxPrice(taxPrice => taxPriceWithDiscount);
        }
    }, [totalDiscountRate])

    /* 3D modalı açıldığında yapılan işlemler */

    // 3D modalı açıldığında counter çalışmaya başlar.
    useEffect(() => {
        if(show3dModal) {
            const interval = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
            }, 500);

            return () => clearInterval(interval);
        }
    }, [show3dModal]);

    // Counter'a göre gizli input kontrol edilir
    useEffect(() => {
        if(hiddenInputRef?.current?.value?.length > 0) {
            // Modal kapatılır
            closeModal(modalOverlayRef, modalContentRef);
            setShow3dModal(false);

            // Gizli input bir state'e atılıyor ve boşaltılıyor.
            setSecurePaymentResult(hiddenInputRef.current.value);
            hiddenInputRef.current.value = "";

            // Sayfa loading'e sokuluyor
            setSecurePaymentLoading(true);

            // Sayfa yukarı scroll ettiriliyor
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [counter]);

    useEffect(() => {
        if(securePaymentLoading && securePaymentResult.length > 0) {
            const decodedSecurePaymentResult = parseSecurePaymentKeyToJson(securePaymentResult);
            //Order numarası oluşturma işlemi bittikten sonra ödeme 3d order oluşumu için servise gidiliyor
            payWith3d(decodedSecurePaymentResult)
                .then()
                .catch();
        }
    }, [securePaymentLoading, securePaymentResult]);
    /* 3D modalı açıldığında yapılan işlemler */

    useEffect(() => {
        if(isMobile) {
            const page = {
                "second_page": true
            }
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(page));

            GetMemberName(memberInfo?.MemberId)
                .then(result => {
                    if(result.status === 200) {
                        setMemberName(result.content);
                    } else {
                        setMemberName('');
                    }
                })
                .catch()
        } else {
            setMemberName(member?.FullName);
        }
    }, [])

    // Web görünümünde sidebar kısmında ödeme bilgilerinin yazdığı bölüm set ediliyor
    useEffect(() => {
        if(width >= 768 && !isMobile)
            setCurrentPage(
                <CheckoutInfo
                    changeCreditCardInfo={changeCreditCardInfo}
                    creditCardInfo={creditCardInfo}
                    packageInfo={packageInfo}
                    handlePayment={handlePayment}
                    successfulPayment={successfulPayment}
                    buttonLoading={loading}
                />
            );
    }, [creditCardInfo, invoiceInfo, width, loading, successfulPayment]);

    // Kullanıcı sayfayı yenilemek istediğinde önüne uyarı çıkaracak kod.
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            try {
                if (/www\/(signin|signout)/i.test(window.location.href)) {
                    return;
                } else {
                    e.preventDefault();
                    e.returnValue = 'reload / leave site?';
                }
            } catch (error) {
                console.error(error);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <div className={classNames("", {
                "main-content-mobile": isMobile,
                "main-content": !isMobile
            })}>
                {securePaymentLoading &&
                    <Loading classnames="text-center" text={strings.market.speakingLesson.please_wait} />
                }

                {!securePaymentLoading &&
                    <>
                        <input
                            id="secure-payment-input"
                            ref={hiddenInputRef}
                            className="hidden"
                        />

                        {(hourlyDiscountRender.visibility && opportunityPackageCategoryId !== packageInfo?.selectedProduct?.categoryId) &&
                            <CampaignBar
                                classnames="md:w-[495px] mx-auto"
                            />
                        }

                        <CreditCard
                            packageInfo={packageInfo}
                            memberInfo={memberInfo}
                            creditCardInfo={creditCardInfo}
                            changeCreditCardInfo={changeCreditCardInfo}
                        />

                        <Invoice
                            packageInfo={packageInfo}
                            memberInfo={memberInfo}
                            changeInvoiceInfo={changeInvoiceInfo}
                            invoiceInfo={invoiceInfo}
                        />

                        {width < 768 &&
                            <CheckoutInfoSticky
                                changeCreditCardInfo={changeCreditCardInfo}
                                creditCardInfo={creditCardInfo}
                                packageInfo={packageInfo}
                                handlePayment={handlePayment}
                                successfulPayment={successfulPayment}
                                buttonLoading={loading}
                                openBottomSheet={openBottomSheet}
                                setOpenBottomSheet={setOpenBottomSheet}
                            />
                        }
                    </>
                }

                <div className={classNames("left-margin-bottom", {
                        "!h-[300px]" : width < 768
                    })}
                ></div>
            </div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.market.speakingLesson.information_messages.modal_title}
                message={errors.join("")}
                buttonText={strings.market.okay_button}
                buttonClick={handleCloseModal}
                closeClick={() => setErrors(error => [])}
            />
            <ModalContent
                ref={modalContentRef}
                overlayRef={modalOverlayRef}
                closeAction={() => setShow3dModal(false)}
                show={show3dModal}
                showCloseButton={iframeContent?.src.includes("/ziraat/verify.php") ? false : isMobile}
            >
                <div className="py-5 text-[14px] flex items-center justify-center h-full">
                    <iframe
                        id="secure-payment-iframe"
                        src={`${process.env.REACT_APP_SECURE_PAYMENT_URL}${securePaymentBase64Content}`}
                        className="w-full h-full"
                    />
                </div>
            </ModalContent>
        </>
);
}

export default Checkout;