import {useState} from "react";
import {FollowMember, UnfollowMember} from "../services/Profile/ProfileService";
import useAnalytics from "./useAnalytics";
import {EventLogs} from "../components/Constants";

const useFollowUnfollow = () => {

    const analytics = useAnalytics();

    const [followLoading, setFollowLoading] = useState(false);
    const [unfollowLoading, setUnfollowLoading] = useState(false);

    const follow = (followerMemberId, followingMemberId, func) => {
        setFollowLoading(true);
        analytics.useAnalytics(EventLogs.FOLLOW_CLICKED);

        FollowMember(followerMemberId, followingMemberId)
            .then(result => {
                if (result.status === 200)
                    func(followingMemberId);

                setFollowLoading(false);
            })
            .catch()
    }

    const unfollow = (followerMemberId, followingMemberId, successFunc, errorFunc) => {
        setUnfollowLoading(true);
        analytics.useAnalytics(EventLogs.UNFOLLOW_CLICKED, undefined, false, true);

        UnfollowMember(followerMemberId, followingMemberId)
           .then(result => {
               if (result.status === 200)
                   successFunc();
               else
                   errorFunc();

               setUnfollowLoading(false);
           })
           .catch()
    }

    return { follow, followLoading, unfollow, unfollowLoading }
}

export default useFollowUnfollow;