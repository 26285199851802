import React, { useEffect } from 'react'
import useAnalytics from '../../../../hooks/useAnalytics';
import { useLocalization } from '../../../../hooks/useLocalization';

const Frozen = ({ data }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const nextLessonDate = data ? data['nextLessonDate'] : "";

    useEffect(() => {
        analytics.useAnalytics('screen_view', { screen_name: 'Gunun_Dersi_Dondurulmus_Ders' });
        window.customDataLayer.push({ event: 'screen_view', screen_name: 'Gunun_Dersi_Dondurulmus_Ders' });
    },[]);

    return (
        <>

            <div className="gray-section-bottom">

                <div className="flex flex-col justify-center text-sm mt-4 mb-4 mx-auto max-w-lg">
                        <p className="mb-5">
                            {strings.speaking_lesson.components.frozen.content_1}
                        </p>
                        <p>
                            {strings.speaking_lesson.components.frozen.content_2.replace('#date#', nextLessonDate)}
                        </p>
                </div>

            </div>

        </>
    )
}

export default Frozen