import {useLocalization} from "../../../../hooks/useLocalization";
import {Card_Name, Card_Number} from "../../../../assets/svg";
import React, {useEffect, useState} from "react";
import {checkNumber, formatCreditCardNumber, getCardMonths, getCardYears} from "../../../../utils";
import {Dropdown} from "../../../../components";
import {MarketSourceTypes} from "../../../../components/Constants";
import {GetBankPosses} from "../../../../services/Market/MarketService";
import {Contract, Installments} from "../index";
import BinChecker from "./BinChecker";

const CreditCard = (props) => {

    const { packageInfo, memberInfo, creditCardInfo, changeCreditCardInfo } = props;

    const strings = useLocalization();

    // Kart son kullanma ayının tutulduğu state
    const firstMonth = { id: -1, name: strings.market.checkout.month };
    const [cardMonth, setCardMonth] = useState(firstMonth);
    // Kart son kullanma yılının tutulduğu state
    const firstYear = { id: -1, name: strings.market.checkout.year };
    const [cardYear, setCardYear] = useState(firstYear);

    // Banka işlemlerini yapıldığı stateler
    const [banks, setBanks] = useState([]);
    const firstBank = { id: -1, name: strings.market.checkout.choose_bank };
    const [selectedBank, setSelectedBank] = useState(firstBank);

    const [isOneShot, setIsOneShot] = useState(false);

    // Banka bilgileri getiriliyor
    useEffect(() => {
        GetBankPosses()
            .then(result => {
                if(result.statusCode === 200) {
                    let resultContent = JSON.parse(result.message);
                    let tempBankList = [];

                    resultContent.map((item) => {
                        tempBankList.push({
                            id: item.bankPosId,
                            name: item.bankName,
                            bankId: item.bankId,
                            brandLocalCompanyId: item.brandLocalCompanyId
                        });
                    })

                    setBanks(banks => [firstBank, ...tempBankList]);
                }
            })
            .catch(e => {})
    }, []);

    // Seçili ay değiştikçe üst componentteki kredi kartı bilgileri güncelleniyor
    useEffect(() => {
        changeCreditCardInfo({ cardMonth: cardMonth });
    }, [cardMonth])

    // Seçili yıl değiştikçe üst componentteki kredi kartı bilgileri güncelleniyor
    useEffect(() => {
        changeCreditCardInfo({ cardYear: cardYear });
    }, [cardYear])

    // Seçili banka değiştikçe üst componentteki kredi kartı bilgileri güncelleniyor
    useEffect(() => {
        changeCreditCardInfo({ bank: selectedBank });
    }, [selectedBank]);

    return (
        <div className="px-5 md:px-0 w-full md:w-[495px] mx-auto text-sm sm:text-base mt-5">
            <div className="font-bold w-full relative pb-2 text-sm mt-4 sm:mt-0">
                {strings.market.checkout.card_informations}
                <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
            </div>

            {/* Kart Adı */}
            <div className="flex flex-col mt-2">
                <label className="block text-start" htmlFor="card-name">
                    {strings.market.checkout.card_name_surname}
                </label>
                <div className="w-full relative group">
                    <input
                        id="card-name"
                        type="text"
                        className="market-input"
                        onChange={(e) => changeCreditCardInfo({cardName: e.target.value})}
                    />
                    <div className="market-input-left">
                        <img src={Card_Name} width="20" height="20" alt="card-name"/>
                    </div>
                </div>
            </div>

            {/* Paket satın alma kısmında bin checker olan input gösteriliyor */}
            {MarketSourceTypes.SPEAKING_LESSON === packageInfo?.source &&
                <BinChecker
                    creditCardInfo={creditCardInfo}
                    changeCreditCardInfo={changeCreditCardInfo}
                    setSelectedBank={setSelectedBank}
                    bankList={banks}
                    setIsOneShot={setIsOneShot}
                />
            }

            {/* Ek hak satın alma kısmında bin checker olan input gösteriliyor */}
            {MarketSourceTypes.POSTPONE_RIGHT === packageInfo?.source &&
                <div className="flex flex-col mt-2">
                    <label className="block text-start" htmlFor="card-number">{strings.market.checkout.card_number}</label>
                    <div className="w-full relative group">
                        <input
                            id="card-number"
                            type="text"
                            className="market-input"
                            placeholder="---- ---- ---- ----" maxLength="19"
                            value={formatCreditCardNumber(creditCardInfo.cardNumber)}
                            onKeyDown={(e) => !checkNumber(e)}
                            onChange={(e) => changeCreditCardInfo({cardNumber: e.target.value})}
                        />
                        <div className="market-input-left">
                            <img src={Card_Number} width="20" height="20" alt="card-number"/>
                        </div>
                    </div>
                </div>
            }

            {/* Kart Son Kullanma Tarihi */}
            <div className="flex gap-3 justify-between items-center w-full">
                <div className="flex flex-col mt-2">
                    <label className="block text-start">
                        {strings.market.checkout.card_exp_date}
                    </label>
                    <div className="w-full">
                        <div className="w-full flex justify-between items-center relative">
                            <Dropdown
                                data={[firstMonth, ...getCardMonths()]}
                                selected={cardMonth}
                                setSelected={setCardMonth}
                                classnames="min-w-[95px]"
                                innerClassnames="h-[40px] focus:border-primary"
                            />
                            <p className="px-1 text-center text-xl">/</p>
                            <Dropdown
                                data={[firstYear, ...getCardYears()]}
                                selected={cardYear}
                                setSelected={setCardYear}
                                classnames="min-w-[95px]"
                                innerClassnames="h-[40px] focus:border-primary"
                            />
                        </div>
                    </div>
                </div>
                {/* CVV */}
                <div className="flex flex-col mt-1.5 items-end">
                    <label className="flex w-full sm:w-[calc(50%-10px)]" htmlFor="card-cvv">
                        <p className="mr-1">CVV</p>
                        <button type="button" className="group relative transition duration-150 ease-in-out has-tooltip">
                            <span className="material-symbols-outlined text-[20px]">info</span>
                            <div className="tooltip-top -top-[55px]">
                                {strings.market.checkout.cvv}
                            </div>
                        </button>
                    </label>
                    <input
                        id="card-cvv"
                        type="text"
                        inputMode="numeric"
                        className="market-input w-full sm:w-[calc(50%-10px)] pl-4"
                        maxLength="4"
                        onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                        onChange={(e) => changeCreditCardInfo({cvv: e.target.value})}
                    />
                </div>
            </div>

            {/* Taksitler */}
            {MarketSourceTypes.SPEAKING_LESSON === packageInfo?.source && (
                <>
                    {selectedBank.id !== -1 &&
                        <Installments
                            packageInfo={packageInfo}
                            changeCreditCardInfo={changeCreditCardInfo}
                            bankPosId={selectedBank?.id}
                            isOneShot={isOneShot}
                        />
                    }
                </>
            )}

            {/* Sözleşme */}
            {creditCardInfo.bank.id !== -1 &&
                <Contract
                    packageInfo={packageInfo}
                    memberInfo={memberInfo}
                    priceInfo={creditCardInfo.priceInfo}
                    bankInfo={creditCardInfo.bank}
                />
            }
        </div>
    )
}

export default CreditCard;