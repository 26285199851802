import { Outlet } from "react-router-dom"
import { Menu, SideBar } from "../../components"
import { useLocalization } from "../../hooks/useLocalization"

const SupportLayout = () => {

    const strings = useLocalization();

    return (
        <>
            <Menu title={strings.support.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
        </>
    )
}

export default SupportLayout