import {useEffect, useState} from "react";
import {GetAiUseCaseIdByKey} from "../services/AIUseCase/AIUseCaseService";

const useAiRouting = (key) => {

    const [aiUseCaseId, setAiUseCaseId] = useState(-1);

    useEffect(() => {
        GetAiUseCaseIdByKey(key)
            .then(result => {
                if(result.status === 200){
                    setAiUseCaseId(aiUseCaseId => result.content);
                }
            })
            .catch(r => {})
    }, [])

    return aiUseCaseId;
}

export default useAiRouting;