import {useLocalization} from "../../../../hooks/useLocalization";
import {getTimeZone, ISOStringToDate} from "../../../../utils";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateRenderStatus} from "../../../../store/ComponentRenderSlice";
import {ComponentRenderGroups, ComponentRenderKeys} from "../../../../components/Constants";
import classNames from "classnames";

const CampaignBar = (props) => {

    const { classnames = "" } = props;

    const strings = useLocalization();
    const dispatch = useDispatch();
    const hourlyDiscountRender = useSelector(state => state.componentRenderStore.Market.HourlyDiscount);
    const timeZone = getTimeZone();

    const [isTimerEnd, setIsTimerEnd] = useState(false);

    const calculateTimeLeft = () => {
        const removeZFromDate = hourlyDiscountRender?.endDate.replace("Z", "");
        const difference = new Date(ISOStringToDate(removeZFromDate, timeZone)) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft() || {});

    useEffect(() => {
        if(!isTimerEnd) {
            const timer = setInterval(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000);

            return () => clearInterval(timer);
        } else {
            setTimeLeft({});
            dispatch(updateRenderStatus({
                group: ComponentRenderGroups.MARKET,
                key: ComponentRenderKeys.HOURLY_DISCOUNT,
                value: {
                    visibility: false,
                    endDate: null,
                    campaignDuration: null,
                    resetList: true
                }
            }));
        }
    }, [isTimerEnd]);

    useEffect(() => {
        if(Object.keys(timeLeft)?.length === 0 && !isTimerEnd) {
            setIsTimerEnd(true);
        }
    }, [timeLeft, isTimerEnd])

    return (
        <>
            {Object.keys(timeLeft)?.length > 0 &&
                <div
                    className={classNames("rounded-[10px] bg-box p-3 mt-3 border border-onboarding-border", {
                        [classnames] : [!!classnames]
                    })}
                >
                    <div className="flex justify-between items-center max-w-lg mx-auto">
                        <div className="flex flex-col justify-center items-center gap-2.5">
                            <div className="flex gap-1 justify-center">
                                {timeLeft.days !== 0 &&
                                    <div
                                        className="flex flex-col items-center justify-center bg-secondary w-10 h-10 rounded-lg text-[18px] text-white">
                                        <p className="font-bold">{timeLeft.days}</p>
                                        <p className="text-[11px] -mt-2">{strings.dashboard.student.day}</p>
                                    </div>
                                }
                                {timeLeft.hours !== 0 &&
                                    <div
                                        className="flex flex-col items-center justify-center bg-secondary text-white w-10 h-10 rounded-lg text-[18px]">
                                        <p className="font-bold">{timeLeft.hours}</p>
                                        <p className="text-[11px] -mt-2">{strings.dashboard.student.hour}</p>
                                    </div>
                                }
                                <div
                                    className="flex flex-col items-center justify-center bg-secondary text-white w-10 h-10 rounded-lg text-[18px]">
                                    <p className="font-bold">{timeLeft.minutes}</p>
                                    <p className="text-[11px] -mt-2">{strings.dashboard.student.minute}</p>
                                </div>
                                <div
                                    className="flex flex-col items-center justify-center bg-secondary text-white w-10 h-10 rounded-lg text-[18px]">
                                    <p className="font-bold">{timeLeft.seconds}</p>
                                    <p className="text-[11px] -mt-2">{strings.dashboard.student.second}</p>
                                </div>
                            </div>
                            <div className="">
                                <p className="font-bold text-center text-[14px] md:text-[16px]">{strings.market.campaign_bar.title}</p>
                                <p className="text-[12px] md:text-[14px] text-center">
                                    {strings.market.campaign_bar.description.replace("#hour#", hourlyDiscountRender.campaignDuration)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CampaignBar;