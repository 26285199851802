import {fetchApi} from '../index';

export const GetResourcesByResourceType = async (resourceType) => {

    const url = '/_services/resources/get_resources_by_resource_type/';

    const payload = {
        resourceType: resourceType
    };

    return await fetchApi(url, payload);
}

export const CreateMemberResourcesResult = async (model) => {

    const url = '/_services/resources/create_member_resources_result/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}