import {useLocalization} from "../hooks/useLocalization";
import {useNavigate} from "react-router-dom";
import {addTimeZone, closeModal, getTimeZone, openModal} from "../utils";
import React, {useEffect, useRef, useState} from "react";
import {ModalOverlay, ModalTwoButtons} from "./Modals";
import {url} from "../routes/utility";

const AssignTeacherSandyModal = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const timeZone = getTimeZone();

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [modalText, setModalText] = useState("");

    let showAssignTeacherModal = localStorage.getItem("openAssignTeacherModal") || false;
    let showAssignTeacherSandyModal = (!showAssignTeacherModal && localStorage.getItem("openAssignTeacherSandyModal")) || false;

    const removeFromLocalStorage = () => {
        localStorage.removeItem("openAssignTeacherSandyModal");
    }

    useEffect(() => {
        let json = JSON.parse(showAssignTeacherSandyModal);

        //"Açık" bilgisi geliyorsa if'e giriliyor.
        if(json && json.open) {

            //if: Eğer sadece bir term'ün ders saati değiştiyse kullanıcıye tek term değişme mesajı gösteriliyor.
            //else if: Birden fazla term'ün ders saati değiştiyse değişen tüm term'ler kullanıcıya gösterilmek için mesaja ekleniyor.
            if(json.info.length === 1){
                let teacherInfo = json.info[0].teacherName + " / " + addTimeZone(json.info[0].lessonTime, timeZone);

                setModalText(modalText =>
                    strings.support.assign_term_teacher.message_single
                        .replace("#teacherInformation#", teacherInfo)
                );
            } else if (json.info.length > 1) {

                let teacherInfo = "";
                json.info.map((item) => {
                    teacherInfo += item.productDisplayName + " / " + item.teacherName + " / " + addTimeZone(item.lessonTime, timeZone) + "\n";
                })

                let tmpModalText = strings.support.assign_term_teacher.message_multiple + "\n" + teacherInfo;
                setModalText(modalText => tmpModalText);
            }

            openModal(modalOverlayRef, modalRef);
        }
    }, [showAssignTeacherSandyModal])

    /*
        Sayfa yenilendiğinde localstorage'da bulunan package bilgileri temizleniyor.
    */
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            removeFromLocalStorage();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return(
        <>
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={modalText}
                buttonText1={strings.support.teacher_time_change_title}
                buttonClick1={() => {
                    navigate(url("support.changeteachertime"));
                    removeFromLocalStorage();
                    closeModal(modalOverlayRef, modalRef);
                }}
                buttonText2={strings.modal.okey}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                    removeFromLocalStorage();
                }}
                closeButtonClick={() => {
                    removeFromLocalStorage();
                }}
            />
        </>
    )
}

export default AssignTeacherSandyModal;