import React, {useEffect, useState} from "react";
import MultipleChoice from "./QuestionTypes/MultipleChoice";
import Pronunciation from "./QuestionTypes/Pronunciation";
import FillInTheBlank from "./QuestionTypes/FillInTheBlank";

const ExerciseQuestion = (props) => {

    const { questions, setInnerStep, setComponentStep, logs } = props;

    const [questionIndex, setQuestionIndex] = useState(0);

    const QuestionTypes = Object.freeze(
        {
            "MULTIPLE_CHOICE": 1,
            "FILL_IN_THE_BLANK": 2,
            "PRONUNCIATION": 3,
        }
    );

    const questionContent = () => {

        let selectedQuestion = questions[questionIndex];
        let questionsLength = questions.length;

        const commonProps = {
            selectedQuestion,
            questionsLength,
            questionIndex,
            setQuestionIndex,
            setInnerStep,
            setComponentStep,
            logs
        };

        switch (selectedQuestion?.questionTypeId){
            case QuestionTypes.MULTIPLE_CHOICE:
                return <MultipleChoice {...commonProps} />
            case QuestionTypes.PRONUNCIATION:
                return <Pronunciation {...commonProps} />
            case QuestionTypes.FILL_IN_THE_BLANK:
                return <FillInTheBlank {...commonProps} />
            default:
                return <></>
        }
    }

    //Eğer geri butonu ile gelinip bu component render edildi ise son sorudan başlatıyoruz.
    useEffect(() => {
        let stepObject = JSON.parse(localStorage.getItem("step")) || false;
        localStorage.removeItem("step");
        if(stepObject?.isClickedBackButton) {
            setQuestionIndex(questions.length - 1);
        }
    }, [])

    return(
        <div>
            {questionContent()}
        </div>
    )
}

export default ExerciseQuestion;