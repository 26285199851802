import useAnalytics from "./useAnalytics";
import { useDispatch } from "react-redux";
import { GenerateTokenService } from "../services/Auth/AuthService";
import { decodeToken, encodeToken } from "../utils";
import { memberLogin } from "../store/MemberSlice";
import {termStorageName} from "../components/Constants";

const useToken = () => {

    const analytics = useAnalytics();
    const dispatch = useDispatch();

    const generateToken = async memberId => {

        const result = await GenerateTokenService(memberId);

        if (result.statusCode !== 200) {
            if (result.message)
                analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
            else {
                analytics.useAnalytics('Error_Occured', {
                    error: `An unexpected error occurred.`,
                });
            }
            return;
        } else {
            const rawToken = result.message;
            const member = decodeToken(rawToken);
            localStorage.removeItem(termStorageName);

            let terms = '';

            if (member.hasOwnProperty("Terms")) {
                terms = encodeToken(member.Terms);
                delete member["Terms"];
            }

            const token = encodeToken(member);

            //update member token
            dispatch(memberLogin({ token, terms }));
        }
    }

    return { generateToken }
}

export default useToken