import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {Transcribe} from "../../../../services/AIUseCase/AIUseCasePythonService";
import {AnswerStatutes} from "../../../../components/Constants";
import {ModalOneButton, ModalOverlay} from "../../../../components";
import {closeModal} from "../../../../utils";
import classNames from "classnames";
import {AILoader} from "../../../SpeakingLesson/components";

const SpeakDialog = (props) => {

    const { questionText, setAnswerStatus, isRecording, setIsRecording } = props;
    const strings = useLocalization();

    const mediaRecorderRef = useRef(null);
    const modalOverlayRef = useRef();
    const modalRef = useRef();

    const [dynamicClassnames, setDynamicClassnames] = useState("text-base-text border-onboarding-border");
    const [transcribeLoading, setTranscribeLoading] = useState(false);
    const [userMessage, setUserMessage] = useState("");

    //Kullanıcıdan ses kaydı almak için kullanılan fonksiyon.
    const startRecording = async () => {
        let chunks = [];

        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            // To get access to the microphone
            //mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' }); // Set the mimeType to 'audio/webm'

            setAnswerStatus(AnswerStatutes.NOT_ANSWERED);
            setUserMessage(userMessage => "");

            mediaRecorderRef.current = new MediaRecorder(stream); // To create a media recorder object

            mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
                chunks.push(event.data); // To store the audio data in chunks
            });

            mediaRecorderRef.current.addEventListener('stop', async () => {
                const blob = new Blob(chunks, {type: 'audio/wav'}); // To create a blob object from the chunks
                const formData = new FormData(); // To create a form data object
                formData.append('audio', blob); // To append the blob object to the form data

                //Ses kaydı alındıktan sonra blob formatından birleştirilip formData olarak transcribe servise gönderiliyor.
                setTranscribeLoading(transcribeLoading => true);
                let tmpUserMessage = await Transcribe(formData);

                setUserMessage(userMessage => tmpUserMessage);
                setAnswerStatus(
                    modifyText(tmpUserMessage) === modifyText(questionText)
                        ? AnswerStatutes.CORRECT
                        : AnswerStatutes.WRONG
                );

                setTranscribeLoading(transcribeLoading => false);
                // Reset the chunks array
                chunks = [];
            });

            mediaRecorderRef.current.start(); // To start recording audio
            setIsRecording(true);

        } catch (error) {
            console.error('Error accessing microphone:', error); // To log the error message
        }
    }

    const modifyText = (inputString)  => {
        return inputString.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();
    }

    const stopRecording = () => {
        mediaRecorderRef.current.stop(); // To stop recording audio
        setIsRecording(isRecording => false);
    }

    const handleMicrophoneButton = () => {
        if(!isRecording) {
            startRecording();
        } else {
            stopRecording();
        }
    }

    useEffect(() => {
        let intervalId;

        if (isRecording) {
            // Her yarım saniyede bir çalışacak işlemleri tanımlayın
            intervalId = setInterval(() => {
                // Şu anki dynamicClassnames değerine göre yeni değeri belirleyin
                const newClassname =
                    dynamicClassnames === "text-green border-green"
                        ? "text-base-text border-onboarding-border"
                        : "text-green border-green";

                // Yeni değeri state'i güncellemek için setDynamicClassnames kullanın
                setDynamicClassnames(dynamicClassnames =>  newClassname);
            }, 700);

        } else {
            // isRecording false olduğunda interval'i temizleyin
            clearInterval(intervalId);
            setDynamicClassnames(dynamicClassnames => "text-base-text border-onboarding-border");
        }

        // useEffect içinde interval'i temizlemeyi unutmayın
        return () => {
            clearInterval(intervalId);
        };
    }, [isRecording, dynamicClassnames]);

    return (
        <>
            <div className='flex gap-6 max-w-max mx-auto mt-5'>
                <button
                    className={`h-10 w-10 ${dynamicClassnames} border rounded-full flex items-center justify-center transition-colors duration-300 relative group`}
                    onClick={handleMicrophoneButton}
                >
                    {transcribeLoading && <AILoader color="#F16C00" />}

                    {!transcribeLoading && (
                        <>
                            <MicrophoneIcon/>
                            <div
                                className={classNames("tooltip-top !-top-[37px]", {
                                    "!block" : isRecording
                                })}
                            >
                                {isRecording && strings.onboarding_exercises.recording}
                                {!isRecording && strings.onboarding_exercises.record_dialog}
                            </div>
                        </>
                    )}
                </button>
            </div>

            {(!transcribeLoading && userMessage.length > 0) && (
                <div className="flex flex-col mt-1">
                    <span className="font-bold">{strings.onboarding_exercises.your_answer}</span>
                    <p>{userMessage}</p>
                </div>
            )}

            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.ai_chatbot.errors.microphone_not_allowed_error}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    )
}

export default SpeakDialog;

const MicrophoneIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9648_11838)">
                <path
                    d="M20.9273 8.6707C20.5903 8.6707 20.317 8.94396 20.317 9.2811V13.095C20.317 17.1296 17.0345 20.412 12.9998 20.412C8.96509 20.412 5.68269 17.1295 5.68269 13.0949V9.28105C5.68269 8.94391 5.40934 8.67065 5.0723 8.67065C4.73517 8.67065 4.46191 8.94391 4.46191 9.28105V13.0949C4.46191 17.5975 7.96546 21.2967 12.3894 21.6107V24.7792H9.23214C8.89511 24.7792 8.62175 25.0525 8.62175 25.3896C8.62175 25.7268 8.89506 26 9.23214 26H16.7674C17.1044 26 17.3777 25.7268 17.3777 25.3896C17.3777 25.0525 17.1044 24.7792 16.7674 24.7792H13.6101V21.6107C18.0341 21.2967 21.5376 17.5974 21.5376 13.0949V9.28105C21.5377 8.94396 21.2644 8.6707 20.9273 8.6707Z"
                    fill="currentColor"/>
                <path
                    d="M13.4067 0H12.5932C9.80283 0 7.53271 2.27012 7.53271 5.06049V13.5017C7.53271 16.2921 9.80283 18.5622 12.5932 18.5622H13.4067C16.1971 18.5622 18.4672 16.2921 18.4672 13.5017V5.06049C18.4672 2.27012 16.1971 0 13.4067 0ZM17.2465 6.64487H15.0338C14.6967 6.64487 14.4235 6.91812 14.4235 7.25526C14.4235 7.59239 14.6967 7.86565 15.0338 7.86565H17.2465V9.56468H15.0338C14.6967 9.56468 14.4235 9.83793 14.4235 10.1751C14.4235 10.5122 14.6967 10.7855 15.0338 10.7855H17.2465V12.4846H15.0338C14.6967 12.4846 14.4235 12.7579 14.4235 13.095C14.4235 13.4321 14.6967 13.7054 15.0338 13.7054H17.241C17.1348 15.7283 15.4557 17.3415 13.4068 17.3415H12.5933C10.5443 17.3415 8.86521 15.7283 8.75903 13.7054H10.9662C11.3033 13.7054 11.5766 13.4321 11.5766 13.095C11.5766 12.7579 11.3033 12.4846 10.9662 12.4846H8.75354V10.7855H10.9662C11.3033 10.7855 11.5766 10.5122 11.5766 10.1751C11.5766 9.83793 11.3033 9.56468 10.9662 9.56468H8.75354V7.86565H10.9662C11.3033 7.86565 11.5766 7.59239 11.5766 7.25526C11.5766 6.91812 11.3033 6.64487 10.9662 6.64487H8.75354V5.06049C8.75354 3.53889 9.64323 2.22112 10.9297 1.60011V3.32581C10.9297 3.66295 11.203 3.9362 11.5401 3.9362C11.8772 3.9362 12.1505 3.66295 12.1505 3.32581V1.24708C12.2959 1.23028 13.7041 1.23028 13.8495 1.24708V3.32581C13.8495 3.66295 14.1228 3.9362 14.4599 3.9362C14.7969 3.9362 15.0703 3.66295 15.0703 3.32581V1.60011C16.3568 2.22112 17.2465 3.53879 17.2465 5.06044V6.64487Z"
                    fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_9648_11838">
                    <rect width="26" height="26" fill="currentColor"/>
                </clipPath>
            </defs>
        </svg>

    )
}

