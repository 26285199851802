import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {CheckPostponeAvailabilityService} from "../services/Market/MarketPostponeService";
import {TermRightTypes} from "../components/Constants";

const usePostponeButtonVisibility = () => {

    const selectedTermId = useSelector(state => state.termStore.termId) || false;
    const [buttonVisibilityStatus, setButtonVisibilityStatus] = useState(false);

    const checkPostponeAvailabilityService = async (selectedTermId) => {
        let model = {
            termRightCount: process.env.REACT_APP_BUY_POSTPONE_VISIBILITY_STATUS_RIGHT_COUNT,
            rightTypeId: TermRightTypes.LessonPostponeRight,
            termId: selectedTermId
        };

        const result = await CheckPostponeAvailabilityService(model);

        if(result.status === 200) {
            setButtonVisibilityStatus(buttonVisibilityStatus => JSON.parse(result.content));
        }
    }

    useEffect(() => {
        if(selectedTermId)
            checkPostponeAvailabilityService(selectedTermId);
    }, [selectedTermId]);

    return buttonVisibilityStatus;
}

export default usePostponeButtonVisibility