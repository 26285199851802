import React from 'react'
import { Family, Invite } from '../../assets/svg';
import { HorizontalMenuButton } from '../../components';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';

const LearnTogetherHome = () => {

    const strings = useLocalization();
    
    const menuItems = [
        {
          title: strings.learn_together.family_package,
          icon: Family,
          showLink: true,
          showOnclick: false,
          path: "learntogether.family",
          onClick: () => {}
        },
        {
          title: strings.learn_together.invite_friends,
          icon: Invite,
          showLink: true,
          showOnclick: false,
          path: "learntogether.reference",
          onClick: () => {}
        }
      ]

    return (
        <div className="main-content">
          <div className="left-page">
            <div className="onboarding-outer-div">
              {menuItems.map((item, index) => (
                <HorizontalMenuButton
                    key={index}
                    title={item.title}
                    icon={item.icon}
                    showLink={item.showLink}
                    showOnclick={item.showOnclick}
                    onclick={item.onClick}
                    link={item.path.length > 0 && url(item.path)}
                />
              ))}
            </div>  
            <div className="left-margin-bottom"></div>
          </div>
        </div>
    );
}

export default LearnTogetherHome