import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Views} from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../../assets/css/streak-calendar.css";
import {Calendar, Loading} from '../../../../components';
import {GetMemberStreakCalendar} from '../../../../services/Profile/ProfileService';
import classNames from "classnames";
import {useLocalization} from "../../../../hooks/useLocalization";

const StreakCalendar = ({ memberId }) => {

    const strings = useLocalization();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const CalendarType = Object.freeze({
        "LAST" : 0,
        "FIRST": 1,
        "BETWEEN": 2
    });

    const IconDescriptions = [
        {
            title: strings.profile.calendar.start_streak,
            icon: "play_arrow",
            className: "bg-active"
        },
        {
            title: strings.profile.calendar.active_streak,
            icon: "check",
            className: "bg-green"
        },
        {
            title: strings.profile.calendar.end_streak,
            icon: "close",
            className: "bg-red"
        }
    ];

    const eventWrapper = ({ event }) => {
        const { type, status } = event;
        let typeToClass = "";

        switch (type) {
            case CalendarType.FIRST:
                typeToClass = "bg-active rounded-l-md";
                break;
            case CalendarType.LAST:
                if (status === 1)
                    typeToClass = "bg-green rounded-r-md";
                else
                    typeToClass = "bg-red rounded-r-md";
                break;
            default:
                typeToClass = "bg-green";
                break;
        }

        return (
            <div className={`p-1 group relative  ${typeToClass}`}>
                {type === CalendarType.FIRST &&
                    <span className="material-symbols-outlined flex justify-center">
                        play_arrow
                    </span>
                }

                {(type === CalendarType.BETWEEN || (type === CalendarType.LAST && status === 1)) &&
                    <span className="material-symbols-outlined flex justify-center">
                        check
                    </span>
                }

                {(type === CalendarType.LAST && status === 0) &&
                    <span className="material-symbols-outlined flex justify-center">
                        close
                    </span>
                }
            </div>
        );
    };

    useEffect(() => {
        if(memberId)
            GetMemberStreakCalendar(memberId)
                .then(result => {
                    if (result.status === 200)
                        setData(JSON.parse(result.content));

                    setLoading(false);
                })
                .catch()
    }, []);

    return (
        <div className='mt-5 w-full'>
            <p className="text-[16px] font-bold text-primary -mb-8">{strings.profile.calendar.calendar_title}</p>

            {loading && <Loading classnames="mt-10" />}

            {!loading &&
                <>
                    <div className="h-full relative">
                        {data.length === 0 &&
                            <div className="absolute w-full h-full flex items-center justify-center">
                                <p className="font-bold bg-onboarding-bg px-4 py-2 rounded-[10px]">
                                    {strings.profile.calendar.disable_calendar_text}
                                </p>
                            </div>
                        }
                        <Calendar
                            views={[Views.MONTH]}
                            startAccessor={(data) => moment(data.startDate).toDate()}
                            endAccessor={(data) => moment(data.endDate).add(1, 'days').toDate()}
                            events={data}
                            components={{
                                event: eventWrapper
                            }}
                            className={classNames("mt-10 streak-calendar", {
                                "hide-rbc-button-group opacity-10": data.length === 0
                            })}
                        />
                    </div>
                    {data.length > 0 &&
                        <div className="flex gap-1.5 mt-3 items-center">
                            {IconDescriptions.map((item, index) => (
                                <div key={index} className="flex gap-1 items-center">
                                    <div
                                        className={classNames("md-lg:w-[24px] md-lg:h-[24px] w-[30px] h-[30px] bg-red rounded-[10px] flex justify-center items-center", {
                                            [item.className]: [!!item.className]
                                        })}
                                    >
                                        <span className="material-symbols-outlined flex justify-center text-white">
                                            {item.icon}
                                        </span>
                                    </div>
                                    <p>{item.title}</p>
                                </div>
                            ))}
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default StreakCalendar