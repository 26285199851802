export const openModal = (overlay, modal) => {
    overlay?.current?.classList?.toggle('hidden');
    modal?.current?.classList?.toggle('hidden');
}

export const closeModal = (overlay, modal) => {
    overlay.current.classList.add('hidden');
    modal.current.classList.add('hidden');
}

export const openPopup = (modal) => {
    modal?.current?.classList?.remove('scale-0');
    modal?.current?.classList?.remove('scale-100');
}

export const closePopup = (modal) => {
    modal?.current?.classList?.add('scale-0');
    modal?.current?.classList?.remove('scale-100');
}

export const openCollapse = (collapseRef, collapseOverlayRef) => {
    collapseRef.current.classList.toggle("translate-x-[250px]")
    collapseOverlayRef.current.classList.toggle("hidden")
}

export const openDropdown = () => {
    let dropdownMenu = document.getElementById("dropdown_menu");
    dropdownMenu.classList.toggle("hidden");
};

export const openWideDropdown = () => {
    let dropdownMenu = document.getElementById('dropdown_menu');
    let bottomArrow = document.getElementsByClassName('bottom-arrow')[0];
    dropdownMenu.classList.toggle('hidden');
    bottomArrow.classList.toggle('rotate-180');
}

export const openAnswerStatus = (ref) => {
    ref?.current?.classList?.remove('scale-0');
}

export const closeAnswerStatus = (ref) => {
    ref?.current?.classList?.add('scale-0');
    ref?.current?.classList?.remove('scale-100');
}