import classNames from "classnames";
import React, {useEffect, useState} from "react";

const LevelTab = (props) => {

    const {
        state,
        setState
    } = props;

    const params = new URLSearchParams(window.location.search);
    const getByMember = params.get('getByMember') || false;

    const STATIC_LEVELS = getByMember === 'true'
        ? ["Beginner"]
        : ["Starter", "Beginner", "Intermediate", "Advanced"]

    useEffect(() => {
        if(getByMember === 'true') {
            setState('Beginner');
        }
    }, [getByMember])

    return (
        <>
            {(STATIC_LEVELS.length > 0 && STATIC_LEVELS.includes(state)) && (
                <div className="mx-auto mt-5">
                    <ul id="tabs" className="flex gap-2 sm:gap-5 justify-between mx-auto text-start">
                        {STATIC_LEVELS.map((tab, index) => (
                            <li
                                key={index}
                                className="font-bold w-full max-w-[150px] relative pb-1 cursor-pointer"
                                onClick={() => setState(tab)}
                            >
                                <button type="button">{tab}</button>
                                <div
                                    className={classNames("absolute bottom-0 left-0 right-0 h-[3px] rounded", {
                                        "bg-primary": state === tab,
                                        "bg-base-text": state !== tab
                                    })}
                                >
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}

export default LevelTab;