import {AddAction} from "../services/SpeakingLesson/SpeakingLessonService";

const useAction = () => {
    const sendAction = async (termId, bookUnitId, actionType) => {

        const model = {
            termId: termId,
            bookUnitId: bookUnitId,
            actionType: actionType
        };

        const result = await AddAction(model);
        return result.status === 200;
    }

    return { useAction: sendAction };
}

export default useAction;

