import React, { useEffect } from 'react'
import { Holiday_2 } from '../../../../assets/svg'
import useAnalytics from '../../../../hooks/useAnalytics';
import { useLocalization } from '../../../../hooks/useLocalization'

const Holiday = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.useAnalytics('screen_view', { screen_name: 'Gunun_Dersi_Bugün_Tatil' });
        window.customDataLayer.push({ event: 'screen_view', screen_name: 'Gunun_Dersi_Bugün_Tatil' });
    },[]);

    return (
        <>

            <div className="gray-section-bottom">

                <div className="flex flex-col sm:flex-row sm:justify-between text-sm mt-4 mx-auto max-w-lg">
                    <div className="sm:w-7/12 sm:order-last">
                        <p className="mb-5">
                            {strings.speaking_lesson.components.holiday.content_1}
                        </p>
                        <p>
                            {strings.speaking_lesson.components.holiday.content_2}
                        </p>
                    </div>
                    <div className="sm:w-5/12 mt-5 sm:mt-0">
                        <img
                            className="mx-auto sm:mx-0"
                            src={Holiday_2}
                            width="128"
                            height="128"
                            alt=""
                        />
                    </div>
                </div>

            </div>

        </>
    )
}

export default Holiday