import React from 'react'
import { Outlet } from 'react-router-dom';
import { Menu, SideBar } from '../../components'
import { useLocalization } from '../../hooks/useLocalization'

const MentorLayout = () => {

    const strings = useLocalization();
    return (
        <>
            <Menu title={strings.mentor.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
        </>
    )
}

export default MentorLayout