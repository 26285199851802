import {removeCookie} from "ko-basic-cookie";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {cookieName} from "../../components/Constants";
import {url} from "../../routes/utility";
import {GetCandidateSurveyService, LoginService} from "../../services/Auth/AuthService";
import {memberLogin} from "../../store/MemberSlice";
import {decodeToken, encodeToken} from "../../utils";

const AutoSignin = () => {

    const { base64 } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [decodedBase64, setDecodedBase64] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        let decodedStr = atob(base64);
        setDecodedBase64(decodedBase64 => decodedStr);
    }, []);

    useEffect(() => {
        const keys = decodedBase64.split('&');

        const result = {};

        keys.map(pair => {
            const [key, value] = pair.split('=');
            result[key] = value;
        });

        setPhoneNumber(phoneNumber => result['phone']);
        setPassword(password => result['pass']);
    }, [decodedBase64])

    useEffect(() => {
        if(phoneNumber?.length > 0 && password?.length > 0){
            const login =  async () => {

                const result = await LoginService(phoneNumber, password);
                if (result.statusCode === 200) {
                    //get member info
                    const rawToken = result.message;
                    const member = decodeToken(rawToken);

                    let terms = '';

                    if (member.hasOwnProperty("Terms")) {
                        terms = encodeToken(member.Terms);
                        delete member["Terms"];
                    }

                    const token = encodeToken(member);

                    //set member login
                    dispatch(memberLogin({ token, terms }));

                    //send info to analytics
                    // analytics.useCustomAnalytics('user_memberId', memberId);
                    // analytics.useCustomAnalytics('User_type', memberType);

                    //define a redirect url
                    let redirectUrl = "dashboard";

                    //if member type is candidate

                    //get survey status
                    const surveyResult = await GetCandidateSurveyService(member.MemberId);
                    const surveyStatus = JSON.parse(surveyResult.content);

                    if (surveyStatus) {
                        if (surveyStatus?.first.length === 0) {
                            redirectUrl = "onboarding.first";
                        }
                    }

                    navigate(url(redirectUrl));
                } else {
                    localStorage.clear();
                    removeCookie(cookieName);
                    navigate(url("auth.login"));
                    window.location.reload();
                }
            }

            login();
        }
    }, [phoneNumber, password]);
}

export default AutoSignin;