import {useLocalization} from "../../../../hooks/useLocalization";
import {SadFace, SmileFace} from "../../../../assets/svg";
import React, {forwardRef} from "react";
import classNames from "classnames";
import {AnswerStatutes} from "../../../../components/Constants";

const AnswerStatus = forwardRef((props, ref) => {

    const { answerStatus } = props;
    const strings = useLocalization();

    return (
        <div
            ref={ref}
            className="flex flex-col gap-1.5 justify-center items-center mt-5 transition-all duration-500 scale-0 origin-top"
        >
            <img
                src={
                    answerStatus === AnswerStatutes.CORRECT
                        ? SmileFace
                        : SadFace
                }
                alt="correct_answer"
                width={answerStatus === AnswerStatutes.NOT_ANSWERED ? 0 : 32}
                height={answerStatus === AnswerStatutes.NOT_ANSWERED ? 0 : 32}
            />
            <p
                className={classNames("font-bold text-center", {
                    "text-green block" : answerStatus === AnswerStatutes.CORRECT,
                    "text-red block" : answerStatus === AnswerStatutes.WRONG,
                    "hidden" : answerStatus === AnswerStatutes.NOT_ANSWERED
                })}
                dangerouslySetInnerHTML={{__html:
                    answerStatus === AnswerStatutes.CORRECT
                        ? strings.onboarding_exercises.correct_answer
                        : strings.onboarding_exercises.wrong_answer
                }}
            ></p>
        </div>
    )
})

export default AnswerStatus;