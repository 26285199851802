import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";

const CountdownTimer = ({ endDate }) => {

    const strings = useLocalization();

    const calculateTimeLeft = () => {
        const difference = new Date(endDate) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);


    return (
        <div className="flex gap-5 justify-center">
            <div className="flex flex-col items-center">
                <div
                    className="flex items-center justify-center bg-secondary w-12 h-12 rounded-lg text-white text-[24px]">
                    {timeLeft.days}
                </div>
                <span>{strings.dashboard.student.day}</span>
            </div>
            <div className="flex flex-col items-center">
                <div
                    className="flex items-center justify-center bg-secondary w-12 h-12 rounded-lg text-white text-[24px]">
                    {timeLeft.hours}
                </div>
                <span>{strings.dashboard.student.hour}</span>
            </div>
            <div className="flex flex-col items-center">
                <div
                    className="flex items-center justify-center bg-secondary w-12 h-12 rounded-lg text-white text-[24px]">
                    {timeLeft.minutes}
                </div>
                <span>{strings.dashboard.student.minute}</span>
            </div>
            <div className="flex flex-col items-center">
                <div
                    className="flex items-center justify-center bg-secondary w-12 h-12 rounded-lg text-white text-[24px]">
                    {timeLeft.seconds}
                </div>
                <span>{strings.dashboard.student.second}</span>
            </div>
        </div>
    )
}

export default CountdownTimer;