import { fetchApi } from "../index";

export const SendTicketService = async (memberId, message, token) => {
    const url = '/support/send_ticket/';

    const payload = {
        payload: {
            memberId: memberId,
            brandId: parseInt(process.env.REACT_APP_BRAND_ID),
            message: message
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const SetTicketReadService = async (ticketContentId, token) => {
    const url = '/support/set_ticket_read/';

    const payload = {
        payload: {
            ticketContentId: ticketContentId,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetTicketsService = async (memberId, token) => {
    const url = '/support/get_tickets/';

    const payload = {
        payload: {
            memberId: memberId
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetPostponeHistoryService = async (termId, token) => {
    const url = '/support/get_postpone_history/';

    const payload = {
        payload: {
            termId: termId
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const PostponeLessonService = async (date, termId, memberId) => {
    const url = '/_services/member/v2/support/postpone_lesson/';

    const payload = {
        lessonDate: date,
        termId: termId,
        createUserId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetTeacherWorkingTimesService = async (token) => {
    const url = '/support/get_teacher_working_times/';

    const payload = {
        payload: {
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetTeacherLessonTimeChangeListService = async (termId, token) => {
    const url = '/support/get_change_teacher_time_list/';

    const payload = {
        payload: {
            termId: termId
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

/* export const GetAllTeacherService = async () => {
    const url = '/_services/teacher/v1/teacher/get_all_human_teachers';

    const payload = {
    };

    return await fetchApi(url, payload);
} */

export const TeacherFreeLessonTimesService = async (termId, beginTime, endTime, token) => {
    const url = '/support/get_teacher_free_lesson_times/';

    const payload = {
        payload: {
            termId: termId,
            beginTime: beginTime,
            endTime: endTime
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetFreezeInformationService = async (termId, token) => {
    const url = '/support/get_freeze_information/';

    const payload = {
        payload: {
            termId: termId,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetMissedInformationService = async (termId, token) => {
    const url = '/support/get_missed_information/';

    const payload = {
        payload: {
            termId: termId,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const FreezeClassesService = async (termId, token) => {
    const url = '/support/freeze_classes/';

    const payload = {
        payload: {
            termId: termId,
            freezeMonth: 2,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetMissedCallFreeTimeService = async (termId, memberId, token) => {
    const url = '/support/get_missed_call_free_time/';

    const payload = {
        payload: {
            termId: termId,
            createUserId: memberId
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const SetMissedCallService = async (termId, lessonDate, lessonTime, teacherId, token) => {
    const url = '/support/set_missed_call/';

    const payload = {
        payload: {
            termId: termId,
            lessonDate: lessonDate,
            lessonTime: lessonTime,
            teacherId: teacherId,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const SetMissedCallServiceV2 = async (termId, lessonDate, lessonTime, teacherId, createUserId, token) => {
    const url = '/support/set_missed_call_v2/';

    const payload = {
        payload: {
            termId: termId,
            lessonDate: lessonDate,
            lessonTime: lessonTime,
            teacherId: teacherId,
            createUserId: createUserId
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };
    return await fetchApi(url, payload);
}

export const ChangePasswordService = async (memberId, currentPassword, newPassword, token) => {
    const url = '/support/change_password/';

    const payload = {
        payload: {
            memberId: memberId,
            currentPassword: currentPassword,
            newPassword: newPassword,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const AutoMissedLesson = async (model) => {
    const url = "/_services/member/v1/support/auto_missed_lesson/";

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
};