import { ModalFullScreenWhite } from "../../../../components";
import React, { useEffect, useRef, useState } from "react";
import AnswerStatus from "../Common/AnswerStatus";
import {AnswerStatutes, EventLogs} from "../../../../components/Constants";
import { closeAnswerStatus, openAnswerStatus } from "../../../../utils/OpenClose";
import classNames from "classnames";
import {useLocalization} from "../../../../hooks/useLocalization";
import {sendEventByDefaultParameters} from "../../../../utils";
import useAnalytics from "../../../../hooks/useAnalytics";

const WritingExerciseModal = (props) => {

    const { setOpenWritingExerciseModal, writingExerciseInfo } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const answerStatusRef = useRef();

    if(!writingExerciseInfo.sentence.includes(writingExerciseInfo?.word)) {
        writingExerciseInfo.word = writingExerciseInfo.word.slice(0, -1);
    }

    const maxInputLength =
        writingExerciseInfo?.word?.length > 9 ? 3 : writingExerciseInfo?.word?.length > 3 ? 2 : 1;
    const relatedWordIndex = writingExerciseInfo?.sentence
        .replaceAll(',', '')
        .replaceAll('.', '')
        .replaceAll('!', '')
        .replaceAll('?', '')
        .replaceAll(':', '')
        .replaceAll(';', '')
        .split(" ")
        .findIndex(w => w === writingExerciseInfo?.word);

    const [wordStates, setWordStates] = useState([]);
    const [questionSentence, setQuestionSentence] = useState('');
    const [maskedWord, setMaskedWord] = useState('');
    const [answerStatus, setAnswerStatus] = useState(AnswerStatutes.NOT_ANSWERED);

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            writingExerciseInfo?.logParameters,
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    // Yazma egzersizi modalını kapatma işlemlerini yapan fonksiyon
    const handleCloseWritingExerciseModal = () => {
        setOpenWritingExerciseModal(false);
        sendEvent(EventLogs.VIDEO_EXERCISE_WORD_WRITING_EXERCISE_BACK); //LOGGED
    }

    const setQuestionWord = () => {

        const numberOfChanges = writingExerciseInfo?.word?.length - maxInputLength;

        if (
            numberOfChanges !== null &&
            (numberOfChanges < 0 || numberOfChanges > writingExerciseInfo?.word?.length)
        ) {
            throw new Error(
                'maxInputLength must be a non-negative integer less than or equal to the length of inputStr'
            );
        }

        let result = '';
        let numChanges = 0;

        for (let char of writingExerciseInfo?.word) {
            if (
                numberOfChanges !== null &&
                numChanges < numberOfChanges &&
                Math.random() < 0.5
            ) {
                result += '_';
                numChanges++;
            } else {
                result += char;
            }
        }

        while (numChanges < numberOfChanges) {
            const randomIndex = Math.floor(Math.random() * writingExerciseInfo?.word?.length);
            if (result[randomIndex] !== '_') {
                result =
                    result.slice(0, randomIndex) + '_' + result.slice(randomIndex + 1);
                numChanges++;
            }
        }
        setMaskedWord(result);
        setWordStates([result]);
        setQuestionSentence(writingExerciseInfo.sentence.replace(writingExerciseInfo.word, result));
    };

    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === 'Backspace') {
                if (wordStates.length <= 1) {
                    return;
                }
                let newWordStates = [...wordStates];
                newWordStates?.pop();
                let currentWord = newWordStates[newWordStates?.length - 1];
                setQuestionSentence(prev =>
                    prev.replace(wordStates[wordStates?.length - 1], currentWord)
                );
                setWordStates(newWordStates);
            } else {
                if (!wordStates[wordStates?.length - 1]?.includes('_'))
                    return;
                let newWord = wordStates[wordStates?.length - 1]?.replace('_', event.key);
                setQuestionSentence(questionSentence =>
                    questionSentence.replace(wordStates[wordStates?.length - 1], newWord)
                );
                setWordStates(state => [...state, newWord]);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [wordStates]);

    useEffect(() => {
        setQuestionWord();
    }, [])

    useEffect(() => {
        if(!questionSentence?.includes('_')) {
            if(questionSentence === writingExerciseInfo?.sentence) {
                setAnswerStatus(AnswerStatutes.CORRECT);
            } else {
                setAnswerStatus(AnswerStatutes.WRONG);
            }

            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_WRITING_EXERCISE_COMPLETED); //LOGGED
            openAnswerStatus(answerStatusRef);
        } else {
            setAnswerStatus(AnswerStatutes.NOT_ANSWERED)
            closeAnswerStatus(answerStatusRef);
        }
    }, [questionSentence]);

    return (
        <ModalFullScreenWhite
            handleCloseClick={handleCloseWritingExerciseModal}
            children={
                <div className="flex items-center justify-center mt-4">
                    <div className="max-w-[500px] flex flex-col gap-4 px-4 md:px-0">

                        <p className="text-secondary text-center text-[18px] font-bold">{strings.video_practice.word_card.writing_exercise}</p>

                        <div className="bg-box p-4 rounded-md flex flex-col gap-2">

                            <p className="text-center font-bold text-[14px]">
                                {strings.video_practice.word_card.use_keyboard}
                            </p>

                            {/* Değiştirilmiş cümleyi gösterir */}
                            <p className="text-center">
                                {questionSentence.split(" ")
                                    .map((item, index) => (
                                        <span
                                            key={index}
                                            className={classNames("", {
                                                "font-bold text-primary" : index === relatedWordIndex
                                            })}
                                        >
                                            {item}{" "}
                                        </span>
                                    ))
                                }
                            </p>
                        </div>

                        {!questionSentence?.includes('_') &&
                            <button
                                type="button"
                                className="text-button font-bold underline text-primary hover:text-secondary"
                                onClick={() => {
                                    setQuestionSentence(
                                        writingExerciseInfo.sentence.replace(writingExerciseInfo.word, maskedWord)
                                    );
                                    setWordStates([maskedWord]);
                                }}
                            >
                                {strings.video_practice.word_card.reset}
                            </button>
                        }

                        <AnswerStatus
                            ref={answerStatusRef}
                            answerStatus={answerStatus}
                        />
                    </div>
                </div>
            }
        />
    );
}

export default WritingExerciseModal;
