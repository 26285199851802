import React from 'react'
import { useLocalization } from '../../../../hooks/useLocalization'

const Accordion = ({ data, title, tabindex, onclick }) => {

    const strings = useLocalization();

    const Content = () => {
        return data.length > 0 ? (
            data.map((item, index) => (
                <p key={index} className="mb-3">{item} {strings.speaking_lesson.attendance_report.date_content}</p>
            ))) : (
            <p className="mb-3">{strings.speaking_lesson.attendance_report.not_use}</p>
        )
    }

    return (
        <div className="group outline-none accordion-section text-sm mt-5" tabIndex={tabindex} onClick={onclick}>
            <div className="group accordion-div">
                <div className="accordion-header">
                    <span className="material-symbols-outlined text-base-text"> arrow_forward_ios </span>
                </div>
                <div className="accordion-text">
                    {title}
                </div>

            </div>
            <div className="accordion-content">
                <Content />
            </div>
        </div>
    )
}

export default Accordion