import {formatDateWithMonthAndYear, getDefaultPhotoByGender} from "../../../../utils";
import {ProfileSettings} from "../../../../assets/svg";
import React from "react";
import useAuth from "../../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useLocalization} from "../../../../hooks/useLocalization";
import {url} from "../../../../routes/utility";
import {useSelector} from "react-redux";
import {EventLogs, MemberProfileStatus} from "../../../../components/Constants";
import {UpdateMemberProfileStatus} from "../../../../services/Profile/ProfileService";
import useFollowUnfollow from "../../../../hooks/useFollowUnfollow";
import useAnalytics from "../../../../hooks/useAnalytics";

const ProfileInfo = (props) => {

    const { data, updateProfileStatus, getMemberProfile, setSendRequestToRecommendations } = props;

    const strings = useLocalization();
    const { member } = useAuth();
    const { follow, followLoading } = useFollowUnfollow();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);

    const handleSelfProfileButton = () => {
        if(MemberProfileStatus.PUBLIC === data?.profileStatus){
            navigate(url("learntogether.reference"));
            analytics.useAnalytics(EventLogs.ADD_FRIEND_CLICKED);
        } else {
            unlockProfile(member?.MemberId);
        }
    }

    const handleNotselfProfileButton = () => {
        !data?.isFollow
            && follow(
                member?.MemberId,
                data?.memberId,
                () => {
                    getMemberProfile();
                    setSendRequestToRecommendations(true);
                }
            );
    }

    const unlockProfile = (memberId) => {
        UpdateMemberProfileStatus(memberId, MemberProfileStatus.PUBLIC)
            .then(result => {
                if(result.status === 200) {
                    updateProfileStatus();
                    analytics.useAnalytics(EventLogs.PROFILE_UNLOCKED, false, true);
                }
            })
            .catch()
    }

    return (
        Object.keys(data).length > 0 &&
            <>
                <div className="flex gap-2">
                    <img
                        src={getDefaultPhotoByGender(data?.gender, data?.memberId) ?? ""}
                        width="60"
                        height="60"
                        alt="profile-photo"
                    />
                    <div className="flex flex-col justify-center">
                        <p className="font-bold">{data?.memberName}</p>
                        <p className="text-[12px]">
                            {strings.profile.join_date_text
                                ?.replace("#date#", formatDateWithMonthAndYear(data?.joinDate, language))}
                        </p>
                    </div>
                </div>
                <div className="absolute top-0 right-0">
                    <div className="relative group">
                        <button
                            type="button"
                            onClick={() => navigate(url("profile.settings"))}
                        >
                            <img
                                src={ProfileSettings}
                                width="16"
                                height="16"
                                alt="profile-settings"
                            />
                        </button>
                        <div className="tooltip-top -top-[34px]">
                            {strings.profile.profile_settings}
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 mt-3 text-[14px]">
                    <button
                        type="button"
                        className="text-primary font-bold hover:text-secondary hover:underline"
                        onClick={() =>
                            navigate(url("profile.followlist", { username: data?.username }), { state: { source: "followings" }})
                        }
                    >
                        <span className="font-bold">{data?.followingMemberCount}</span>{" "}
                        {strings.profile.following}
                    </button>
                    <p>|</p>
                    <button
                        type="button"
                        className="text-primary font-bold hover:text-secondary hover:underline"
                        onClick={() =>
                            navigate(url("profile.followlist", { username: data?.username }), { state: { source: "followers" }})
                        }
                    >
                        <span className="font-bold">{data?.followerMemberCount}</span> {strings.profile.follower}
                    </button>
                </div>
                <div className="flex items-center justify-center mt-3">
                    <button
                        type="button"
                        className="button primary-button max-w-[324px]"
                        disabled={followLoading}
                        onClick={() =>
                            data?.isSelfProfile
                                ? handleSelfProfileButton()
                                : handleNotselfProfileButton()
                        }
                    >
                        {data?.isSelfProfile &&
                            <>
                                {/*Kullanıcı kendi profilini görüntülüyor ve profil durumu public ise "Arkadaş Ekle" butonu basılıyor.*/}
                                {MemberProfileStatus.PUBLIC === data?.profileStatus
                                    && strings.profile.add_friend
                                }

                                {/*Kullanıcı kendi profilini görüntülüyor ve profil durumu private ise "Profil Kilidini Aç" butonu basılıyor.*/}
                                {MemberProfileStatus.PRIVATE === data?.profileStatus
                                    && strings.profile.unlock_profile
                                }
                            </>
                        }

                        {!data?.isSelfProfile &&
                            <>
                                {data?.isFollow
                                    && strings.profile.you_are_following}

                                {!data?.isFollow
                                    && strings.profile.follow}
                            </>
                        }
                    </button>
                </div>
            </>
    )
}

export default ProfileInfo;