import React from 'react'
import { Audio } from '../../../../components';
import useAnalytics from '../../../../hooks/useAnalytics';

const LessonBookAudio = ({ title, src, events, onplay, onpause, analyticsPass = false }) => {

    const analytics = useAnalytics();

    return (
        <div className="transparent-section mt-5">
            <h5 className="text-primary">{title}</h5>
            {src.map((item, index)=>
                <Audio
                    key={`${item}-${index}`}
                    src={item}
                    onclick={() => !analyticsPass && analytics.useAnalytics(events)}
                    onplay={onplay}
                    onpause={onpause}
                />
            )}
        </div>
    )
}

export default LessonBookAudio;