import React, {useEffect, useState} from 'react'
import useAnalytics from '../../../../hooks/useAnalytics';
import {useLocalization} from '../../../../hooks/useLocalization'
import {AttendedInformation, LessonAudio, Teacher, LessonContent, LessonStudy} from '../Common';
import PopularSentences from "../Common/PopularSentences";

const Attended = ({ data, selectedDate }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const [convertedData, setConvertedData] = useState();

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            let newData = {
                1: [], //StudySubjects-question
                2: [], //StudySubjects-sentence
                3: [], //DailyTextbook-book
                4: [], //DailyTextbook-presentation
                5: [], //DailyTextbook-practice
                6: [], //video
                7: [], //audio
                8: [], // Lesson Audio
            };

            data.book.contents.map(value => {
                // 7 contentType var
                for (let i = 1; i < 9; i++) {
                    value.contentType == i && newData[i].push(value.content);
                }
            });

            data.lessonRecords?.map(value => {
                newData[8].push(
                    `https://records.clickivo.com/${value.slice(4, 12)}/${value}`,
                );
            });

            setConvertedData(newData);
        }
    }, [data]);

    useEffect(() => {
        analytics.useAnalytics('Completed_Lesson_Detail_View');
        analytics.useAnalytics('screen_view', { screen_name: 'Gunun_Dersi_Islenmis_Derse_Katildi' });
        window.customDataLayer.push({ event: 'screen_view', screen_name: 'Gunun_Dersi_Islenmis_Derse_Katildi' });
    },[]);

    return (
        <>
            <AttendedInformation data={data} />

            {convertedData && convertedData[8][0] && (
                <LessonAudio
                    src={convertedData[8]}
                    title={strings.speaking_lesson.components.audio.lesson_records_title}
                    event='Completed_Lesson_Record_Listen'
                />
            )}

            <LessonStudy
                isBookHaveQuiz={data?.book?.isBookHaveQuiz}
                bookUnitId={data?.book?.bookUnitId}
                selectedDate={selectedDate}
            />

            <Teacher
                teacherInfo={data?.teacher}
                lessonCallLogs={data?.lessonCallLogs}
            />

            <LessonContent
                book={data?.book}
                convertedData={convertedData}
                selectedDate={selectedDate}
            />

            {data?.book?.bookUnitId !== 0 &&
                <PopularSentences
                    title={strings.speaking_lesson.daily_lesson.sentence_bank.title}
                    bookUnitId={data?.book?.bookUnitId}
                    selectedDate={selectedDate}
                />
            }
        </>
    )
}

export default Attended