import React, { useEffect, useState } from 'react';
import {useLocalization} from "../../../../hooks/useLocalization";
import {Timer} from "../../../../assets/svg"; // Timer bileşeni veya resmi eklenecek

const QuizTimer = ({ duration, setIsTimerEnd, pauseTimer }) => {

    const strings = useLocalization();

    // State değeri ve zamanlayıcı süresi
    const [remainingTime, setRemainingTime] = useState(duration);

    // Zamanlayıcı işlemi
    useEffect(() => {
        const timer = setInterval(() => {
            // Eğer pauseTimer true ise zamanlayıcıyı durdur
            if (pauseTimer) {
                clearInterval(timer);
            } else {
                // Kalan süreyi bir saniye azalt
                setRemainingTime(prevRemainingTime => prevRemainingTime - 1);
            }
        }, 1000); // Her saniye güncelle

        // Kalan süre 0 olduğunda veya daha az olduğunda zamanlayıcıyı temizle
        if (remainingTime <= 0) {
            clearInterval(timer);
            setIsTimerEnd(true);
        }

        // Komponent unmount edildiğinde zamanlayıcıyı temizle
        return () => clearInterval(timer);
    }, [remainingTime, pauseTimer]);

    return (
        <div className="flex justify-center items-center gap-2">
            <img src={Timer} width={30} height={30}/>
            <span className="font-bold">{strings.speaking_lesson.quiz.remaining_time}:</span>
            <span className="font-bold text-primary">
                {Math.floor(remainingTime / 60)}:{(remainingTime % 60).toString().padStart(2, '0')}
            </span>
        </div>
    )

}

export default QuizTimer;
