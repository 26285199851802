import {useLocalization} from "../../hooks/useLocalization";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {GetEducationProgramList} from "../../services/SpeakingLesson/SpeakingLessonService";
import {Accordion, ActionIcons, LevelTab, Loading} from "../../components";
import {BookModal} from "../../components/Modals";
import {url} from "../../routes/utility";
import {getActionIconByActionStatus, getLanguageIdByLanguageKey} from "../../utils";
import useAction from "../../hooks/useAction";
import {ActionTypes, EventLogs} from "../../components/Constants";
import useAnalytics from "../../hooks/useAnalytics";
import useAuth from "../../hooks/useAuth";

const EducationPrograms = () => {

    const strings = useLocalization();

    const { member } = useAuth();
    const navigate = useNavigate();
    const selectedTermId = useSelector(state => state.termStore.termId);
    const action = useAction();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);
    const params = new URLSearchParams(window.location.search);

    const getByMember = params.get('getByMember') || false;

    const modalRef = useRef();

    const [loading, setLoading] = useState(true);

    // Data ile alakalı state'ler
    const [data, setData] = useState([]);
    const [visibleData, setVisibleData] = useState({});

    // Tablar ile alakalı state'ler
    const [selectedTab, setSelectedTab] = useState(null);

    // Kitap modalı ile alakalı stateler
    const [showModal, setShowModal] = useState(false);
    const [selectedBookUrl, setSelectedBookUrl] = useState("");

    // Kullanıcının son girdiği derse göre açık gelecek olan accordion index'ini tutan state
    const [openedAccordionIndex, setOpenedAccordionIndex] = useState(-1);
    const [lastLessonIndex, setLastLessonIndex] = useState(-1);
    const [accordionInnerScrollHeight, setAccordionInnerScrollHeight] = useState(0);

    const programAndReasonToLearnEnglishIds = [
        {
            programId: 12,
            reasonToLearnEnglishId: 1
        },
        {
            programId: 13,
            reasonToLearnEnglishId: 2
        },
        {
            programId: 14,
            reasonToLearnEnglishId: 3
        },
        {
            programId: 15,
            reasonToLearnEnglishId: 4
        },
        {
            programId: 16,
            reasonToLearnEnglishId: 5
        },
        {
            programId: 17,
            reasonToLearnEnglishId: 6
        },
        {
            programId: 18,
            reasonToLearnEnglishId: 7
        },
        {
            programId: 19,
            reasonToLearnEnglishId: 8
        },
        {
            programId: 20,
            reasonToLearnEnglishId: 9
        }
    ]

    const openModal = async (bookContent, bookId) => {
        setSelectedBookUrl(selectedBookUrl => bookContent.bookUrl);
        setShowModal(showModal => true);
        modalRef.current.classList.remove("hidden");

        analytics.useAnalytics(EventLogs.EDUCATION_PROGRAM_BOOK_CLICKED);

        if(bookContent.educationProgramStatus === ActionTypes.NOT_STARTED) {
            const result = await action.useAction(selectedTermId, bookContent.bookUnitId, ActionTypes.PREPARATION);

            if(result)
                updateData(bookContent, bookId);
        }
    }

    const closeModal = () => {
        setShowModal(showModal => false);
        setSelectedBookUrl(selectedBookUrl => "");
        modalRef.current.classList.add("hidden");
    };

    // Action alındığında servise tekrar gitmemek için verinin güncellenmesini sağlayan fonksiyon
    const updateData = (bookContent, bookId) => {
        const targetData = visibleData.bookContentItems.filter(v => v.bookId === bookId);
        const targetSubData = findBookUnitById(targetData, bookContent.bookUnitId);

        if(targetSubData) {
            targetSubData.educationProgramStatus = 200;
        }
    }

    // Veri içersinde bookUnitId'ye göre arama yapan fonksiyon
    const findBookUnitById = (data, targetBookUnitId) => {
        for (const unit of data) {
            for (const bookUnit of unit.bookUnitContents) {
                if (bookUnit.bookUnitId === targetBookUnitId) {
                    return bookUnit;
                }
            }
        }
        return null;
    }

    // Veri içerisinde arama yapıp, bookUnitId'ye göre bookId'yi getiren fonksiyon.
    const findBookIndexByBookUnitId = (data, bookUnitId) => {
        for (let i = 0; i < data.length; i++) {
            for (const unit of data[i].bookUnitContents) {
                if (unit.bookUnitId === bookUnitId)
                    return i;
            }
        }
        return -1;
    }


    useEffect(() => {
        setLoading(loading => true);

        GetEducationProgramList(selectedTermId, getLanguageIdByLanguageKey(language))
            .then(result => {
                let resultContent;

                if(result.status === 200) {
                    resultContent = JSON.parse(result.content);
                    setData(data => resultContent);
                } else {
                    navigate(url("market.educationpackages"));
                }

                // Eğer accordion'lar kullanıcının son kitabına göre açılmayacaksa loading bu kısımda false'a çekiliyor.
                !resultContent?.lastBookUnitId && setLoading(false);

            })
            .catch(e => {});
    }, [language]);

    /*
        * Veri içerisinde tab değer var ise ilk tab seçili değer olarak set ediliyor.
        * Veriden gönderilen tablar dinamik olarak array içerisine atılıyor.
    */
    useEffect(() => {
        if(Object.keys(data).length > 0) {
            let tempSelectedTab = "";

            if(!data.lastBookCategoryTitle) {
                data.bookList.map((item, index) => {
                    if(item.categoryTitle && index === 0){
                        tempSelectedTab = item.categoryTitle;
                    }
                })
            } else {
                tempSelectedTab = data.lastBookCategoryTitle;
            }

            setSelectedTab(selectedTab => tempSelectedTab);
        }
    }, [data]);

    /*
        * Eğer seçili tab var ise ilgili veri visibleData olarak set ediliyor.
        * Level içermeyen kitaplarda seçili tab olmadığı için visibleData direkt data olarak set edildi.
    */
    useEffect(() => {
        /*
            Eğer accordionlar kullanıcının son kitabına göre açılacak ise loading her tab değiştiğinde true'ya çekiliyor.
        */
        data?.lastBookUnitId && setLoading(loading => true);

        if(selectedTab){
            let tempVisibleData = data.bookList.find(item => item.categoryTitle === selectedTab);
            setVisibleData(visibleData => tempVisibleData);
        } else {
            setVisibleData(visibleData => data);
        }
    }, [selectedTab])

    useEffect(() => {
        if(Object.keys(visibleData).length > 0 && data?.lastBookUnitId) {
            // Görüntülenen veri içerisinden kitap içeriklerinin tutulduğu array seçiliyor.
            const bookContentData = visibleData.bookContentItems

            // İşlenen son kitabın bookUnitId'sine göre veri içerisinde bookId bulunuyor
            const bookIndex = findBookIndexByBookUnitId(bookContentData, data?.lastBookUnitId);
            const tempLastLessonIndex = bookContentData[bookIndex]?.bookUnitContents.findIndex(bc => bc.bookUnitId === data?.lastBookUnitId);

            setOpenedAccordionIndex(openedAccordionIndex => bookIndex);
            setLastLessonIndex(lastLessonIndex => tempLastLessonIndex);

            // Veri işlemleri bittikten sonra loading false'a çekiliyor ve render sağlanıyor
            setLoading(loading => false);
        }
    }, [visibleData]);

    /*
        * Sayfa scroll'u height hesaplaması
        * Açık olacak olan ünite index'ine göre sayfanın scroll height'i hesaplanıyor.
    */
    useEffect(() => {
        if(openedAccordionIndex !== -1) {
            let baseTopHeight = 20 + (openedAccordionIndex * 59);
            window.scrollTo({ top: baseTopHeight, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [openedAccordionIndex]);

    /*
        * İç akordiyon scroll height hesaplaması
        * Açık olan akordiyon index'i belirliyse scrollHeight bir akordiyonun yüksekliğine göre ayarlnıyor.
    */
    useEffect(() => {
        if(lastLessonIndex !== -1) {
            setAccordionInnerScrollHeight(accordionInnerScrollHeight => (lastLessonIndex * 60) - 10);
        }
    }, [lastLessonIndex])

    useEffect(() => {
        analytics.useAnalytics(EventLogs.EDUCATION_PROGRAM_VIEW);

        if(getByMember === 'true'){
            const program
                = programAndReasonToLearnEnglishIds.find(p => p.reasonToLearnEnglishId === +member?.ReasonToLearnEnglishId) || false;

            if(program)
                window.location.href = `/market/educationpackages/detail/${program.programId}?getByMember=true`
        }
    }, []);

    return (
        <div className="main-content">
            <div className="left-page">

                {loading && <Loading/>}

                {!loading && (
                    <div className="transparent-section mt-5 text-sm text-start">
                        <h5 className="text-secondary">{strings.speaking_lesson.education_packages.title}</h5>

                        <ActionIcons/>

                        <div className="bg-box rounded-md p-1 mt-6 pl-3">
                            <p className="font-bold text-start text-[16px]">{data?.programName}</p>
                        </div>

                        <LevelTab
                            state={selectedTab}
                            setState={setSelectedTab}
                        />

                        {Object.keys(visibleData).length > 0 && (
                            visibleData?.bookContentItems.map((book, index) => {

                                let bookUnitTitle = book.unitTitle ? book.unitTitle : strings.speaking_lesson.education_packages.units;
                                let actionCount = book.bookUnitContents.filter(b => b.educationProgramStatus !== ActionTypes.NOT_STARTED).length;
                                let totalUnitCount = book.bookUnitContents.length;

                                let isOpenControl = data?.lastBookUnitId
                                    ? index === openedAccordionIndex
                                    : index === 0;

                                return (
                                    <Accordion
                                        key={index}
                                        title={`${bookUnitTitle} - ${actionCount}/${totalUnitCount}`}
                                        isOpen={isOpenControl}
                                        scrollValue={accordionInnerScrollHeight}
                                        disableOutsideClick={showModal}
                                        children={
                                            <>
                                                {book.bookUnitContents.map((bookContent, index) => (
                                                    <div
                                                        key={index}
                                                        className={classNames("", {
                                                            "pb-4": book.bookUnitContents.length - 1 === index
                                                        })}
                                                    >
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex gap-2 items-center">
                                                                <img
                                                                    src={getActionIconByActionStatus(bookContent?.educationProgramStatus).icon}
                                                                    className={classNames("rounded-md border", {
                                                                        [getActionIconByActionStatus(bookContent?.educationProgramStatus).className]: [!!getActionIconByActionStatus(bookContent?.educationProgramStatus).className]
                                                                    })}
                                                                    width={26}
                                                                    height={26}
                                                                    alt="content-icon"
                                                                />
                                                                <p className={classNames("text-start", {
                                                                    "text-primary font-bold" : bookContent.bookUnitId === data?.lastBookUnitId
                                                                })}>
                                                                    {index + 1}) {bookContent.bookTitle}
                                                                </p>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="material-symbols-outlined text-base-text cursor-pointer"
                                                                onClick={() => openModal(bookContent, book.bookId)}
                                                            >
                                                                article
                                                            </button>
                                                        </div>
                                                        {book.bookUnitContents.length - 1 !== index &&
                                                            <hr className="border border-[#f0f0f0]"/>}
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    />
                                )
                            })
                        )}
                    </div>
                )}

                <BookModal
                    ref={modalRef}
                    src={selectedBookUrl}
                    toggleModal={closeModal}
                    timeoutState={showModal}
                />

                <div className="left-margin-bottom"></div>
            </div>
        </div>
    )

}

export default EducationPrograms;