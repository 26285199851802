import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { ModalFullScreenWhite } from "../../../../components";
import { EventLogs, LanguageTitleType } from "../../../../components/Constants";
import useAnalytics from "../../../../hooks/useAnalytics";
import { useLocalization } from "../../../../hooks/useLocalization";
import { GetShareImage } from "../../../../services/VideoPractice/VideoPracticeService";
import { sendEventByDefaultParameters } from "../../../../utils";

const ShareModal = (props) => {

    const { setOpenShareModal, shareModalInfo } = props;
    const strings = useLocalization();
    const analytics = useAnalytics();
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const copiedRef = useRef();

    const [selectedImage, setSelectedImage] = useState("");

    // base event fonksiyonu
    const sendEvent = (event, parameters) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                ...shareModalInfo?.logParameters,
                ...parameters
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    const shareWithWhatsapp = () => {
        const shareLink = `${process.env.REACT_APP_WHATSAPP_API_URL}?text=${selectedImage}`;
        window.open(shareLink, "_blank");

        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_SHARE_CLICK,
            {
                platform: 'whatsapp'
            }
        ); //LOGGED
    }

    const sendMail = () => {
        const body = encodeURIComponent(selectedImage);

        const mailtoLink = `mailto:?&body=${body}`;
        window.location.href = mailtoLink;

        sendEvent(
            EventLogs.VIDEO_EXERCISE_WORD_SHARE_CLICK,
            {
                platform: 'mail'
            }
        ); //LOGGED
    }

    const copyLink = () => {
        handleCopy(selectedImage)
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);

        copiedRef.current.classList.remove("hidden");

        setTimeout(() => {
            copiedRef.current.classList.add("hidden");
        }, 1500);
    }

    const SOCIAL_LINKS = [
        {
            name: strings.social_button.whatsapp,
            action: shareWithWhatsapp
        },
        {
            name: strings.social_button.mail,
            action: sendMail
        },
        {
            name: strings.social_button.copy_link,
            action: copyLink
        },
    ]

    const createServiceModel = (shareType) => {
        return {
            wordId: shareModalInfo?.wordId,
            wordType: shareModalInfo?.wordType,
            languageTitleType: LanguageTitleType.TURKISH,
            brandId: +process.env.REACT_APP_BRAND_ID,
            shareType: shareType
        }
    };

    const getShareImageByShareType = (shareType) => {

        const model = createServiceModel(shareType);

        GetShareImage(model)
            .then(result => {
                if(result.status === 200){
                    setSelectedImage(result.content);
                }
            })
            .catch()
    }

    useEffect(() => {
        getShareImageByShareType(shareModalInfo?.shareType);
    }, [])

    return (
        <ModalFullScreenWhite
            handleCloseClick={() => setOpenShareModal(false)}
            children={
                <div className="flex items-center justify-center mt-4 pb-5">
                    <div className="flex flex-col max-w-[480px] gap-4 px-4 md:px-0">
                        <p className="text-secondary text-center font-bold">
                            {strings.video_practice.word_card.today_english_word}
                        </p>

                        {selectedImage.length > 0 &&
                            <div className="flex items-center justify-center">
                                <button className={classNames("",{
                                    "border rounded-md border-box": shareModalInfo?.shareType === "origin"
                                })}>
                                    <img src={selectedImage} className="rounded-[10px]"  alt="share" />
                                </button>
                            </div>
                        }

                        <div className="transparent-section">
                            <p className="text-start">{strings.video_practice.word_card.share_text}</p>
                            <div className="flex flex-col gap-3 my-4">
                                {SOCIAL_LINKS.map((social, index) => (
                                    <div key={index} className="relative group">
                                        {social.name === strings.social_button.copy_link && (
                                            <div ref={copiedRef} className="tooltip-top-content hidden -top-[34px] duration-500">{strings.reference.copied}</div>
                                        )}
                                        <button
                                            className="button primary-button max-w-[330px] mx-auto w-full"
                                            onClick={() => {
                                                social.action();
                                            }}
                                        >
                                            {social.name}

                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            }
        />
    )
}

export default ShareModal;