import React, {forwardRef} from 'react';
import {closePopup} from '../utils';

const Popup = forwardRef((props, ref) => {
  
  const { children, setIsClickedCancelButton, showCancelButton } = props;

  return (
    <div
      ref={ref}
      className="transition-transform duration-300 fixed z-30 md:bottom-2 bottom-[78px] right-2 max-sm:left-2 sm:right-4 w-[calc(100% - 16px)] sm:w-[500px] transition-translate duration-500 origin-bottom-right scale-0 bg-box border border-onboarding-border rounded-[10px] px-2 sm:px-8 pt-6 pb-3 space-y-5 shadow-popup"
    >
      <div
        className="text-[14px] text-center"
      >
        {children}
      </div>
      {showCancelButton && (
        <button
          type='button'
          onClick={() => {
            closePopup(ref);
            setIsClickedCancelButton(isClickedCancelButton => true);
          }}
          className="flex justify-center items-center absolute md:-top-2 md:right-3 -top-4 right-2 w-6 h-6 opacity-40 hover:opacity-100 before:fixed before:bg-[#333333] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:fixed after:content-[''] after:bg-[#333333] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45"
        ></button>
      )}
    </div>
  );
});

export default Popup;
