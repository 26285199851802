import { fetchApi } from "../index";

export const AddRateService = async (model, token) => {
    const url = '/teacher/add_rate/';

    const payload = {
        payload: {
            ...model
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetTeacherReviewListService = async (languageId, token) => {
    const url = '/teacher/get_teacher_review_category_list/';

    const payload = {
        payload: {
            languageId: languageId
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);   
}

export const CheckPageVisibilityService = async (model, token) => {
    const url = '/teacher/check_page_visibility/';

    const payload = {
        payload: {
            ...model
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);   
}