import {useEffect, useRef, useState} from 'react';
import {Button, ModalOneButton, ModalOverlay, ModalTwoButtons} from '../../../components';
import {
    addTimeZone,
    closeModal,
    formatNoneZeroDateToZeroDateForDotForOnlyDays,
    getTimeZone,
    openModal
} from "../../../utils";
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import {useLocalization} from '../../../hooks/useLocalization';
import {GetMissedCallFreeTimeService, SetMissedCallServiceV2} from '../../../services/Support/SupportService';
import {InformationAccordion} from '../components';
import {EventLogs, ServiceErrorMessages} from "../../../components/Constants";

const MissedCall = ({ termId, getMissedCall }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const { member, token } = useAuth();
    const timeZone = getTimeZone(member.Timezone);

    const modalRef = useRef();
    const secondModalRef = useRef();
    const thirdModalRef = useRef();
    const modalOverlayRef = useRef();

    const [loadingTeacher, setLoadingTeacher] = useState(false);
    const [viewList, setViewList] = useState(false);
    const [lesson, setLesson] = useState('');
    const [resultModalMessage, setResultModalMessage] = useState('');
    const [data, setData] = useState({});
    const [selectedTeacher, setSelectedTeacher] = useState({});

    const generalInformationData = [
        { id: 1, text: strings.support.missed_call_information.information_one },
        { id: 2, text: strings.support.missed_call_information.information_two },
        { id: 3, text: strings.support.missed_call_information.information_three },
        { id: 4, text: strings.support.missed_call_information.information_four },
        { id: 5, text: strings.support.missed_call_information.information_five },
    ];

    const getTeacherFreeTime = async (termId, memberId) => {
        try {
            const result = await GetMissedCallFreeTimeService(termId, memberId, token);
            if (result.statusCode !== 200) {
                if (result.message)
                    analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
            } else {
                let resultMsg = JSON.parse(result.message);
                if (resultMsg) {
                    setData(resultMsg);
                    setViewList(viewList => true);
                    setLoadingTeacher(loadingTeacher => false);
                } else {
                    openModal(modalOverlayRef, thirdModalRef);
                }
            }
        } catch (error) { }
    };

    const setTeacher = async () => {
        // "05.02.2022" -> "2022-05-02"
        let lessonDateArray = selectedTeacher.lessonDate.split('.');
        let lessonDate = `${lessonDateArray[2]}-${lessonDateArray[1]}-${lessonDateArray[0]}`;
        try {
            const result = await SetMissedCallServiceV2(termId, lessonDate, selectedTeacher.lessonTime, selectedTeacher.teacherId, member.MemberId, token);
            if (result.statusCode !== 200) {
                if (result.message)
                    analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }

                if(result.message === ServiceErrorMessages.LESSON_TIME_IN_ANOTHER_TERM){
                    setResultModalMessage(strings.support.missed_call.lesson_time_not_suitable_error_message);
                } else if (result.message === ServiceErrorMessages.MISSED_LESSON_CONFLICTED){
                    setResultModalMessage(strings.support.missed_call.missed_lesson_conflicted);
                } else {
                    setResultModalMessage(strings.general_information_messages.an_error_occured);
                }

                analytics.useAnalytics(EventLogs.MISSED_CALL_OPERATION_FAILED);
            } else {
                analytics.useAnalytics(EventLogs.MISSED_CALL_APPROVED);
                setResultModalMessage(resultModalMessage => strings.support.missed_call_set_lesson.process_done);
            }
        } catch (error) {
            analytics.useAnalytics('Error_Occured', {
                error: `An unexpected error occurred.`,
            });
            setResultModalMessage(resultModalMessage => strings.general_information_messages.an_error_occured);
         }
        openModal(modalOverlayRef, secondModalRef);
    };

    useEffect(() => {
        analytics.useAnalytics(EventLogs.MISSED_CALL_LIST_HOURS_SCREEN);
    }, [])

    return (
        <>

            <InformationAccordion
                title={strings.support.missed_call_information.information_title}
                generalInformationData={generalInformationData}
            />

            <div className="btn-outer-div">
                <Button
                    type="submit"
                    classnames={`button primary-button my-7 ${loadingTeacher && "disabled:opacity-25"} `}
                    disabled={loadingTeacher}
                    action={() => {
                        analytics.useAnalytics(EventLogs.MISSED_CALL_BUTTON_CLICK);
                        setLoadingTeacher(loadingTeacher => true);
                        getTeacherFreeTime(termId, member?.MemberId);
                    }}
                    text={loadingTeacher ? strings.general_information_messages.loading : strings.support.teacher_time_change.list_hours}
                />
            </div>

            {(viewList && data.length > 0) && (
                <>
                    <div className="transparent-section mt-5 text-sm text-center">
                        <h5 className="text-secondary mb-3">{strings.support.missed_call_set_lesson.title}</h5>
                        <p className="font-bold">{strings.support.missed_call_set_lesson.information}</p>
                        <hr />
                        <div className="mt-5">
                            <div className="font-bold flex justify-between">
                                <p className="w-1/4 text-start">{strings.support.teacher_list.teacher_name}</p>
                                <p className="w-1/4 text-start">{strings.support.teacher_list.lesson_date}</p>
                                <p className="w-1/4 text-start">{strings.support.teacher_list.lesson_time}</p>
                                <p className="w-1/4"> </p>
                            </div>
                            {data.map((teacher, index) => (
                                <div key={index}>
                                    <hr className="my-2" />
                                    <div className="flex justify-between">
                                        <p className="w-1/4 text-start">{teacher.teacherName}</p>
                                        <p className="w-1/4 text-start">{formatNoneZeroDateToZeroDateForDotForOnlyDays(teacher.lessonDate)}</p>
                                        <p className="w-1/4 text-start">{addTimeZone(teacher?.lessonTime?.slice(0, 5),timeZone)}</p>
                                        <p className="w-1/4">
                                            <Button
                                                classnames="button primary-button-outline max-w-max px-2 h-[25px] text-sm mt-0"
                                                action={() => {
                                                    analytics.useAnalytics(EventLogs.CHOOSE_MISSED_CALL);
                                                    setLesson(lesson => `${teacher.teacherName} - ${teacher.lessonDate} - ${addTimeZone(teacher?.lessonTime?.slice(0, 5),timeZone)}`);
                                                    setSelectedTeacher(selectedTeacher => teacher);
                                                    openModal(modalOverlayRef, modalRef);
                                                }}
                                                text={strings.support.teacher_list.choose}
                                            />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </>
            )}

            {/* Modal */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.support.missed_call_set_lesson.modal_transaction
                    .replace(/#lesson#/g, lesson)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    closeModal(modalOverlayRef, modalRef);
                    setTeacher();
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                    setSelectedTeacher(selectedTeacher => { });
                }}
            />

            <ModalOneButton
                ref={secondModalRef}
                title={strings.modal.information_message}
                message={resultModalMessage}
                overlayRef={modalOverlayRef}
                buttonClick={() => {
                    closeModal(modalOverlayRef, secondModalRef);
                    getMissedCall(termId);
                }}
                buttonText={strings.modal.okey}
            />

            <ModalOneButton
                ref={thirdModalRef}
                title={strings.modal.information_message}
                message={strings.support.missed_call.no_suitable_instructor}
                overlayRef={modalOverlayRef}
                buttonClick={() => {
                    closeModal(modalOverlayRef, thirdModalRef);
                }}
                buttonText={strings.modal.okey}
            />

        </>
    )
}

export default MissedCall