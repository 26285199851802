import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {checkNumber} from "../../../../utils";
import {Checkbox, Loading} from "../../../../components";
import {GetStudentInvoiceInformation} from "../../../../services/Market/MarketService";

const Invoice = (props) => {

    const { packageInfo, memberInfo, changeInvoiceInfo, invoiceInfo} = props;
    const strings = useLocalization();

    // Loading -> Servis cevabı bekler
    const [loading, setLoading] = useState(true);
    const [isCorporate, setIsCorporate] = useState(false);

    const handleCheckCorporate = () => {
        setIsCorporate(isCorporate => !isCorporate);
    }

    useEffect(() => {
        if(packageInfo && memberInfo) {
            GetStudentInvoiceInformation(memberInfo?.MemberId)
                .then(result => {
                    if(result.status === 200) {
                        let resultContent = JSON.parse(result.content);
                        changeInvoiceInfo({
                           name: resultContent.fullName,
                           email: resultContent.email,
                           taxNumber: resultContent.taxNumber,
                           address: resultContent.invoiceAddress
                        });
                    }

                    setLoading(false);
                })
                .catch(e => {})
        }
    }, [])

    useEffect(() => {
        changeInvoiceInfo({ isCorporate: isCorporate });
    }, [isCorporate]);

    return (
        <div className="px-5 md:px-0 w-full md:w-[495px] mx-auto text-sm sm:text-base mt-5">
            <div className="font-bold w-full relative pb-2 text-sm mt-4 sm:mt-0">
                {strings.market.checkout.invoice_informations}
                <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
            </div>

            {loading && <Loading />}

            {!loading &&
                <div className="mt-2.5">
                    <input
                        type="text"
                        className="market-info-input h-[40px] !leading-[40px]"
                        placeholder={isCorporate ? strings.market.information.company_name : strings.market.information.name}
                        value={invoiceInfo.name}
                        onChange={(e) => changeInvoiceInfo({name: e.target.value})}
                    />
                    <input
                        type="text"
                        className="market-info-input h-[40px] !leading-[40px]"
                        placeholder={strings.market.information.email}
                        value={invoiceInfo.email}
                        onChange={(e) => changeInvoiceInfo({email: e.target.value})}
                    />
                    {isCorporate &&
                        <input
                            type="text"
                            className="market-info-input h-[40px] !leading-[40px]"
                            placeholder={strings.market.information.tax_office}
                            onChange={(e) => changeInvoiceInfo({taxOffice: e.target.value})}
                        />
                    }
                    <input
                        type="text"
                        className="market-info-input h-[40px] !leading-[40px]"
                        inputMode="numeric"
                        placeholder={isCorporate ? strings.market.information.tax_number : strings.market.information.identity_number}
                        value={invoiceInfo.taxNumber}
                        onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                        onChange={(e) => changeInvoiceInfo({taxNumber: e.target.value})}
                    />
                    <textarea
                        id="message"
                        name="message"
                        rows="3"
                        className="market-info-input mb-1 placeholder:pt-1 pt-1 resize-none"
                        placeholder={isCorporate ? strings.market.information.company_address : strings.market.information.address}
                        value={invoiceInfo.address}
                        onChange={(e) => changeInvoiceInfo({address: e.target.value})}
                    />
                    <div className="flex items-center -mt-2">
                        <Checkbox
                            type="checkbox"
                            state={isCorporate}
                            action={handleCheckCorporate}
                        />
                        <label
                            className="text-xs mt-1.5 -ml-0.5 cursor-pointer"
                            onClick={handleCheckCorporate}
                        >
                            {strings.market.information.is_corporate}
                        </label>
                    </div>
                </div>
            }
        </div>
    )
}

export default Invoice;