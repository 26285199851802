import { fetchApi } from '../index';

export const EducationProgramListService = async (languageTitleType) => {

    const url = '/_services/member/v1/education_program/market_education_program_list/';

    const payload = {
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const EducationProgramSubCategoryListService = async (programId, languageTitleType) => {

    const url = '/_services/member/v1/education_program/market_education_program_categories/';

    const payload = {
        programId: programId,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const EducationProgramDetailService = async (programId, languageTitleType, memberStatus) => {

    const url = '/_services/member/v1/education_program/market_education_program_detail/';

    const payload = {
        programId: programId,
        languageTitleType: languageTitleType,
        brandId: +process.env.REACT_APP_BRAND_ID,
        memberStatus: +memberStatus
    };

    return await fetchApi(url, payload);
}