import classNames from 'classnames';
import { getCookie } from 'ko-basic-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../assets/css/login.css';
import { ModalOneButton, ModalOverlay } from '../../components';
import {cookieName, EventLogs} from '../../components/Constants';
import useAnalytics from '../../hooks/useAnalytics';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { VerifyCodeServiceV2 } from '../../services/Auth/AuthService';
import { checkVerificationCode, clearError, closeModal, openModal, showError } from '../../services/Auth/AuthValidation';

const ForgotPasswordVerificationCode = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const location = useLocation();

    //Eğer landingpage'den gelirse linkin sonuna ?referrer=landingpage diye bir parametre geliyor.
    //Bu parametre gelirse isFromTruePage değerini true yapıyoruz.
    const referrer = new URLSearchParams(location.search).get('referrer');
    const isFromTruePage = (location.state?.from?.includes("/auth/forgotpassword") || referrer === "landingpage") || false;

    const verficationCodeRef = useRef();
    const verficationCodeErrorRef = useRef();
    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [verificationCode, setVerificationCode] = useState('');
    const [loading, setLoading] = useState(false)
    const token = getCookie(cookieName) || false;

    const verificationCodeHandle = e => {
        setVerificationCode(verificationCode => e.target.value);
    };


    const submitHandle = async e => {
        e.preventDefault();

        const valid = checkVerificationCode(verificationCode);

        //Eğer kullanıcı landing page'den geliyorsa memberId'sini paramdan alıyoruz.
        const memberId = JSON.parse(localStorage.getItem('memberId')) || window.atob(new URLSearchParams(location.search).get('from')).replace(/^['"]|['"]$/g, '');

        // Eğer kullanıcı landing page'den gelirse oradan gelen memberId'yi local storage'e kaydediyoruz.
        !localStorage.getItem('memberId') && localStorage.setItem('memberId', JSON.stringify(memberId))

        if (valid) {

            setLoading(loading => true)

            const result = await VerifyCodeServiceV2(memberId, verificationCode);

            //if there is no error do operations
            if (result.status === 200) {

                //send info to analytics
                analytics.useAnalytics(
                    EventLogs.PASSWORD_RESET_CONFIRMATION,
                    undefined,
                    true,
                    false
                );
                navigate(url("auth.changepassword") + window.location.search, { state: { "from": location.pathname } });


                //if there is an error send error informations to analytics and open a modal
            } else {
                if (result.content) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${result.content}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
                openModal(modalOverlayRef, modalRef);
            }

            setLoading(loading => false)
        } else {
            showError(valid, verficationCodeErrorRef);
        }
    }

    //verification code useeffect
    useEffect(() => {
        clearError(verficationCodeErrorRef);

        //for borders
        const element = verficationCodeRef.current;

        if (verificationCode === '') {
            element.classList.remove('border-red-600')
            element.classList.remove('border-green-600')
        } else {
            if (checkVerificationCode(verificationCode)) {
                element.classList.remove('border-red-600')
                element.classList.add('border-green-600')
                clearError(verficationCodeErrorRef);
            }
            else {
                element.classList.add('border-red-600')
                element.classList.remove('border-green-600')
            }
        }

    }, [verificationCode])

    //login control & analytics
    useEffect(() => {

        //if request comes from true page(forgot password)
        if (isFromTruePage) {
            if (token) {
                navigate(url("home"));
            }

        } else {
            navigate(url("home"));
        }
    }, []);

    return (
        <>
            <form id="form" onSubmit={submitHandle}>
                <div className="mb-[6px]">
                    <label className="block text-start text-base-text-light font-bold" htmlFor="password">
                        {strings.auth.form.enter_code}
                    </label>
                    <input className="keypress input-area w-full block" id="verification_code"
                        type="number" ref={verficationCodeRef} min="1000" max="9999"
                        name="verification_code" placeholder={strings.auth.form.enter_sms_code}
                        onChange={verificationCodeHandle}
                    />
                </div>
                <p id="verification_code_error" className="text-[11px] text-[#FF0000] text-start ml-2 hidden" ref={verficationCodeErrorRef}>{strings.auth.validation_messages.invalid_verification_code}</p>

                <button type="submit" disabled={loading} className={classNames("button button-mt primary-button", {
                    "opacity-25 disabled:pointer-events-none": loading
                })}>
                    {loading ?
                        strings.general_information_messages.processing
                        : strings.auth.form.verification_button}
                </button>

                <p className="text-[12px] text-center mt-4">{strings.auth.information_messages.forgot_password_verification_code_sentence_1}</p>
                <p className="text-[12px] text-center my-4">{strings.auth.information_messages.forgot_password_verification_code_sentence_2}</p>

                <Link to={{
                    pathname: url('auth.login'),
                    search: window.location.search
                }}
                    className='button text-button'>{strings.auth.information_messages.forgot_password_back}
                </Link>

            </form>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.auth.validation_messages.you_entered_an_incorrect_verification_code}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    )
}

export default ForgotPasswordVerificationCode