import ExerciseAnswerButton from "../Common/ExerciseAnswerButton";
import {Button} from "../../../../components";
import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {AnswerStatutes} from "../../../../components/Constants";
import {closeAnswerStatus, openAnswerStatus} from "../../../../utils/OpenClose";
import AnswerStatus from "../Common/AnswerStatus";
import {useDispatch} from "react-redux";
import {removeId, setCorrectId, setMistakeId} from "../../../../store/ExerciseReportSlice";
import useAnalytics from "../../../../hooks/useAnalytics";
import {getLogDescriptionAndParameter} from "../../../../utils";

const MultipleChoice = (props) => {

    const { selectedQuestion, questionsLength, questionIndex, setQuestionIndex, setInnerStep, setComponentStep, logs } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const lang = "tr"

    const answerStatusRef = useRef();

    //Seçili cevabın tutulduğu state.
    const [selectedAnswer, setSelectedAnswer] = useState({});
    //"İleri" butonunun textinin tutulduğu state -> Default: Boş Bırak
    const [nextButtonText, setNextButtonText] = useState(strings.speaking_lesson.quiz.leave_empty);
    //"Kontrol Et" butonunun textinin tutulduğu state -> Default: Boş Bırak
    const [isClickedControlButton, setIsClickedControlButton] = useState(false);
    //Seçilen şıkkın doğru olup olmadığını tutan state -> Default: Cevaplanmadı
    const [answerStatus, setAnswerStatus] = useState(AnswerStatutes.NOT_ANSWERED);

    /*
        * Geri butonu kontrolleri yapılıyor.
        * Eğer kullanıcı ilk soruda ise geri butonuna basıldığında içerik alanına gönderiliyor.
        * Eğer kullanıcı ilk soruda değil ise bir önceki soruya gönderiliyor.
    */
    const handlePreviousButton = () => {
        if(questionIndex === 0) {
            setInnerStep();
        } else {
            setQuestionIndex(questionIndex => questionIndex - 1);
            clearStates();
        }
    }

    const clearStates = () => {
        closeAnswerStatus(answerStatusRef);
        setAnswerStatus(answerStatus => AnswerStatutes.NOT_ANSWERED);
        setSelectedAnswer({});
        setIsClickedControlButton(isClickedControlButton => false);
    }

    /*
        * ileri butonu kontrolleri yapılıyor.
        * if: Eğer kullanıcı herhangi bir cevap seçtiyse ve ilgili butona bastıysa kontrol etme işlemi gerçekleştiriliyor.
        * else if: Eğer kullanıcı herhangi bir cevap seçtiyse ve kontrol ettiyse bir sonraki soruya geçiliyor.
        * else if: Kullanıcı eğer son soruda ise farklı bir componente geçiş yapıyoruz.
        * else: Eğer kullanıcı herhangi bir işlem yapmadan butona basarsa bir sonraki soruya geçiliyor.
    */
    const handleNextButton = () => {
        //İki yerde log atıldığı için butonu log bilgileri alınıyor.
        let logInfoContinue = getLogDescriptionAndParameter(logs?.continue);
        let logInfoLeaveEmpty = getLogDescriptionAndParameter(logs?.leaveEmpty);

        if(Object.keys(selectedAnswer).length > 0 && !isClickedControlButton) {
            setIsClickedControlButton(isClickedControlButton => true);
            if(selectedAnswer.isCorrectAnswer) {
                setAnswerStatus(answerStatus => AnswerStatutes.CORRECT);
                dispatch(setCorrectId(selectedQuestion.id));
            }

            if(!selectedAnswer.isCorrectAnswer) {
                setAnswerStatus(answerStatus => AnswerStatutes.WRONG);
                dispatch(setMistakeId(selectedQuestion.id));
            }

            //Dinamik olarak 'Kontrol Et' butonu logu atılıypr
            let logInfo = getLogDescriptionAndParameter(logs?.check);
            if(logInfo) {
                logInfo.parameterObject['result'] = selectedAnswer.isCorrectAnswer;
                analytics.useAnalytics(logInfo.logDescription, logInfo.parameterObject);
            }

            openAnswerStatus(answerStatusRef);
        } else if(Object.keys(selectedAnswer).length > 0 && isClickedControlButton && questionIndex !== questionsLength - 1) {
            setQuestionIndex(questionIndex => questionIndex + 1);

            //'Devam Et' butonunda log atılıyor.
            logInfoContinue && analytics.useAnalytics(logInfoContinue.logDescription, logInfoContinue.parameterObject);

            clearStates();
        } else if(questionIndex === questionsLength - 1) {
            //'Devam Et' butonunda log atılıyor.
            (logInfoContinue && isClickedControlButton)
                && analytics.useAnalytics(logInfoContinue.logDescription, logInfoContinue.parameterObject);

            //'Boş Bırak' butonunda log atılıyor.
            (logInfoLeaveEmpty && !isClickedControlButton)
                && analytics.useAnalytics(logInfoLeaveEmpty.logDescription, logInfoLeaveEmpty.parameterObject);

            setComponentStep();
        } else {
            setQuestionIndex(questionIndex => questionIndex + 1);

            //'Boş Bırak' butonunda log atılıyor.
            logInfoLeaveEmpty && analytics.useAnalytics(logInfoLeaveEmpty.logDescription, logInfoLeaveEmpty.parameterObject);

            clearStates();
        }
    }

    /*
        * ileri butonu texti güncelleniyor.
        * if: "Kontrol Et" texti basılıyor.
        * else if: "Devam Et" texti basılıyor.
        * else: "Boş Bırak" texti basılıyor.
    */
    useEffect(() => {
        if(Object.keys(selectedAnswer).length > 0 && !isClickedControlButton) {
            setNextButtonText(strings.speaking_lesson.quiz.control);
        } else if (Object.keys(selectedAnswer).length > 0 && isClickedControlButton) {
            setNextButtonText(strings.speaking_lesson.quiz.continue);
        } else {
            setNextButtonText(strings.speaking_lesson.quiz.leave_empty);
        }
    }, [selectedAnswer, isClickedControlButton])


    // Soruya geri gelindiğinde store'dan ilgili soruyu siler.
    useEffect(() => {
        dispatch(removeId(selectedQuestion.id));
    }, [selectedQuestion.id]);

    return (
        <div className="flex flex-col">
            <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-center">
                <h5>{selectedQuestion?.translation[lang]}</h5>

                <div className="flex flex-col mt-4 items-center">
                    {selectedQuestion?.questionAnswers.map((item, index) => (
                        <ExerciseAnswerButton
                            key={index}
                            index={index}
                            item={item}
                            selectedAnswer={selectedAnswer}
                            setSelectedAnswer={setSelectedAnswer}
                            isClickedControlButton={isClickedControlButton}
                        />
                    ))}
                </div>

                <div className="flex gap-8 my-5 justify-center">
                    <Button
                        classnames="button primary-button-outline max-w-[150px]"
                        text={strings.pagination.previous}
                        action={handlePreviousButton}
                    />
                    <Button
                        classnames="button primary-button max-w-[150px]"
                        text={nextButtonText}
                        action={handleNextButton}
                    />
                </div>
            </div>

            <AnswerStatus ref={answerStatusRef} answerStatus={answerStatus} />
        </div>
    )
}

export default MultipleChoice;