import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePageLeave = (delay = 0) => {
    const [isLeaving, setIsLeaving] = useState(false);
    const location = useLocation();
    const callbackRef = useRef(null);
    const timeoutRef = useRef(null);

    const setCallback = useCallback((callback) => {
        callbackRef.current = callback;
    }, []);

    const handlePageLeave = useCallback(() => {
        if (!isLeaving) {
            setIsLeaving(true);
            if (callbackRef.current) {
                callbackRef.current();
            }

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setIsLeaving(false);
            }, delay);
        }
    }, [isLeaving, delay]);

    useEffect(() => {
        return () => {
            handlePageLeave();
        };
    }, [location, handlePageLeave]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return { isLeaving, setCallback };
};

export default usePageLeave;