//get browser name
import {DeviceOs} from "../components/Constants";

export const getBrowserName = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else {
        browserName = "No browser detection";
    }
    return browserName
}

export const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    if (/Mac/.test(platform)) {
        return 'Mac OS';
    } else if (/Win/.test(platform)) {
        return 'Windows';
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        return 'iOS';
    } else if (/Android/.test(userAgent)) {
        return 'Android';
    } else if (/Linux/.test(platform)) {
        return 'Linux';
    }

    return null;
}

export const getOSId = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    if (/Mac/.test(platform)) {
        return DeviceOs.MACOS;
    } else if (/Win/.test(platform)) {
        return DeviceOs.WINDOWS;
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        return DeviceOs.IOS;
    } else if (/Android/.test(userAgent)) {
        return DeviceOs.ANDROID;
    } else if (/Linux/.test(platform)) {
        return DeviceOs.LINUX;
    }

    return DeviceOs.NONE;
}

export const getDeviceOSName = () => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Windows') !== -1) {
        return 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
        return 'macOS'
    } else if (userAgent.indexOf('Linux') !== -1) {
        if (userAgent.indexOf('Ubuntu') !== -1) {
            return 'Linux Ubuntu';
        } else if (userAgent.indexOf('Fedora') !== -1) {
            return 'Linux Fedora';
        } else if (userAgent.indexOf('Debian') !== -1) {
            return 'Linux Debian';
        } else if (userAgent.indexOf('Mint') !== -1) {
            return 'Linux Mint';
        } else {
            return 'Linux';
        }
    } else {
        return 'Bilinmeyen İşletim Sistemi';
    }
}

export const getBrowserVersion = () => {
    var ua = navigator.userAgent;
    var tem;
    var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}