const Video = ({ src, onclick }) => {
  return (
    <>
      <iframe
        src={src}
        onClick={onclick}
        frameBorder="0"
        allowFullScreen
        className="w-full rounded-[10px] aspect-video mt-4"
      ></iframe>
    </>
  );
}

export default Video