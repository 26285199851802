import {fetchApi, fetchPythonApi} from '../index';

export const GetSpeakingLessonDetailService = async (date, termId, token) => {

    const url = '/speaking_lesson/get_lesson_data/';

    const payload = {
        payload: {
            lessonDate: date,
            termId: termId,
            wordTranslationLanguage: 'tr'
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetAttendanceReportService = async (termId, token) => {

    const url = '/speaking_lesson/get_attendance_report/';

    const payload = {
        payload: {
            termId: termId,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetProgressReportService = async (termId, token) => {

    const url = '/speaking_lesson/get_progress_report/';

    const payload = {
        payload: {
            termId: termId,
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetHolidaysService = async (token, lang) => {

    const url = '/speaking_lesson/get_holidays_list/';

    const payload = {
        payload: {
            
        },
        jwtToken: token,
        lang: lang
    };

    return await fetchApi(url, payload);
}

export const GetLessonDatesService = async (termId, token) => {

    const url = '/speaking_lesson/get_lesson_dates/';

    const payload = {
        payload: {
            termId: termId
        },
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetAndUpdateLessonCorrectionsService = async (lessonResultId, mistakes) => {

    const url = '/speaking_lesson/get_and_update_lesson_corrections/'; 

    const payload = {
        payload: {
            lessonResultId: lessonResultId,
            mistakes: mistakes
        }
    }

    return await fetchApi(url, payload);
}

export const GetTermScheduleByTermId = async (termId) => {

    const url = '/speaking_lesson/list_by_term_id/';

    const payload = {
        payload: {
            termId: termId,
        }
    };

    return await fetchApi(url, payload);
}

export const SpeakWordWithTts = async (word) => {
    const url = "/ai/tts";

    const payload = {
        text: word
    };

    return await fetchPythonApi(url, payload);
}

export const GetBookOfTheLastLessonAttended = async (termId) => {
    const url = "/speaking_lesson/get_book_of_the_last_lesson_attended/";

    const payload = {
        payload : {
            termId: termId
        }
    };

    return await fetchApi(url, payload);
}

export const GetDailyPattern = async (model) => {
    const url = "/_services/member/v1/daily_pattern/get_daily_pattern/";

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const AddMemberBookHintQuiz = async (model) => {
    const url = "/_services/member/v1/member_book_hint_quiz/add_member_book_hint_quiz/";

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const GetCaptionByRecordName = async (termId, recordName) => {
    const url = "/_services/caption/v1/caption/get_subtitles/";

    const payload = {
        termId: termId,
        recordName: recordName
    }

    return await fetchApi(url, payload);
}

export const GetBookInformations = async (bookName) => {
    const url = "/_services/books_php/books_get_size/";

    const payload = {
        brandId: +process.env.REACT_APP_BRAND_ID,
        bookName: bookName
    }

    return await fetchApi(url, payload);
}

export const GetEducationProgramList = async (termId, languageTitleType) => {
    const url = "/_services/member/v1/education_program/speaking_lesson_list/";

    const payload = {
        termId: termId,
        languageTitleType: languageTitleType
    }

    return await fetchApi(url, payload);
}

export const AddAction = async (model) => {
    const url = "/_services/member/v1/education_program/add_action/";

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const AddLessonResultAccordionActivity = async (lessonResultId, lessonResultAccordionType) => {
    const url = "/_services/member/v1/lesson_result_accordion_activity/add_lesson_result_accordion_activity/";

    const payload = {
        lessonResultId: lessonResultId,
        lessonResultAccordionType: lessonResultAccordionType
    };

    return await fetchApi(url, payload);
}

export const PlanYourLessonService = async (memberId) => {
    //TODO: Buraya servis gelecek
    console.log("ders planlandı")

}

