import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import { useLocalization } from '../../../hooks/useLocalization'
import {useSelector} from "react-redux";
import {EducationProgramListService} from "../../../services/Market/MarketEducationPackageService";
import {Loading} from "../../../components";
import {ProgramBadge} from "../components";
import {url} from "../../../routes/utility";
import {getLanguageIdByLanguageKey} from "../../../utils";
import useAnalytics from "../../../hooks/useAnalytics";
import {EventLogs} from "../../../components/Constants";

const List = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);
    const location = useLocation();
    const IS_MOBILE = location.pathname.includes("/mobile_market_education_packages");
    // Landing page'lerde bu sayfayı embed edip kullanıldığında bu değer true oluyor.
    const IS_EMBEDDED_URL = !location.pathname.includes("market");

    const [loading, setLoading] = useState(true);
    const [programList, setProgramList] = useState([]);

    const handleButton = (programId, isHaveSubCategory) => {
        let redirectUrl = isHaveSubCategory
            ? IS_EMBEDDED_URL ? 'educationpackages.subcategory' : (IS_MOBILE ? 'mobile_market_sub_category' : 'market.educationpackages.subcategory')
            : IS_EMBEDDED_URL ? 'educationpackages.detail' : (IS_MOBILE ? 'mobile_market_education_packages_detail' : 'market.educationpackages.detail');

        navigate(url(redirectUrl, { programId }));
    }

    useEffect(() => {
        EducationProgramListService(getLanguageIdByLanguageKey(language))
            .then(result => {
                if(result.status === 200){
                    let resultContent = JSON.parse(result.content);
                    setProgramList(programList => resultContent);
                }

                setLoading(loading => false);
            })
            .catch(e => {})
    }, [language])

    useEffect(() => {
        if (IS_MOBILE) {
            const page = {
                "second_page": true
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(page));
        }

        !IS_EMBEDDED_URL && analytics.useAnalytics(EventLogs.EDUCATION_PROGRAM_MARKET_VIEW);
    }, [])

    return (
        <div className={` ${!IS_EMBEDDED_URL ? `${IS_MOBILE ? "main-content-mobile" : "main-content"}` : "mx-5"}`}>
            <div className="left-page">

                {loading && <Loading/>}

                {!loading && (
                    <div className="transparent-section mt-5 text-sm text-start">
                        <h5 className="text-secondary">{strings.market.education_packages.title}</h5>
                        <p className="mt-3 text-center">{strings.market.education_packages.description}</p>

                        {programList.length > 0 &&
                            programList.map((program, index) => (
                                <ProgramBadge
                                    key={index}
                                    title={program.title}
                                    subtitle={program.description}
                                    lessonCount={program.totalLessonCount}
                                    onClick={() => handleButton(program.programId, program.isHaveSubCategory)}
                                />
                            ))
                        }
                    </div>
                )}
                <div className="left-margin-bottom"></div>
            </div>
        </div>
    )
}

export default List