import React from 'react'
import { useLocalization } from '../../../../hooks/useLocalization'
import { Icon_Confused } from '../../../../assets/svg';
import Error from '../Common/Error';
import { url } from '../../../../routes/utility';
import ChangeTeacherTimeHistory from './ChangeTeacherTimeHistory';

const ChangeTeacherTimeNoRight = ({ data }) => {
    const strings = useLocalization();

    return (
        <>
            <Error
                icon={Icon_Confused}
                title={strings.support.teacher_time_change_no_right.no_change_right}
                content={strings.support.teacher_time_change_no_right.no_change_right_information}
                to={url("market.changeteachertime")}
                buttonText={strings.support.buy}
            />
            <ChangeTeacherTimeHistory data={data} />
        </>
    )
}

export default ChangeTeacherTimeNoRight