import { fetchApi } from '../index';

export const GetDashboardLessonData = async (termId) => {

    const url = '/_services/member/v2/lesson/speaking_lesson/';

    const payload = {
        termId: termId,
    };

    return await fetchApi(url, payload);
}

export const MemberDeviceProcesses = async (model) => {

    const url = '/dashboard/member_device_processes_for_web/';

    const payload = {
        payload: {
            ...model
        },
        lang: process.env.REACT_APP_LANG,
    };

    return await fetchApi(url, payload);
}

export const AutoJoinToken = async (memberId) => {

    const url = '/token/auto_join_token/';

    const payload = {
        payload: {
            memberId: memberId,
            brandId: process.env.REACT_APP_BRAND_ID,
        },
        lang: process.env.REACT_APP_LANG,
    };

    return await fetchApi(url, payload);
}

//Mentör bilgilerini getiren servis
export const GetMentorInformation = async (memberId) => {

    const url = '/_services/member/v1/employee_member/get_mentor_information/';

    const payload = {
        memberId: memberId,
    };

    return await fetchApi(url, payload);
}

//Kullanıcının timeZone bilgisini güncelleyen servis
export const UpdateMemberTimeZone = async (memberId, timeZone) => {

    const url = '/_services/member/v1/member/update_member_timezone/';

    const payload = {
        memberId: memberId,
        timeZone: timeZone
    };

    return await fetchApi(url, payload);
}

export const GetMemberName = async (memberId) => {

    const url = '/_services/member/v1/member/get_member_name/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const CheckAutoJoinVisibility = async (memberId) => {

    const url = '/_services/member/v1/lesson/check_auto_join_visibility/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const IsCallBarTestMember = async (memberId) => {
    const url = `/_services/member/v1/member/get_call_bar_visible_members/index.php?memberId=${memberId}`;

    const payload = {}

    return await fetchApi(url, payload);
}