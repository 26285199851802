import { fetchHeader } from "../Header";
import {getBrowserName, getOSId, parseMobileKeyToJson} from "../../utils";

export const LogService = async (event, memberId, termId) => {

    const url = process.env.REACT_APP_API_URL + '/log/';
    const deviceName = getBrowserName();
    const isMobile = window?.location.href.includes('/mobile_');
    const memberInfo = isMobile ?
        sessionStorage.getItem("m")
            ? (parseMobileKeyToJson(sessionStorage.getItem("m")))
            : null
        : null;

    const sourceType = isMobile
        ? 3 // mobil
        : 1; // web

    if (!memberId && isMobile && memberInfo) {
        memberId = memberInfo?.MemberId;
    }

    const payload = termId
        ? {
            description: event,
            sourceType: sourceType,
            userType: 0,
            createUser: memberId,
            termId: termId,
            deviceName: deviceName,
            deviceOs: getOSId()
        }
        : {
            description: event,
            sourceType: sourceType,
            userType: 0,
            createUser: memberId,
            deviceName: deviceName,
            deviceOs: getOSId()
        };

    await fetch(url, fetchHeader(payload));
}