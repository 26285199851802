import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {MarketSourceTypes} from "../../../../components/Constants";
import {formatPriceWithoutCurrency} from "../../../../utils";
import classNames from "classnames";
import {Arrow_Down} from "../../../../assets/svg";
import '../../../../assets/css/react_spring_bottom_sheet.css'
import {Checkbox} from "../../../../components";
import {BottomSheet} from "react-spring-bottom-sheet";
import {useLocation} from "react-router-dom";
import {PackageContent} from "../index";

const CheckoutInfoSticky = (props) => {

    const { changeCreditCardInfo, packageInfo, creditCardInfo, successfulPayment, handlePayment, buttonLoading, setOpenBottomSheet, openBottomSheet } = props;

    const location = useLocation();

    const [isContractApproved, setIsContractApproved] = useState(false);

    const isMobile = location.pathname.includes("mobile_market_checkout");
    const currencySymbol = MarketSourceTypes.SPEAKING_LESSON === packageInfo?.source
        ? packageInfo?.selectedProduct?.productDetail?.priceDetail?.currencySymbol
        : packageInfo?.selectedProduct?.additionalProductPriceDetails?.currencySymbol

    return (
        <>
            <BottomSheet
                className={classNames("", {
                    "mobile-view": isMobile,
                    "web-view": !isMobile,
                })}
                open={openBottomSheet}
                onDismiss={() => setOpenBottomSheet(false)}
            >
                <div className="w-full px-3 py-2">
                    {creditCardInfo?.bank.id !== -1 &&
                        <CheckoutInfoStickyContractApprove
                            changeCreditCardInfo={changeCreditCardInfo}
                            isContractApproved={isContractApproved}
                            setIsContractApproved={setIsContractApproved}
                        />
                    }

                    <div className="max-h-[45vh] overflow-y-auto mb-3">
                        <PackageContent
                            packageInfo={packageInfo}
                            creditCardInfo={creditCardInfo}
                        />
                    </div>
                    <CheckoutInfoStickyContent
                        packageInfo={packageInfo}
                        creditCardInfo={creditCardInfo}
                        successfulPayment={successfulPayment}
                        handlePayment={handlePayment}
                        buttonLoading={buttonLoading}
                        openBottomSheet={openBottomSheet}
                        setOpenBottomSheet={setOpenBottomSheet}
                        currencySymbol={currencySymbol}
                    />
                </div>
            </BottomSheet>
            {!openBottomSheet &&
                <div
                    className={classNames("bg-white fixed left-0 right-0 w-full min-h-[60px] border-t border-b px-3 py-2 shadow-popup rounded-t-[10px]", {
                        "bottom-0" : isMobile,
                        "bottom-[70px]" : !isMobile
                    })}
                >
                    {creditCardInfo?.bank.id !== -1 &&
                        <CheckoutInfoStickyContractApprove
                            changeCreditCardInfo={changeCreditCardInfo}
                            isContractApproved={isContractApproved}
                            setIsContractApproved={setIsContractApproved}
                        />
                    }
                    <CheckoutInfoStickyContent
                        packageInfo={packageInfo}
                        creditCardInfo={creditCardInfo}
                        successfulPayment={successfulPayment}
                        handlePayment={handlePayment}
                        buttonLoading={buttonLoading}
                        openBottomSheet={openBottomSheet}
                        setOpenBottomSheet={setOpenBottomSheet}
                        currencySymbol={currencySymbol}
                    />
                </div>
            }
        </>
    );
}

const CheckoutInfoStickyContent = (props) => {

    const { packageInfo, creditCardInfo, successfulPayment, handlePayment, buttonLoading, openBottomSheet, setOpenBottomSheet, currencySymbol } = props;

    const strings = useLocalization();


    return (
        <div className="flex justify-between items-center">
            <div className="flex flex-col font-bold text-[14px]">
                <span className="text-primary">{strings.market.checkout.total_amount}{" "}</span>

                <button
                    type="button"
                    className="flex gap-2"
                    onClick={() => setOpenBottomSheet(openBottomSheet => !openBottomSheet)}
                >
                    {packageInfo?.source === MarketSourceTypes.SPEAKING_LESSON
                        ? creditCardInfo?.bank.id === -1
                            ? packageInfo?.discountInformation?.totalPrice === 0
                                ? packageInfo?.selectedProduct?.productDetail?.priceDetail?.totalPrice
                                : formatPriceWithoutCurrency(packageInfo?.discountInformation?.totalPrice)
                            : creditCardInfo.priceInfo?.price
                        : packageInfo?.selectedProduct?.additionalProductPriceDetails?.totalPrice
                    }
                    {currencySymbol}

                    <img
                        src={Arrow_Down}
                        className={classNames("material-symbols-outlined text-[36px] transition-transform duration-300 cursor-pointer", {
                            "rotate-180" : !openBottomSheet,
                        })}
                        alt="arrow_down"
                    />
                </button>
            </div>

            <div className="flex justify-center">
                <button
                    type='button'
                    className={classNames("button primary-button min-w-[175px] focus:!bg-primary flex items-center justify-center !text-[14px] !h-[40px]", {
                        "disabled:opacity-25 disabled:pointer-events-none": buttonLoading
                    })}
                    disabled={buttonLoading}
                    onClick={handlePayment}
                >
                    {buttonLoading
                        ? successfulPayment
                            ? strings.market.checkout.pay_is_ok
                            : strings.market.checkout.trying_to_pay
                        : strings.market.checkout.complete_button
                    }
                </button>
            </div>
        </div>
    )
}

const CheckoutInfoStickyContractApprove = (props) => {

    const { changeCreditCardInfo, isContractApproved, setIsContractApproved } = props;

    const strings = useLocalization();

    // Sözleşmenin onay durumu değiştikçe üst componentteki kredi kartı bilgileri güncelleniyor
    useEffect(() => {
        changeCreditCardInfo({ isContractApproved: isContractApproved });
    }, [isContractApproved]);

    return (
        <>
            {/*Sözleşme Onay Durumu*/}
            <label className="flex justify-center items-start">
                <Checkbox
                    type="checkbox"
                    state={isContractApproved}
                    action={() => setIsContractApproved(isContractApproved => !isContractApproved)}
                />
                <p
                    className="text-[12px] text-start"
                    dangerouslySetInnerHTML={{__html: strings.market.checkout.preliminary_information_form}}
                />
            </label>
            <hr className="my-2.5" />
        </>
    )
}

export default CheckoutInfoSticky;