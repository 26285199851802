import { createSlice } from "@reduxjs/toolkit";

export const TermSlice = createSlice({
  name: 'term',
  initialState: { termId: JSON.parse(localStorage.getItem('selectedTerm')) || '' },
  reducers: {
    setTermId: (state, action) => {
      state.termId = action.payload;
      localStorage.setItem('selectedTerm', JSON.stringify(state.termId));
    },
  },
})

export const { setTermId } = TermSlice.actions

export default TermSlice.reducer