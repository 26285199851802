import classNames from "classnames";
import React from "react";

const Checkbox = (props) => {
    const { id, type, state, size = "w-4 h-4", iconSize = 'text-[20px]', action } = props

    const handleCheckbox = (status) => {
        if (status) {
            return 'bg-green border border-green text-white'
        } else {
            return 'border border-[#aaaaaa]'
        }
    }

    return (
        <div className="pt-1.5">
            <input
                name={id}
                type={type}
                className="hidden"
                onChange={action}
                checked={state}
            />
            <label
                htmlFor={id}
                className={`${handleCheckbox(state)} ${size} mr-2 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                onClick={action}
            >
                <span className={classNames('text-inherit material-symbols-outlined animate-fadeIn font-bold', {
                    'hidden': !state,
                    [iconSize]: !!iconSize
                })}>
                    done
                </span>
            </label>
        </div>
    )
}

export default Checkbox;