import { fetchApi } from '../index';

export const GetQuizById = async (quizId) => {

    const url = '/_services/ab_testing/demo_prototype/quiz/get_quiz_by_id/';

    const payload = {
        quizId: quizId
    };

    return await fetchApi(url, payload);
};

export const GetDailySentenceById = async (dailySentenceId) => {

    const url = '/_services/ab_testing/demo_prototype/daily_sentence/get_daily_sentence_by_id/';

    const payload = {
        dailySentenceId: dailySentenceId
    };

    return await fetchApi(url, payload);
};

export const GetVideoDetailsById = async (videoId) => {

    const url = '/_services/ab_testing/demo_prototype/video/get_video_by_id/';

    const payload = {
        videoId: videoId
    };

    return await fetchApi(url, payload);
}

export const GetVideoDialogById = async (dialogId) => {

    const url = '/_services/ab_testing/demo_prototype/video/get_dialog_by_id/';

    const payload = {
        dialogId: dialogId
    };

    return await fetchApi(url, payload);
}

export const GetVideoWordsById = async (wordId) => {

    const url = '/_services/ab_testing/demo_prototype/video/get_words_by_id/';

    const payload = {
        wordId: wordId
    }

    return await fetchApi(url, payload);
}

export const GetVideoGrammarById = async (grammarId) => {

    const url = '/_services/ab_testing/demo_prototype/video/get_grammar_by_id/';

    const payload = {
        grammarId: grammarId
    }

    return await fetchApi(url, payload);
}

export const GetRandomVideoGrammarAi = async () => {

    const url = '/_services/ab_testing/demo_prototype/video/get_random_grammar_ai/index.php';

    const payload = {}

    return await fetchApi(url, payload);
}