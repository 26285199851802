import { useLocalization } from "../../../../hooks/useLocalization"

const ChangeTeacherTimeHistory = ({ data }) => {
    const strings = useLocalization();

    return (
        <div className="transparent-section mt-5 text-sm text-start">
            <h5 className="text-secondary mb-3">{strings.support.teacher_time_change.previously_changed_courses}</h5>
            {data &&
                data.map((history, index) => (
                    <div key={index}>
                        <hr />
                        <p> {strings.support.teacher_time_change.you_changed_your_lesson
                            .replace(/#teacher#/g, history.teacherName)
                            .replace(/#time#/g, history.lessonTime)}</p>
                    </div>
                ))}
            <br />
        </div>
    )
}

export default ChangeTeacherTimeHistory