import { Outlet } from "react-router-dom"
import { Menu, SideBar } from "../../components"
import { useLocalization } from "../../hooks/useLocalization"

const VideoPracticeLayout = () => {

    const strings = useLocalization()

    return (
        <>
            <Menu title={strings.video_practice.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
        </>
    )
}

export default VideoPracticeLayout