import { Icon_Confused } from "../../../assets/svg";
import { useLocalization } from "../../../hooks/useLocalization";
import { Error, InformationAccordion } from "../components";
import {useEffect} from "react";
import useAnalytics from "../../../hooks/useAnalytics";
import {EventLogs} from "../../../components/Constants";

const MissedCallNotInCorrectTime = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const generalInformationData = [
        { id: 1, text: strings.support.missed_call_information.information_one },
        { id: 2, text: strings.support.missed_call_information.information_two },
        { id: 3, text: strings.support.missed_call_information.information_three },
        { id: 4, text: strings.support.missed_call_information.information_four },
        { id: 5, text: strings.support.missed_call_information.information_five },
    ];

    useEffect(() => {
        analytics.useAnalytics(EventLogs.MISSED_CALL_NOT_IN_ACTIVE_HOURS_SCREEN);
    }, []);

    return (
        <>
            <Error
                icon={Icon_Confused}
                title={strings.support.missed_call_out_of_time.out_of_time}
                content={strings.support.missed_call_out_of_time.out_of_time_information}
                hideButton="hidden"
            />

            <InformationAccordion
                title={strings.support.missed_call_information.information_title}
                generalInformationData={generalInformationData}
            />
        </>
    )
}

export default MissedCallNotInCorrectTime