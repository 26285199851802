import {fetchApi} from '../index';

export const InviteFriendsService = async (memberId) => {

    const url = '/_services/member/v1/member_reference/invite_friends/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const TargetReferenceStudentsCount = async (memberId) => {

    const url = '/_services/member/v1/member_reference/target_reference_students_count/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}