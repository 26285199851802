import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../../assets/svg';
import { ModalOneButton, ModalOverlay } from '../../components';
import { MemberFamilyStatus, ServiceErrorMessages } from '../../components/Constants';
import useAuth from '../../hooks/useAuth';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { AddMemberFamily, GetFamilyManagerNameByFamilyCode } from '../../services/LearnTogether/LearnTogetherService';
import { closeModal, openModal } from '../../utils';
import { getQueryParam } from '../../utils/Static';

const JoinFamily = () => {

    const strings = useLocalization();
    const location = useLocation();
    const navigate = useNavigate();
    const { member } = useAuth();

    // familyCode varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const familyCode = getQueryParam('familyCode', location);

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [addMemberText, setAddMemberText] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false);
    const [inviteName, setInviteName] = useState("");

    const addMemberFamily = async (memberId, familyCode, memberFamilyStatus) => {

        setButtonLoading(buttonLoading => true)

        const model = {
            memberId: memberId,
            familyCode: familyCode,
            memberFamilyStatus: memberFamilyStatus
        }

        const result = await AddMemberFamily(model);

        // Eğer Vazgeç butonuna basarsa servisten gelen sonuçla ilgilenmeyip dashboard'a yönlendiriyoruz.
        if (memberFamilyStatus === MemberFamilyStatus.REJECTED) {
            navigate(url("dashboard"));
            return;
        }

        if (result.status === 200) {
            setAddMemberText(addMemberText => strings.learn_together.join_success_message.replace("#NAME#", result.content))
            openModal(modalOverlayRef, modalRef);
        }
        else {
            if (result.content === ServiceErrorMessages.ALREADY_REGISTERED_FAMILY) {
                setAddMemberText(addMemberText => strings.learn_together.already_registered_family)
            } else if (result.content === ServiceErrorMessages.ALREADY_FAMILY_MANAGER) {
                setAddMemberText(addMemberText => strings.learn_together.already_manager)
            } else if (result.content === ServiceErrorMessages.FAMILY_QUOTA_FULL) {
                setAddMemberText(addMemberText => strings.learn_together.family_quota_full)
            } else {
                setAddMemberText(addMemberText => strings.menu.callbar.an_error_occured)
            }
            openModal(modalOverlayRef, modalRef);
        }
        setButtonLoading(buttonLoading => false)
    }

    const getFamilyManagerNameByFamilyCode = async (familyCode) => {
        
        const result = await GetFamilyManagerNameByFamilyCode(familyCode);
        if (result.status === 200) {
            setInviteName(inviteName =>result.content)
        }
    }

    useEffect(() => {
        familyCode && getFamilyManagerNameByFamilyCode(familyCode)
    }, [familyCode])

    return (
        <>
            <div className="h-full overflow-hidden flex flex-col justify-center items-center gap-10 md:gap-20">
                <div className="flex flex-col items-center z-20">
                    <img className="w-[90px] md:w-[120px]" src={Logo} alt="" />
                    <p className="font-bold text-2xl md:text-4xl mt-2 outline-title">{strings.brand_name}</p>
                </div>

                <div className="text-center mx-2 max-w-lg flex flex-col gap-2">
                    <p dangerouslySetInnerHTML={{ __html: strings.learn_together.invited_title.replace("#NAME#", inviteName) }} />
                    <p>{strings.learn_together.invited_content}</p>
                </div>
                <div className="btn-outer-div">
                    <button
                        disabled={buttonLoading}
                        className={classNames("button primary-button", {
                            "opacity-25 disabled:pointer-events-none": buttonLoading
                        })}
                        onClick={() => addMemberFamily(member.MemberId, familyCode, MemberFamilyStatus.ACCEPTED)}
                    >
                        {buttonLoading
                            ? strings.general_information_messages.processing
                            : strings.learn_together.join_family
                        }
                    </button>
                    <button
                        disabled={buttonLoading}
                        className={classNames("button primary-button-outline button-mt", {
                            "opacity-25 disabled:pointer-events-none": buttonLoading
                        })}
                        onClick={() => addMemberFamily(member.MemberId, familyCode, MemberFamilyStatus.REJECTED)}
                    >
                        {buttonLoading
                            ? strings.general_information_messages.processing
                            : strings.modal.dont_approve
                        }
                    </button>
                </div>

            </div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={addMemberText}
                overlayRef={modalOverlayRef}
                buttonText={strings.modal.okey}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                    navigate(url("learntogether.family"))
                }}
            />
        </>
    )
}

export default JoinFamily