import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {motion} from 'framer-motion';
import {Loading, ModalContent, ModalOneButton} from "../../components";
import {addTimeZone, closeModal, getTimeZone, openModal} from "../../utils";
import {AddDemoTerm, GetDemoTeacherAsAuto} from "../../services/MemberDemoRight/MemberDemoRightServices";
import {TeacherSlotAnimation, TimeSlotAnimation} from "./components";
import {useLocalization} from "../../hooks/useLocalization";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import useToken from "../../hooks/useToken";
import {useDispatch} from "react-redux";
import {EventLogs} from "../../components/Constants";
import {setTermId} from "../../store/TermSlice";
import {url} from "../../routes/utility";
import useAnalytics from "../../hooks/useAnalytics";

const DemoAutoTeacher = forwardRef((props, ref) => {

    const {
        closeAction,
        overlayRef
    } = props;

    const strings = useLocalization();
    const { member } = useAuth();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const tokenHook = useToken();
    const dispatch = useDispatch();
    const timeZone = getTimeZone();

    const modalOverlayRef = useRef();
    const modalErrorRef = useRef();

    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    /* Animasyonlar için stateler */
    const [isFirstVisible, setIsFirstVisible] = useState(true);
    const [isTeacherSelectionCompleted, setIsTeacherSelectionCompleted] = useState(false);
    const [isTimerSelectionCompleted, setIsTimerSelectionCompleted] = useState(false);

    const [autoTeacherData, setAutoTeacherData] = useState({});
    const defaultLessonDurationForDemo = 10;

    const addDemoTerm = async () => {
        setButtonLoading(true);

        let [day, month, year] = autoTeacherData?.lessonDate.split('.');
        const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${autoTeacherData?.lessonTimeWithoutTimeZone}`;

        const model = {
            memberId: member?.MemberId,
            teacherId: autoTeacherData?.teacherId,
            lessonDate: new Date(formattedDate),
            lessonTime: autoTeacherData?.lessonTimeWithoutTimeZone + ".0000000"
        }

        const result = await AddDemoTerm(model);

        if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            analytics.useAnalytics(
                EventLogs.DEMO_LESSON_CREATED,
                {
                    teacher: autoTeacherData?.fullName,
                    date: formattedDate.split("T")?.[0] || "",
                    lesson_time: autoTeacherData?.lessonTime
                }
            );

            await tokenHook?.generateToken(member?.MemberId);
            dispatch(setTermId(resultContent?.termId));
            navigate(url("demo.unitselection"), { state: { "lesson": resultContent }});
        } else {
            openModal(modalOverlayRef, modalErrorRef);
        }
    }

    /* Yapay zeka ile eğitmeniniz belirleniyor yazısının 1.5 saniyede bir değişmesi kontrol ediliyor */
    useEffect(() => {
        let interval;

        if(!loading)
            interval = setInterval(() => {
                setIsFirstVisible((prev) => !prev);
            }, 1500);

        return () => clearInterval(interval);
    }, [loading]);

    /*
        * Servise giden istek bittikten sonra ilk 2.5 saniye eğitmen seçim animasyonu gösteriliyor.
        * Eğitmen seçim animasyonu bittikten sonra ise 2.5 saniye boyunca saat seçim animasyonu gösteriliyor.
    */
    useEffect(() => {
        let teacherTimeout;
        let timerTimeout;

        if(!loading){
            teacherTimeout = setTimeout(() => {
                setIsTeacherSelectionCompleted(true);
            }, 2500);

            timerTimeout = setTimeout(() => {
                setIsTimerSelectionCompleted(true);
            }, 5000)
        }

        return () => {
            clearTimeout(teacherTimeout);
            clearTimeout(timerTimeout);
            clearInterval(timerTimeout);
            clearInterval(teacherTimeout);
        };
    }, [loading]);

    useEffect(() => {
        GetDemoTeacherAsAuto(defaultLessonDurationForDemo)
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    resultContent.lessonTimeWithoutTimeZone = resultContent.lessonTime;
                    resultContent.lessonTime = addTimeZone(resultContent.lessonTime, timeZone);

                    setAutoTeacherData(resultContent);
                }

                setLoading(false);
            })
            .catch()
    }, [])

    return (
        <>
            <ModalContent
                ref={ref}
                overlayRef={overlayRef}
                show={true}
                showCloseButton={true}
                className="sm:w-[500px] sm:h-[500px]"
                closeAction={closeAction}
            >
                {loading && <Loading />}

                {(Object.keys(autoTeacherData).length > 0 && !loading) &&
                    <>
                        <p className="text-center font-bold text-xl">
                            {!isTeacherSelectionCompleted &&
                                strings.demo.auto_teacher_selection.available_teacher
                            }

                            {(isTeacherSelectionCompleted && !isTimerSelectionCompleted) &&
                                strings.demo.auto_teacher_selection.available_hour
                            }

                            {(isTeacherSelectionCompleted && isTimerSelectionCompleted) &&
                                strings.demo.auto_teacher_selection.approve_demo_lesson
                            }
                        </p>
                        <div className="p-2">
                            <div className="flex w-full h-[60px]">
                                <TeacherSlotAnimation selectedTeacherIdAfterAnimation={autoTeacherData?.teacherId} />
                                {isTeacherSelectionCompleted &&
                                    <TimeSlotAnimation selectedTimeAfterAnimation={autoTeacherData?.lessonTime} />
                                }
                            </div>

                            {!isTimerSelectionCompleted &&
                                <div className="mt-36 relative mx-auto flex items-center justify-center">
                                    {isFirstVisible ? (
                                        <motion.p
                                            key="first"
                                            className="absolute text-center w-[300px]"
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            transition={{duration: 0.5}}
                                        >
                                            {strings.demo.auto_teacher_selection.ai_choosing_teacher}
                                        </motion.p>
                                    ) : (
                                        <motion.p
                                            key="second"
                                            className="absolute text-center w-[300px]"
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            transition={{duration: 0.5}}
                                        >
                                            {strings.demo.auto_teacher_selection.ai_choosing_time}
                                        </motion.p>
                                    )}
                                </div>
                            }
                            {isTimerSelectionCompleted &&
                                <>
                                    <p className="mt-14 mx-auto text-center">
                                        <span>{strings.demo.auto_teacher_selection.your_lesson_date}:</span>{" "}
                                        <span className="font-bold">{autoTeacherData.lessonDate}</span>
                                    </p>
                                    <p className="mt-10 w-[370px] text-center mx-auto">
                                        {strings.demo.auto_teacher_selection.selected_auto_teacher_description}
                                    </p>

                                    <div className="flex flex-col items-center btn-outer-div mt-6">
                                        <button
                                            type="button"
                                            className={classNames("button button-mt secondary-button w-full", {
                                                "opacity-70 cursor-not-allowed" : buttonLoading
                                            })}
                                            disabled={buttonLoading}
                                            onClick={addDemoTerm}
                                        >
                                            {buttonLoading
                                                ? strings.general_information_messages.processing
                                                : strings.demo.auto_teacher_selection.approve_demo_lesson
                                            }
                                        </button>
                                        <button
                                            type="button"
                                            className="button button-mt secondary-button-outline w-full"
                                            onClick={() => navigate(url("demo"))}
                                        >
                                            {strings.demo.auto_teacher_selection.choose_different_time}
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
            </ModalContent>

            <ModalOneButton
                ref={modalErrorRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.general_information_messages.an_error_occured}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalErrorRef);
                }}
            />
        </>
    )
})

export default DemoAutoTeacher;