import {GetMemberName} from "../services/Dashboard/DashboardService";
import {useEffect, useState} from "react";

const useMemberName = (memberId) => {

    const [memberName, setMemberName] = useState("");
    
    useEffect(() => {
        if(memberId.length > 0) {
            GetMemberName(memberId)
                .then(result => {
                    if (result.status === 200)
                        setMemberName(result.content);

                })
                .catch()
        }
    }, []);

    return memberName;
}

export default useMemberName;