import {fetchApi} from '../index';

export const GetBookQuizListByTermId = async (termId) => {

    const url = '/quiz/get_book_quiz_list_by_term_id/';

    const payload = {
        payload: {
            termId: termId,
        },
    };

    return await fetchApi(url, payload);
}

export const GetRandomQuestions = async (model) => {

    const url = '/quiz/get_random_questions/';

    const payload = {
        payload: {
            ...model
        },
    };

    return await fetchApi(url, payload);
}

export const SendQuizReport = async (model) => {

    const url = '/quiz/add_member_book_quiz/';

    const payload = {
        payload: {
            ...model
        },
    };

    return await fetchApi(url, payload);
}