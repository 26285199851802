import {Failed} from "../../assets/svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useLocalization} from "../../hooks/useLocalization";

const Fail = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const location = useLocation();
    const errorCode = location.state?.errorCode

    return (
        <div className="main-content">

            <div className="mx-auto text-center mt-7 text-sm px-4">
                <img
                    className="inline-block"
                    src={Failed}
                    alt=""
                    width="100"
                    height="100"
                />
                <p className="font-bold mt-7 text-base">{strings.market.failed.title}</p>
                <p className="mt-5">{strings.market.failed.content}</p>
                <p className="mt-5">
                    <span className="font-bold text-primary">{strings.market.failed.bank_error}</span><br/><br/>{errorCode}
                </p>
            </div>

            <div className="btn-outer-div mt-10">
                <button
                    type="button"
                    className="button primary-button"
                    onClick={() => navigate(-1)}
                >
                    {strings.market.failed.button}
                </button>
            </div>

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default Fail;