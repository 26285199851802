import { Icon_Confused } from "../../../assets/svg";
import { useLocalization } from "../../../hooks/useLocalization";
import { url } from "../../../routes/utility";
import { Error, MissedCallHistory } from "../components";

const MissedCallNoRight = ({ data }) => {
  const strings = useLocalization();

  return (
    <>
      <Error
        icon={Icon_Confused}
        title={strings.support.missed_call_no_right.no_right}
        content={strings.support.missed_call_no_right.no_right_information}
        to={url("market.missedcall")}
        buttonText={strings.support.buy}
      />
      <MissedCallHistory data={data} />
    </>
  );
};

export default MissedCallNoRight;
