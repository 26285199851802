import {useLocalization} from "../../hooks/useLocalization";
import {Menu, SideBar} from "../../components";
import {Outlet} from "react-router-dom";
import React from "react";

const PermissionLayout = () => {

    const strings = useLocalization();

    return (
        <>
            <Menu title={strings.permissions.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
        </>
    )
}

export default PermissionLayout;