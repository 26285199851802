import React, {useContext} from 'react'
import {Outlet} from "react-router-dom";
import {Menu, SideBar} from '../../components';
import {useLocalization} from '../../hooks/useLocalization';
import CampaignBar from "./components/CampaignBar";
import {FirebaseContext} from "../../store/FirebaseProvider";

const DashboardLayout = () => {
  const strings = useLocalization();
  const campaignStickyBar = useContext(FirebaseContext)?.campaignStickyBar;

  return (
    <>
      <Menu title={strings.dashboard.title} />

      <section className="container">

        <Outlet />

        <SideBar />

      </section>

      {campaignStickyBar &&
        <CampaignBar campaignStickyBar={campaignStickyBar} />
      }
    </>
  )
}

export default DashboardLayout