import React from "react";
import { useLocalization } from "../hooks/useLocalization";
import classNames from "classnames";

export const Loading = (props) => {

  const strings = useLocalization();
  const {
      classnames,
      text = strings.general_information_messages.loading
  } = props;

  return (
    <div
        className={classNames("flex gap-2 items-center justify-center mt-5", {
            [classnames] : !!classnames
        })}
    >
      <div className="inline-block h-4 w-4 text-secondary animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
      <p>{text}</p>
    </div>
  );
};

export const LoadingSpinner = () => {
    return (
        <div
            className="inline-block h-4 w-4 text-secondary animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
    )
}
