
// Dakika, saniye, salise cinsinden değerleri saniyeye çevirir.
export const convertTimeFormatToSeconds = (timeFormat) => {
    let timeParts = timeFormat.split(':');

    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
    let seconds = parseFloat(timeParts[2].replace(',', '.'));

    let totalSeconds = hours * 3600 + minutes * 60 + seconds;

    return totalSeconds;
}

export const getFormattedTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

export const addTimeZone = (tm, timeZone) => {
    let today = new Date();
    let timeArr = tm.split(":");
    let time = new Date(today.getFullYear(), today.getMonth(), today.getDay(), parseInt(timeArr[0]), parseInt(timeArr[1]));
    let returnTime = new Date(time.setMinutes(time.getMinutes() + parseInt(timeZone)));
    return ("0" + returnTime.getHours()).slice(-2) + ':' + ("0" + returnTime.getMinutes()).slice(-2);
}

// export const getTimeZone = timeZone => {
//     return timeZone ? timeZone : new Date().getTimezoneOffset();
// }

export const getTimeZone = timeZone => {
    let timeZoneInMinutes = -1 * new Date().getTimezoneOffset();
    return timeZoneInMinutes;
}

export const getTimeDiff = (dateFuture, dateNow) => {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
        difference += days === 1 ? `${days} #day#, ` : `${days} #day#, `;
    }

    difference +=
        hours === 0 || hours === 1 ? `${hours} #hour#, ` : `${hours} #hour#, `;

    difference +=
        minutes === 0 || hours === 1 ? `${minutes} #minute#` : `${minutes} #minute#`;

    return difference;
}

export const timeStampToSecond = (input) => {
    const parts = input.split(':');
    const second = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseFloat(parts[2]);
    return parseFloat(second.toFixed(1));
}