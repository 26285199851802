import { GetMemberStreak } from "../services/Profile/ProfileService";
import { useEffect, useState } from "react";

const useMemberStreak = (memberId) => {

    const [memberStreak, setMemberStreak] = useState(0);

    useEffect(() => {
        if (memberId) {
            GetMemberStreak(memberId)
                .then(result => {
                    if (result.status === 200)
                        setMemberStreak(result.content);

                })
                .catch()
        }
    }, [memberId]);

    return memberStreak;
}

export default useMemberStreak;