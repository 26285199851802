import React, { useEffect, useState } from "react";
import AwardsRow from "./components/StreakAwards/AwardsRow";
import { useParams } from "react-router-dom";
import { GetMemberStreakRewards } from "../../services/Profile/ProfileService";
import { useSelector } from "react-redux";
import { getLanguageIdByLanguageKey } from "../../utils";
import { Loading } from "../../components";
import {useLocalization} from "../../hooks/useLocalization";
import useAnalytics from "../../hooks/useAnalytics";
import {EventLogs} from "../../components/Constants";

const StreakAwards = () => {

    const strings = useLocalization();
    const { memberId } = useParams();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);

    const [rewardsData, setRewardsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        memberId &&
            GetMemberStreakRewards(memberId, getLanguageIdByLanguageKey(language))
                .then(result => {
                    if (result.status === 200)
                        setRewardsData(JSON.parse(result.content));

                    setLoading(false);
                })
                .catch()
    }, [language])

    useEffect(() => {
        analytics.useAnalytics(EventLogs.STREAK_ALL_REWARD_SCREEN_VIEWED, undefined, false, true);
    }, []);

    return (
        <div className="main-content" >
            <div className="mt-5">
                <div className="mb-5">
                    <p className="font-bold text-primary text-center">
                        {strings.profile.awards.all_awards_title}
                    </p>
                    <p className="text-center mt-2">
                        {strings.profile.awards.award_description}
                    </p>
                </div>

                {loading && <Loading />}

                {(!loading && rewardsData) && (
                    <>
                        {rewardsData.map((item, index) => (
                            <AwardsRow
                                key={index}
                                data={item}
                            />
                        ))}
                    </>
                )}
            </div>

        </div>
    )
}

export default StreakAwards