import { fetchApi } from '../index';
import {Brands} from "../../components/Constants";

export const CreateAIUseCaseSession = async (memberId, aiUseCaseId) => {
    const url = '/ai/ai_history/ai_use_case_session/create/';

    const payload = {
        payload: {
            memberId: memberId,
            aiUseCaseId: aiUseCaseId,
            applicationId: Brands.KO
        }
    };

    return await fetchApi(url, payload);
}

export const CreateAIUseCaseSessionLog = async (model) => {
    const url = '/ai/ai_history/ai_use_case_session_log/create/';

    const payload = {
        payload: {
            ...model
        }
    };

    return await fetchApi(url, payload);
}