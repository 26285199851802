import { useLocalization } from "../hooks/useLocalization";
import { url } from "../routes/utility";
import { Share, Streak } from "../assets/svg";
import { useNavigate } from "react-router-dom";
import useMemberStreak from "../hooks/useMemberStreak";
import useAnalytics from "../hooks/useAnalytics";
import { EventLogs } from "./Constants";
import useAuth from "../hooks/useAuth";
import classNames from "classnames";

const StreakBar = ({ memberId, source }) => {
    const strings = useLocalization();
    const { member } = useAuth();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const memberStreak = useMemberStreak(memberId);
    const loginMemberId = member?.MemberId;

    const handleClickStreak = () => {
        if (loginMemberId === memberId) {
            navigate(url("profile.streak", { memberId: memberId }));
            analytics.useAnalytics(EventLogs.STREAK_CLICKED);
        }
    }

    return (
        <div
            className={classNames("flex items-center justify-between px-3 w-full h-12 rounded-[10px] bg-primary/10", {
                "hover:bg-primary/20 hover:cursor-pointer": loginMemberId === memberId
            })}
            onClick={handleClickStreak}
        >
            <div className="flex justify-start gap-2">
                <img
                    src={Streak ?? ""}
                    width="24"
                    height="24"
                    alt="streak"
                />
                <div className="flex flex-col">
                    <p className="font-bold">{memberStreak}</p>
                    <p className="text-[14px] -mt-1">
                        {strings.profile.statistics.weekly_streak}
                    </p>
                </div>
            </div>
            {(loginMemberId === memberId && memberStreak > 0) &&
                <img
                    src={Share ?? ""}
                    width="24"
                    height="24"
                    alt="share"
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (loginMemberId === memberId) {
                            navigate(`/profile/streak/${memberId}`, { state: { modalStatus: true, source: source } })
                            analytics.useAnalytics(EventLogs.STREAK_CLICKED);
                        }
                    }}
                />
            }
        </div>
    )
}

export default StreakBar;