import { useLocalization } from '../../../../hooks/useLocalization'
import { Icon_Confused } from '../../../../assets/svg';
import Error from '../Common/Error';
import ChangeTeacherTimeHistory from './ChangeTeacherTimeHistory';

const ChangeTeacherTimeNotAvailable = ({ data }) => {
    const strings = useLocalization();

    return (
        <>
            <Error
                icon={Icon_Confused}
                title={strings.support.teacher_time_change_not_now.not_now}
                content={strings.support.teacher_time_change_not_now.not_now_information}
                hideButton="hidden"
            />
            <ChangeTeacherTimeHistory data={data} />
        </>
    )
}

export default ChangeTeacherTimeNotAvailable