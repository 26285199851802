import { Under_Construction } from "../../assets/svg"
import { useLocalization } from "../../hooks/useLocalization"

const BuyMissedCall = () => {
    const strings = useLocalization();

    return (
        <div className="main-content">

            <div className="mx-auto text-center mt-7 text-sm px-4">
                <img
                    className="inline-block"
                    src={Under_Construction}
                    alt=""
                    width="256"
                    height="256"
                />
                <p className="font-bold mt-7 text-base">{strings.general_information_messages.under_construction_title}</p>
                <p className="mt-5">{strings.general_information_messages.under_construction_content}</p>
            </div>

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default BuyMissedCall