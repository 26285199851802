import React from 'react'
import { Link } from 'react-router-dom'
import useAnalytics from '../../../hooks/useAnalytics'
import { url } from '../../../routes/utility'

const HowToLearnCard = (props) => {

    const analytics = useAnalytics();

    return (
        <>
            <div className="marketing-dialog pl-4 py-3">
                <p className="font-bold mb-2">{props.title}</p>
                <p>{props.content}</p>
                <div className={props.class_name}></div>
            </div>

            {props.show_button && (
                <div className="btn-outer-div my-5 py-3">
                    <Link to={url("market")} className="button primary-button" onClick={() => analytics.useAnalytics('Campaign_Page_CTA_Click')}>{props.view_package}</Link>
                </div>
            )}
        </>
    )
}

export default HowToLearnCard