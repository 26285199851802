import { fetchApi } from '../index';

export const BinCheck = async (binNumber) => {

    const url = '/_services/iyzico/bin_check/';

    const payload = {
        binNumber: binNumber,
        locale: "tr" // Default
    };

    return await fetchApi(url, payload);
}
