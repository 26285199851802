import {useEffect, useRef, useState} from "react";
import {Schedule} from "../../../../assets/svg";
import {useSelector} from "react-redux";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import '../../../../assets/css/react_calendar.css'
import useAnalytics from '../../../../hooks/useAnalytics';
import useAuth from "../../../../hooks/useAuth";
import {useLocalization} from "../../../../hooks/useLocalization";
import {GetLessonDatesService} from "../../../../services/SpeakingLesson/SpeakingLessonService";
import classNames from "classnames";
import { parseDateForLesson, getDayDiff, getyyyyMMddDate } from "../../../../utils";

const ChooseDate = ({ title, sDate, setDate }) => {

  const strings = useLocalization();

  const { token } = useAuth();
  const analytics = useAnalytics();
  const language = useSelector(state => state.localizationStore.language);
  const localStorageTermId = localStorage.getItem('selectedTerm');
  const termId = useSelector(state => state.termStore.termId);
  //Kullanıcı takvim üzerinden gelmişse localStorage'dan seçili tarihi alıyoruz ve bir sabit'e kaydediyoruz.
  let scheduleDateForDateDiff = localStorage.getItem('selectedDate') && new Date(localStorage.getItem('selectedDate'));

  const dateRef = useRef();
  const dateOpenerRef = useRef();
  const dontRender = useRef(true);

  const [dateValue, setDateValue] = useState(new Date(sDate));
  const [dateDiff, setDateDiff] = useState(0);
  const [lessonDates, setLessonDates] = useState([]);

  //Takvimi gösteren fonksiyon
  const showCalendar = () => {
    dateRef.current.classList.toggle('hidden');

    //Kullanıcı takvimden tarih değiştirdiyse dateDiff'i güncelliyoruz.
    if (dateRef.current.classList.contains('hidden')) {

      let days = 0;

      if(scheduleDateForDateDiff){
        //Yukarıda tanımladığımız sabit var ise o tarih ile seçili tarih arasında kaç gün fark var onu hesaplıyoruz.
        days = getDayDiff(new Date(new Date(sDate).setDate(new Date(sDate).getDate())), scheduleDateForDateDiff) ;
      } else {
        //Kullanıcı takvimden gelmemişse bu kısımda aradaki farkı hesaplıyoruz. 
        days = getDayDiff(dateValue, new Date());
      }

      setDateDiff(dateDiff => days);
    }
  }

  //Bu fonksiyon ile takvim üzerinden tarih değiştirildiğinde dateDiff'i güncelliyoruz.
  const prevOrNextDate = (operation = 'prev') => {
    if (operation === 'next') {
      setDateDiff(dateDiff => dateDiff + 1);
    } else {
      setDateDiff(dateDiff => dateDiff - 1);
    }
  }

  //Status'e göre takvim üzerinde renklerin değişmesi için kullanılan array.
  const colorByLessonType = [
    { status: 1, color: 'bg-active', name: strings.lesson_type.active },
    { status: 2, color: 'bg-attended', name: strings.lesson_type.attended },
    { status: 3, color: 'bg-missed', name: strings.lesson_type.missed },
    { status: 4, color: 'bg-postponed', name: strings.lesson_type.postponed },
    { status: 5, color: 'bg-frozen', name: strings.lesson_type.frozen },
    { status: 6, color: 'bg-holiday', name: strings.lesson_type.holiday },
    { status: 7, color: 'bg-weekend', name: strings.lesson_type.weekend },
    { status: 8, color: 'bg-no-lesson', name: strings.lesson_type.no_lesson },
    { status: 9, color: 'bg-system-postponed', name: strings.lesson_type.system_postponed },
    { status: 10, color: 'bg-deleted', name: strings.lesson_type.deleted }
  ]

  const addGroupClass = ({ date, view }) => {
    if (view === 'month') {
      return 'group';
    }
  }

  const getTileContent = (date, view) => {

    const todayDate = new Date();

    return (
      <div className="cell-content">
        <div className="relative group overflow-visible">
          {lessonDates.length > 0 && view === 'month' && lessonDates.map((lesson, index) => {
            const lessonStatusColor = colorByLessonType.filter(data => data.status === lesson.status);
            let color = lessonStatusColor[0].color;
            if (lesson.lessonDate === getyyyyMMddDate(date) && getyyyyMMddDate(todayDate) !== lesson.lessonDate && getyyyyMMddDate(dateValue) !== lesson.lessonDate) {
              return (
                <div key={index}>
                  <div className={classNames(`absolute !-left-[18px] -bottom-[18px] w-[5px] h-[5px] rounded-full ${color}`)}>
                  </div>
                  <div className="tooltip-top !-left-[130px]">
                      <p>{lessonStatusColor[0].name}</p>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    );
  }

  /*
  ////if user clicks anywhere other than calendar close it
  const handleOtherSideClick = (event) => {
    const isClickInside = dateOpenerRef.current.contains(event.target);
    const isOpen = dateRef.current.classList.contains('hidden');
    if (!isClickInside && !isOpen) {
      showCalendar(true);
    }
  }*/

  //if datediff changes set dateValue
  useEffect(() => {
    if (!dontRender.current) {

      if(scheduleDateForDateDiff){
        //Tanımlanan sabit var ise yani kullanıcı takvim üzerinden erişmişse seçilen günü takvim üzerindeki tarihe göre ayarlıyoruz.
        setDateValue(dateValue => new Date(new Date(scheduleDateForDateDiff).setDate(new Date(scheduleDateForDateDiff).getDate() + dateDiff)));
      } else {
        //Kullanıcı takvim üzerinden gelmemişse seçilen günü bugünün tarihine göre ayarlıyoruz.
        setDateValue(dateValue => new Date(new Date().setDate(new Date().getDate() + dateDiff)));
      }
    
      analytics.useAnalytics('Date_Change');
      dontRender.current = true;
    }
  }, [dateDiff])

  useEffect(() => {
    //Bazı durumlarda takvimi göstermek istemiyoruz. Örneğin ilk renderda veya sonraki ve önceki butonlarına basıldığında.
    if (!dontRender.current) {
      showCalendar();
    }

    setDate(parseDateForLesson(dateValue));
    dontRender.current = false;
  }, [dateValue])

  /*useEffect(() => {
    //if user clicks anywhere other than calendar close it
    document.addEventListener('click', handleOtherSideClick, false);

    return () => {
      document.removeEventListener('click', handleOtherSideClick, false);
    };

  }, [])*/

  useEffect(() => {
    let abortController = new AbortController();

    async function getLessonDates() {
      return await GetLessonDatesService(termId, token);
    }

    if (localStorageTermId !== null) {
      getLessonDates().then(data => {
        if (data.statusCode === 200) {
          setLessonDates(lessonDates => JSON.parse(data.message));
        }
      })
    }

    return () => {
      abortController.abort();
    }
  }, [termId])

  return (
    <>
      <div className="secondary-section">
        <h5 className="text-white mb-2">{title}</h5>
        <div className="flex justify-center items-center gap-5">
          <button className="w-6 h-6 border-2 border-white bg-secondary rounded-md"
            onClick={() => prevOrNextDate('prev')}
          >
            <span className="material-symbols-outlined text-white text-lg leading-5">
              west
            </span>
          </button>

          <div onClick={showCalendar} ref={dateOpenerRef} className="w-[70%] h-10 bg-white border border-onboarding-border rounded-[10px] text-lg flex justify-center items-center gap-7  xs:gap-10">
            <p>{sDate}</p>
            <button>
              <img src={Schedule} width="32" height="32" alt="" />
            </button>
          </div>

          <button onClick={() => prevOrNextDate('next')} className="w-6 h-6 border-2 border-white bg-secondary rounded-md">
            <span className="material-symbols-outlined text-white text-lg leading-5">
              east
            </span>
          </button>
        </div>
      </div>
      <div className="flex justify-center mt-1">
        <div className="hidden absolute z-10" ref={dateRef}>
          <Calendar
            locale={language === 'tr' ? "tr-TR" : "en-EN"}
            value={dateValue}
            onChange={(dateValue) => {
              localStorage.getItem('selectedDate') && localStorage.removeItem('selectedDate');
              setDateValue(dateValue);
            }}
            tileContent={({ date, view }) => getTileContent(date, view)}
            tileClassName={addGroupClass}
          />
        </div>
      </div>
    </>
  );
};

export default ChooseDate;
