import { Link } from "react-router-dom";

const Error = (props) => {
  return (
    <>
      <div className="mx-auto text-center mt-7 text-sm px-4">
        <img
          className="inline-block"
          src={props.icon}
          alt=""
          width="128"
          height="128"
        />
        <p className="font-bold mt-7 text-base">{props.title}</p>
        <p className="mt-5">{props.content}</p>
      </div>

      {/* button */}
      <div className={`btn-outer-div ${props.hideButton}`}>
        <Link to={props.to} state={props.state} className="button primary-button my-7">
          {props.buttonText}
        </Link>
      </div>
    </>
  );
};

export default Error;
