import {Accept, Awsome, Knowledgeable, NotStarted, Preparation, Thunder} from "../assets/svg";
import {useLocalization} from "../hooks/useLocalization";
import classNames from "classnames";
import React from "react";

const ActionIcons = () => {

    const strings = useLocalization();

    const CONTENT_ICONS = [
        {
            icon : Awsome,
            title: strings.speaking_lesson.education_packages.icons.awsome,
            classnames: "bg-[#55418F]",
            description: strings.speaking_lesson.education_packages.icons.awsome_description
        },
        {
            icon : Knowledgeable,
            title: strings.speaking_lesson.education_packages.icons.knowledgeable,
            classnames: "bg-[#938EBA]",
            description: strings.speaking_lesson.education_packages.icons.knowledgeable_description
        },
        {
            icon : Accept,
            title: strings.speaking_lesson.education_packages.icons.completed,
            classnames: "bg-[#CB8132]",
            description: ""
        },
        {
            icon : Preparation,
            title: strings.speaking_lesson.education_packages.icons.preparation,
            classnames: "bg-[#B96634]",
            description: strings.speaking_lesson.education_packages.icons.preparation_description,
        },
        {
            icon : NotStarted,
            title: strings.speaking_lesson.education_packages.icons.not_started,
            classnames: "border border-[#909195]",
            description: ""
        },
        {
            icon : Thunder,
            title: strings.speaking_lesson.education_packages.icons.quiz,
            classnames: "bg-[#EAEAEB]",
            description: ""
        }
    ]

    return (
        <div className="flex flex-wrap gap-3 mt-3">
            {CONTENT_ICONS.map((content, index) => (
                <div key={index} className="flex items-center gap-1.5">
                    <img
                        src={content.icon}
                        className={classNames("rounded-md", {
                            [content.classnames]: !!content.classnames
                        })}
                        width={26}
                        height={26}
                        alt="content-icon"
                    />
                    <div className="flex flex-col">
                        <span>{content.title}</span>
                        {content.description.length > 0 && (
                            <span className="font-bold text-[12px] -mt-1">{content.description}</span>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ActionIcons;