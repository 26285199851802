import React from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { BottomStickyBar } from ".";
import download from '../assets/animation/download.json';
import { Logo } from "../assets/svg";
import { useLocalization } from "../hooks/useLocalization";

const DownloadMobileAppBar = () => {

    const navigate = useNavigate();
    const strings = useLocalization();

    // Animasyon ayarları
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: download,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <BottomStickyBar
            theme="white"
            isOverlay={true}
            children={
                <>
                    <p className="text-center md:hidden pt-2">{strings.popup.download_app.content}</p>
                    <div className="flex justify-between items-center min-h-[64px]">
                        <div className="flex justify-center items-center gap-2.5">
                            <div className="flex items-center">
                                <img className="w-[28px] md:hidden" src={Logo} alt="" />
                                <div className="max-md:hidden">
                                    <Lottie options={defaultOptions} height={32} width={32} />
                                </div>
                                <p className="font-bold text-[14px] md:hidden lg:text-lg ml-1 lg:ml-2">
                                    {strings.brand_name}
                                </p>
                                <p className="text-center max-md:hidden ml-3">{strings.popup.download_app.content}</p>
                            </div>

                        </div>
                        <a
                            className="button primary-button px-2 h-[40px] max-w-max min-w-[80px] text-[16px] mt-0 leading-[16px] flex justify-center items-center"
                            href={"https://onelink.to/3a8qtv"}
                        >
                            {strings.popup.download_app.button}
                        </a>
                    </div>
                </>
            }
        />
    );
}

export default DownloadMobileAppBar;