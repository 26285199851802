import classNames from 'classnames';
import { getCookie } from 'ko-basic-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import '../../assets/css/login.css';
import { PasswordEye, PasswordEyeSlash } from '../../assets/svg';
import { ModalOneButton, ModalOverlay } from '../../components';
import {cookieName, EventLogs} from '../../components/Constants';
import useAnalytics from '../../hooks/useAnalytics';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { UpdatePasswordV2 } from '../../services/Auth/AuthService';
import { checkPassword, clearError, closeModal, openModal, showError } from '../../services/Auth/AuthValidation';

const ForgotPasswordChangePassword = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const location = useLocation();
    const isFromTruePage = location.state?.from?.includes("/auth/verify") || false;

    const passwordRef = useRef();
    const passwordRef2 = useRef();
    const phoneErrorRef = useRef();
    const passwordErrorRef = useRef();
    const modalRef = useRef();
    const modalSuccessRef = useRef();
    const modalOverlayRef = useRef();

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [loading, setLoading] = useState(false)
    const token = getCookie(cookieName) || false;

    const passwordHandle = (setFunc, e) => {
        setFunc(e);
    };


    const submitHandle = async e => {
        e.preventDefault();

        showError(checkPassword(password), passwordErrorRef);
        showError(checkPassword(password2), passwordErrorRef);

        //if password are not equal show error
        if (password !== password2) {
            showError(false, passwordErrorRef);
        } else {

            const memberId = JSON.parse(localStorage.getItem('memberId'));

            setLoading(loading => true)

            const result = await UpdatePasswordV2(memberId, password);

            //if there is no error do operations
            if (result.status === 200) {

                //send info to analytics
                analytics.useAnalytics(
                    EventLogs.PASSWORD_RESETED,
                    undefined,
                    true,
                    false
                );
                //open modal
                openModal(modalOverlayRef, modalSuccessRef);
                //clear member Id
                localStorage.removeItem('memberId');


                //if there is an error send error informations to analytics and open a modal
            } else {
                if (result.message) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${result.message}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
                openModal(modalOverlayRef, modalRef);
            }
            setLoading(loading => false)
        }
    }

    //password eye
    useEffect(() => {
        passwordRef.current.type = showPassword ? 'input' : 'password';
    }, [showPassword])

    //password eye 2
    useEffect(() => {
        passwordRef2.current.type = showPassword2 ? 'input' : 'password';
    }, [showPassword2])

    //password useeffect
    useEffect(() => {
        clearError(passwordErrorRef);

        //for borders
        const element = passwordRef.current;
        const element2 = passwordRef2.current;

        if (password === '' && password2 === '') {
            element.classList.remove('border-red-600')
            element.classList.remove('border-green-600')
            element2.classList.remove('border-red-600')
            element2.classList.remove('border-green-600')
        } else {
            if ((password === password2) && checkPassword(password) && checkPassword(password2)) {
                element.classList.remove('border-red-600')
                element.classList.add('border-green-600')
                element2.classList.remove('border-red-600')
                element2.classList.add('border-green-600')
                clearError(passwordErrorRef);
            }
            else {
                element.classList.add('border-red-600')
                element.classList.remove('border-green-600')
                element2.classList.add('border-red-600')
                element2.classList.remove('border-green-600')
            }
        }

    }, [password, password2])

    //login control & analytics
    useEffect(() => {

        //if request comes from true page(forgot password)
        if (isFromTruePage) {
            if (token) {
                navigate(url("home"));
            }

        } else {
            navigate(url("home"));
        }
    }, []);

    return (
        <>
            <form id="form" onSubmit={submitHandle}>
                <div className="mb-[6px] relative">
                    <label className="block text-start font-bold text-base-text-light" htmlFor="password">
                        {strings.auth.form.your_password}
                    </label>
                    <input className="keypress input-area w-full block" id="password" type="password"
                        ref={passwordRef} name="password" placeholder={strings.auth.form.create_your_password}
                        onChange={(e) => passwordHandle(setPassword, e.target.value)}
                    />
                    <span className="toggle-password">
                        <img
                            src={showPassword ? PasswordEyeSlash : PasswordEye}
                            width="20"
                            alt={showPassword ? "password-eye-slash" : "password-eye"}
                            onClick={() => setShowPassword(showPassword => !showPassword)}
                        />
                    </span>
                </div>
                <p id="phone_error" className="mb-2 text-[11px] text-[#FF0000] text-start ml-2 hidden" ref={phoneErrorRef}>{strings.auth.validation_messages.invalid_phone}</p>
                <div className="mb-[6px] relative">
                    <label className="block text-start font-bold text-base-text-light" htmlFor="password2">
                        {strings.auth.form.your_password_again}
                    </label>
                    <input className="keypress input-area w-full block" id="password2" type="password2"
                        ref={passwordRef2} name="password" placeholder={strings.auth.form.enter_your_password_again}
                        onChange={(e) => passwordHandle(setPassword2, e.target.value)}
                    />
                    <span className="toggle-password">
                        <img
                            src={showPassword2 ? PasswordEyeSlash : PasswordEye}
                            width="20"
                            alt={showPassword2 ? "password-eye-slash" : "password-eye"}
                            onClick={() => setShowPassword2(showPassword2 => !showPassword2)}
                        />
                    </span>
                </div>
                <p id="password_error" className="text-[11px] text-[#FF0000] text-start ml-2 hidden" ref={passwordErrorRef}>{strings.auth.validation_messages.invalid_change_password}</p>

                <button type="submit" disabled={loading} className={classNames("button button-mt primary-button", {
                    "opacity-25 disabled:pointer-events-none": loading
                })}>
                    {loading ?
                        strings.general_information_messages.processing
                        : strings.auth.form.change_button}
                </button>

            </form>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.general_information_messages.an_error_occured}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
            <ModalOneButton
                ref={modalSuccessRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.auth.information_messages.change_password_success_message}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => navigate(url("auth.login") + window.location.search)}
            />
        </>
    )
}

export default ForgotPasswordChangePassword