import {Menu, SideBar} from "../../components";
import {Outlet} from "react-router-dom";
import React from "react";
import { useLocalization } from "../../hooks/useLocalization";

const LearnTogetherLayout = () => {

    const strings = useLocalization();

    return (
        <>
            <Menu title={strings.learn_together.learn_together} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
        </>
    )
}

export default LearnTogetherLayout;

