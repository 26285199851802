import React, { useRef} from "react";
import {ModalOneButton, ModalOverlay} from "../components";
import {SpeakWordWithTts} from "../services/SpeakingLesson/SpeakingLessonService";
import {closeModal, openModal} from "../utils";
import {useLocalization} from "./useLocalization";

const useSpeaker = () => {

    const strings = useLocalization();

    const modalRef = useRef();
    const audioRef = useRef();
    const modalOverlayRef = useRef();

    //speak sentence
    const speakSentence = async (sentence) => {
        const result = await SpeakWordWithTts(sentence);

        if (result.statusCode === 200) {
            audioRef.current.src = result.message.audio;

            // Ses çalmayı başlat
            audioRef.current.play();
        } else {
            openModal(modalOverlayRef, modalRef);
        }
    };

    const SpeakerComponent = () => {
        return (
            <>
                {/* Sesin çalması için gerekli olan kısım */}
                <audio ref={audioRef} id="audioPlayer" controls={false} autoPlay={false} className="hidden">
                    <source type="audio/mp3" />
                </audio>
                <ModalOverlay ref={modalOverlayRef} />
                <ModalOneButton
                    ref={modalRef}
                    overlayRef={modalOverlayRef}
                    title={strings.auth.information_messages.modal_title}
                    message={strings.menu.callbar.an_error_occured}
                    buttonText={strings.auth.form.okay_button}
                    buttonClick={() => closeModal(modalOverlayRef, modalRef)}
                />
            </>
        )
    }

    return {
        speakSentence,
        SpeakerComponent
    }
}

export default useSpeaker;