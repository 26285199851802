import { useLocalization } from "../../../../hooks/useLocalization"

const PostponeHistory = ({ data }) => {

  const strings = useLocalization();

  return (
    <div className="transparent-section mt-5 text-sm text-start">
      <h5 className="text-secondary mb-3">{strings.support.postpone_lesson.postponed_lesson_title}</h5>
      {data &&
        data.map((date, index) => (
          <div key={index}>
            <hr />
            <p>{strings.support.postpone_lesson.postponed_lesson_content.replace("#date#", date)}</p>
          </div>
        ))}
      <br />
    </div>
  )
}

export default PostponeHistory