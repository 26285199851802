import React from "react";
import {getColorForScore} from "../utils";

const ProgressBar = (props) => {

    const { width } = props;

    return (
        <div className="h-3 bg-[#d9d9d9]">
            <div
                className="h-full"
                style={{width: `${width}%`, background: `${getColorForScore(width)}`}}
            >
            </div>
        </div>
    )
}
export default ProgressBar;