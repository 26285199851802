import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {GetBrandLocalCompany, GetStudentInvoiceInformation} from "../../../../services/Market/MarketService";
import {formatDateWithSlash} from "../../../../utils/Date";
import {MarketSourceTypes} from "../../../../components/Constants";
import {Disclosure, Transition} from '@headlessui/react'
import classNames from "classnames";
import {Arrow_Down} from "../../../../assets/svg";

const Contract = (props) => {

    const { packageInfo, priceInfo, bankInfo, memberInfo } = props;

    const strings = useLocalization();
    let preliminaryInformationForm = strings.forms.preliminary_information_form;

    const [currentStudentInformations, setCurrentStudentInformations] = useState({});
    const [brandInfo, setBrandInfo] = useState({});

    // Satın alınacak ürün bilgileri
    const productInfo = {
        name: packageInfo?.selectedProduct?.title,
        totalPrice: packageInfo?.source === MarketSourceTypes.SPEAKING_LESSON
            ? priceInfo?.price
            : packageInfo?.selectedProduct?.additionalProductPriceDetails?.totalPrice,
        paymentType: "Kredi kartı",
        beginDate: formatDateWithSlash(new Date()),
    };

    // Kullanıcı bilgileri
    const memberInformation = {
        name: currentStudentInformations?.fullName,
        address: currentStudentInformations?.invoiceAddress,
        phone: currentStudentInformations?.phoneNumber,
        email: currentStudentInformations?.email,
    };

    // Sözleşme içerisindeki kodlar replace ediliyor
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyName#", brandInfo.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyAddress#", brandInfo.address);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyPhone#", brandInfo.phone);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyEmailAddress#", brandInfo.email);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberName#", memberInformation.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberAddress#", memberInformation.address);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberPhone#", memberInformation.phone);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberEmail#", memberInformation.email);
    preliminaryInformationForm = preliminaryInformationForm.replace("#ProductName#", productInfo.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#TotalProductPrice#", productInfo.totalPrice);
    preliminaryInformationForm = preliminaryInformationForm.replace("#PaymentType#", productInfo.paymentType);
    preliminaryInformationForm = preliminaryInformationForm.replace("#TermBeginDate#", productInfo.beginDate);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberCurrentCardAddress#", memberInformation.address);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyName#", brandInfo.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyAddress#", brandInfo.address);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyPhone#", brandInfo.phone);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyEmailAddress#", brandInfo.email);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberName#", memberInformation.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberAddress#", memberInformation.address);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberPhone#", memberInformation.phone);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberEmail#", memberInformation.email);
    preliminaryInformationForm = preliminaryInformationForm.replace("#ProductName#", productInfo.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#TotalProductPrice#", productInfo.totalPrice);
    preliminaryInformationForm = preliminaryInformationForm.replace("#PaymentType#", productInfo.paymentType);
    preliminaryInformationForm = preliminaryInformationForm.replace("#TermBeginDate#", productInfo.beginDate);
    preliminaryInformationForm = preliminaryInformationForm.replace("#BrandLocalCompanyName#", brandInfo.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#MemberName#", memberInformation.name);
    preliminaryInformationForm = preliminaryInformationForm.replace("#TodayDate#", formatDateWithSlash(new Date()));
    // Sözleşme içerisindeki kodlar replace ediliyor

    // Servisten, sözleşmeye basabilmek için öğrenci bilgileri açılıyor.
    useEffect(() => {
        if(packageInfo && memberInfo) {
            // Öğrencinin fatura bilgileri getiriliyor
            GetStudentInvoiceInformation(memberInfo?.MemberId)
                .then(result => {
                    if(result.status === 200) {
                        setCurrentStudentInformations(JSON.parse(result.content));
                    }
                })
                .catch()
        }
    }, [])

    useEffect(() => {
        if(bankInfo.id !== -1) {
            // Bankanın lokal şirket id'sine göre lokal şirket bilgileri getiriliyor
            GetBrandLocalCompany(+bankInfo?.brandLocalCompanyId)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);

                        const tempBrandInfo = {
                            name: resultContent.name,
                            address: resultContent.address,
                            phone: resultContent.phone,
                            email: resultContent.email,
                        };

                        setBrandInfo(tempBrandInfo);
                    }
                })
                .catch()
        }
    }, [bankInfo.id]);

    return (
        <>
            <Disclosure as="div" className="mt-3 border border-onboarding-border rounded-[10px] p-2">
                {({ open }) => (
                    <>
                        <Disclosure.Button className={classNames("flex items-center w-full justify-between px-2", {
                            "mb-2 pb-2 border-b text-start": open
                        })}>
                            <span className="font-bold">{strings.market.checkout.preliminary_information_form_title}</span>
                            <img
                                src={Arrow_Down}
                                className={classNames("material-symbols-outlined text-[36px] transition-transform duration-300 cursor-pointer", {
                                    "rotate-180": open
                                })}
                                alt="arrow_down"
                            />
                        </Disclosure.Button>
                        <Transition
                            enter="transition-all duration-300 ease-out"
                            enterFrom="max-h-0 opacity-0"
                            enterTo="max-h-[400px] opacity-100"
                            leave="transition-all duration-200 ease-in"
                            leaveFrom="max-h-[400px] opacity-100"
                            leaveTo="max-h-0 opacity-0"
                        >
                            <Disclosure.Panel>
                                <div
                                    className="h-[150px] overflow-y-auto text-[12px]"
                                    dangerouslySetInnerHTML={{ __html: preliminaryInformationForm }}
                                />
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </>

    )
}

export default Contract;